import PropTypes from "prop-types";

// components
import Modal, { ModalButton } from "Common/BootStrap/Modal";
import ModalBody from "./ModalBody";

const Domains = ({ survey }) => {
    const domains = survey.domains.map((d) => {
        return {
            ...d,
            restType: "update",
        };
    });

    return (
        <Modal
            reqObjects={domains}
            apiEndPoint={`/auth/surveys/domains/${survey.id}`}
            method="post"
        >
            <ModalButton>Dom</ModalButton>
            <ModalBody survey={survey} />
        </Modal>
    );
};

export default Domains;

// prop-types
Domains.propTypes = {
    survey: PropTypes.object.isRequired,
};
