import { css } from "styled-components";

export const buttonLinkStyles = css`
    width: 45px;
    margin: 0px 3px;

    &:first-child {
        margin-left: 0px;
    }
    &:last-child {
        margin-right: 0px;
    }
`;
