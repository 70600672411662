import styled from "styled-components";
import PropTypes from "prop-types";

// components
import Username from "./Username";
import UserID from "./UserID";

const UserDetails = ({ roleId, clientIsRestricted, submittedBy }) => {
    if (roleId === 3) return <div></div>;

    return (
        <UserDetailsStyled>
            <UserID submittedBy={submittedBy} />
            <Username
                roleId={roleId}
                clientIsRestricted={clientIsRestricted}
                submittedBy={submittedBy}
            />
        </UserDetailsStyled>
    );
};

export default UserDetails;

// prop-types
UserDetails.propTypes = {
    roleId: PropTypes.number.isRequired,
    clientIsRestricted: PropTypes.bool.isRequired,
    submittedBy: PropTypes.object.isRequired,
};

const UserDetailsStyled = styled.div`
    display: flex;
`;
