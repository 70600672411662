import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

/* Redux ---------------------------*/
import { useCreateNewClientMutation } from "Redux/apiAuthSlice.js";

const AddUser = () => {
    let navigate = useNavigate();
    const [updateData] = useCreateNewClientMutation();

    // Account Details
    const [userInfo, setUserDetails] = useState({
        organization: "",
        phone_number: "",
        first_name: "",
        last_name: "",
        email: "",
        roleId: "",
    });

    const onInputChange = (event) => {
        setUserDetails((prevStatus) => ({
            ...prevStatus,
            [event.target.id]: event.target.value,
        }));
    };

    const resetFields = () => {
        setUserDetails({
            organization: "",
            phone_number: "",
            first_name: "",
            last_name: "",
            email: "",
            roleId: "",
        });
    };

    const saveUser = async (e) => {
        e.preventDefault();

        const { data: resp } = await updateData({ userInfo });

        if (resp.roleId !== 1) {
            navigate(`/auth/cp/admin/client/${resp.id}`);
        } else {
            navigate(`/auth/cp/admin`);
        }
    };
    return (
        <AddNewClientStyled className="AddUser inset">
            <section className="h-100">
                <div className="h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col">
                            <div className="card card-registration my-4">
                                <div className="row g-0">
                                    <div className="col-xl-6 d-none d-xl-block">
                                        <img
                                            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/img4.webp"
                                            alt="Sample photo"
                                            className="img-fluid"
                                        />
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="card-body p-md-5 text-black">
                                            <h3 className="mb-5 text-uppercase">
                                                User registration form
                                            </h3>
                                            <form
                                                className="row g-3 needs-validation"
                                                noValidate
                                                onSubmit={saveUser}
                                            >
                                                <div className="row">
                                                    <div className="col-md-6 mb-4">
                                                        <div className="form-outline">
                                                            <input
                                                                type="text"
                                                                id="first_name"
                                                                className="form-control form-control-lg"
                                                                value={
                                                                    userInfo.first_name
                                                                }
                                                                onChange={(e) =>
                                                                    onInputChange(
                                                                        e
                                                                    )
                                                                }
                                                                required
                                                            />
                                                            <label
                                                                className="form-label"
                                                                htmlFor="first_name"
                                                            >
                                                                First name
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <div className="form-outline">
                                                            <input
                                                                type="text"
                                                                id="last_name"
                                                                className="form-control form-control-lg"
                                                                value={
                                                                    userInfo.last_name
                                                                }
                                                                onChange={(e) =>
                                                                    onInputChange(
                                                                        e
                                                                    )
                                                                }
                                                                required
                                                            />
                                                            <label
                                                                className="form-label"
                                                                htmlFor="last_name"
                                                            >
                                                                Last name
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-outline mb-4">
                                                    <input
                                                        type="text"
                                                        id="organization"
                                                        className="form-control form-control-lg"
                                                        value={
                                                            userInfo.organization
                                                        }
                                                        onChange={(e) =>
                                                            onInputChange(e)
                                                        }
                                                    />
                                                    <label
                                                        className="form-label"
                                                        htmlFor="organization"
                                                    >
                                                        Organization
                                                    </label>
                                                </div>

                                                <div className="form-outline mb-4">
                                                    <input
                                                        type="text"
                                                        id="phone_number"
                                                        className="form-control form-control-lg"
                                                        value={
                                                            userInfo.phone_number
                                                        }
                                                        onChange={(e) =>
                                                            onInputChange(e)
                                                        }
                                                    />
                                                    <label
                                                        className="form-label"
                                                        htmlFor="phone_number"
                                                    >
                                                        Phone Number
                                                    </label>
                                                </div>

                                                <div className="d-md-flex justify-content-start align-items-center mb-4 py-2">
                                                    <h6 className="mb-0 me-4">
                                                        Role:{" "}
                                                    </h6>

                                                    <div className="form-check form-check-inline mb-0 me-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="inlineRadioOptions"
                                                            id="roleId"
                                                            value="1"
                                                            onChange={(e) =>
                                                                onInputChange(e)
                                                            }
                                                            required
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="roleId"
                                                        >
                                                            Admin
                                                        </label>
                                                    </div>

                                                    <div className="form-check form-check-inline mb-0 me-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="inlineRadioOptions"
                                                            id="roleId"
                                                            value="2"
                                                            onChange={(e) =>
                                                                onInputChange(e)
                                                            }
                                                            required
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="roleId"
                                                        >
                                                            Client
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="form-outline mb-4">
                                                    <input
                                                        type="text"
                                                        id="email"
                                                        className="form-control form-control-lg"
                                                        value={userInfo.email}
                                                        onChange={(e) =>
                                                            onInputChange(e)
                                                        }
                                                        required
                                                    />
                                                    <label
                                                        className="form-label"
                                                        htmlFor="email"
                                                    >
                                                        Email ID
                                                    </label>
                                                </div>

                                                <div className="d-flex justify-content-end pt-3">
                                                    <button
                                                        type="button"
                                                        className="btn btn-light btn-lg"
                                                        onClick={() =>
                                                            resetFields()
                                                        }
                                                    >
                                                        Reset all
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-lg ms-2"
                                                    >
                                                        Add User
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </AddNewClientStyled>
    );
};

export default AddUser;

const AddNewClientStyled = styled.div``;
