import styled from "styled-components";
import PropTypes from "prop-types";

// components
import ScalingIFrame from "Common/ScalingIFrame.jsx";

const ResultsDiagramWrapper = ({
    surveyId,
    surveySubmissionId,
    reportType = "strength",
}) => {
    const src = `/auth/bare/survey/results-diagram/${surveyId}/${surveySubmissionId}/${reportType}`;

    return (
        <ResultsDiagramWrapperStyled>
            <ScalingIFrame src={src} aspectRatio="9/9" />
        </ResultsDiagramWrapperStyled>
    );
};

export default ResultsDiagramWrapper;

// prop-types
ResultsDiagramWrapper.propTypes = {
    surveyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    surveySubmissionId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    reportType: PropTypes.string,
};

const ResultsDiagramWrapperStyled = styled.div`
    margin: 0px auto 50px;
`;
