import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareHeadersAuth } from "./utils";

export const apiAuthClientSlice = createApi({
    reducerPath: "apiAuthClient",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_EXPRESS_API}/api/auth`,
        prepareHeaders: prepareHeadersAuth,
    }),
    endpoints: (builder) => {
        return {
            getClient: builder.query({
                query: (clientId) => `/clients/${clientId}`,
            }),
            getSurveys: builder.query({
                query: (clientId) => `/users/${clientId}/surveys`,
            }),
        };
    },
});

export const { useGetClientQuery, useGetSurveysQuery } = apiAuthClientSlice;
