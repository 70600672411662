import styled from "styled-components";

/* ClientDetailsContext ---------------------------*/
import { useClientDetailsContext } from "Pages/Auth/ControlPanel/ClientDetails/ClientDetailsContext/index.js";

/* Components ---------------------------*/
import CardTemplate from "Common/BootStrap/CardTemplate.jsx";
import Header from "./Header";
import SurveyTable from "./SurveyTable/index";

const Surveys = () => {
    const {
        useInput,
        useCheckbox,
        config: { roleId, clientProfile, isLoading },
    } = useClientDetailsContext();

    if (isLoading) {
        return <div>{isLoading}</div>;
    }

    const title = <Header />;

    return (
        <SurveysStyled>
            <CardTemplate title={title} noBodyPadding={true}>
                <SurveyTable />
            </CardTemplate>
        </SurveysStyled>
    );
};

export default Surveys;

const SurveysStyled = styled.div``;
