import React from 'react';
import styled from 'styled-components';

const Main = ({ children }) => {
    return <MainStyled className="Main container-sm">{children}</MainStyled>;
};

export default Main;

const MainStyled = styled.main`
    margin: 0px auto 50px;
`;
