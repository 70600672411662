import { actionTypes } from './actionTypes.js';

/*---------------------------
| State Updater (reducer)
---------------------------*/
const reducer = (state, action) => {
    switch (action.type) {

        case actionTypes.RO_RANKED_ITEMS_ORDER_UPDATE: {
            return conditionsSatisfiedCheck({
                ...state,
                ranked: action.rankedItems,
            });
        }

        case actionTypes.RO_ADD_RANKED_ITEM: {

            const item = action.item;

            // Add
            const ranked = [
                ...state.ranked,
                item
            ];

            // Remove
            const unranked = state.unranked.filter((ur) => {
                return ur.id !== item.id;
            });

            return conditionsSatisfiedCheck({
                ...state,
                ranked: ranked,
                unranked: unranked
            });
        }

        case actionTypes.RO_REMOVE_RANKED_ITEM: {

            const item = action.item;

            // Add
            const unranked = [
                ...state.unranked,
                item
            ];

            // Remove
            const ranked = state.ranked.filter((ur) => {
                return ur.id !== item.id;
            });

            return conditionsSatisfiedCheck({
                ...state,
                ranked: ranked,
                unranked: unranked,
            });
        }

        case actionTypes.RO_MOVE_RANKED_ITEM_UP: {

            const { item, domOrder } = action;
            const { ranked } = state;

            // Calculate new Index
            const newPosition = (0 !== domOrder) ? domOrder - 1: domOrder;

            // Remove from existing
            const newRanked = ranked.filter((r) => r.id !== item.id);

            // Add at new position in array
            newRanked.splice(newPosition,0,item);

            return conditionsSatisfiedCheck({
                ...state,
                ranked: newRanked,
            });
        }

        case actionTypes.RO_MOVE_RANKED_ITEM_DOWN: {

            const { item, domOrder } = action;
            const { ranked } = state;

            // Calculate new Index
            const newPosition = (ranked.length !== domOrder) ? domOrder + 1: domOrder;

            // Remove from existing
            const newRanked = ranked.filter((r) => r.id !== item.id);

            // Add at new position in array
            newRanked.splice(newPosition,0,item);

            return conditionsSatisfiedCheck({
                ...state,
                ranked: newRanked,
            });
        }

        default: {
            return { ...state };
        }
    }
};

export default reducer;

const conditionsSatisfiedCheck = (newState) => {

    let {
        conditions,
        ranked
    } = newState;

    const { count } = conditions;

    const rankedCount = ranked.length;

    count.min.satisfied = (rankedCount >= count.min.value);
    count.min.isMin = (rankedCount === count.min.value);

    count.max.satisfied = (rankedCount <= count.max.value);
    count.max.isMax = (rankedCount === count.max.value);

    count.minThresholdMet = count.min.satisfied;

    return {
        ...newState,
        conditions: {
            ...conditions,
            count: count,
        }
    };
}
