import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';

const CloseButton = ({onClick}) => {
    return (
        <CloseButtonStyled className='CloseButton' onClick={ onClick }>
            <FontAwesomeIcon icon={ faCircleXmark } />
            <span className='label'>Close</span>
        </CloseButtonStyled>
    );
}

export default CloseButton;

const CloseButtonStyled = styled.button`
    border: none;
    border-radius: 50%;
    outline: none;
    background-color: transparent;
    padding: 0px;
    margin: 0px;

    width: 20px;
    height: 20px;

    color: #41bdee;
    font-size: 2vw;

    .label {
        font-size: 0px;
    }
`;