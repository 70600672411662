import React from "react";
import styled from "styled-components";

/* Components ---------------------------*/
import Circles from "./Circles.jsx";
import Percentages from "./Percentages.jsx";

const ResultsInline = (props) => {
    return (
        <ResultsInlineStyled className="ResultsInline">
            <div className="ri-col ri-left">
                <Circles {...props} />
            </div>
            <div className="ri-col ri-right">
                <Percentages {...props} />
            </div>
        </ResultsInlineStyled>
    );
};

export default ResultsInline;

const ResultsInlineStyled = styled.div`
    display: flex;
    margin: auto;

    .ri-left {
        width: 40%;
    }
    .ri-right {
        width: 60%;
        padding: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
`;
