import { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Modal Context
import { useModalContext } from "Common/BootStrap/Modal";

// components
import ControlWithLabel from "Common/BootStrap/FormControls/ControlWithLabel";
import TextArea from "Common/BootStrap/FormControls/TextArea";

const AddQuestion = ({ survey }) => {
    const { reqObjectAdd } = useModalContext();
    const [question, questionUpdate] = useState("");

    const handleAddQuestion = () => {
        reqObjectAdd({
            surveyId: survey.id,
            userId: survey.userId,
            question,
        });
        questionUpdate("");
    };

    return (
        <AddQuestionStyled>
            <ControlWithLabel
                label={`Add New Question`}
                id={"question-placeholder"}
            >
                <TextArea
                    id={"question-placeholder"}
                    placeholder="Enter Question"
                    value={question}
                    onChange={(e) => questionUpdate(e.target.value)}
                    height="38px"
                />
            </ControlWithLabel>
            <footer>
                <button
                    className="btn btn-secondary"
                    onClick={handleAddQuestion}
                >
                    Add Question
                </button>
            </footer>
        </AddQuestionStyled>
    );
};

export default AddQuestion;

// prop-types
AddQuestion.propTypes = {
    survey: PropTypes.object.isRequired,
};

const AddQuestionStyled = styled.div`
    margin-bottom: 40px;

    footer {
        text-align: right;
    }
`;
