// https://redux.js.org/introduction/getting-started
import { configureStore } from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice.js";
import { apiAuthSlice } from "./apiAuthSlice.js";
import { apiAuthSurveySlice } from "./apiAuthSurveySlice.js";
import { apiAuthUserSlice } from "./apiAuthUserSlice.js";
import { apiAuthClientSlice } from "./apiAuthClientSlice.js";

export default configureStore({
    devTools: {
        name: "See Degree Survey App",
    },
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        [apiAuthSlice.reducerPath]: apiAuthSlice.reducer,
        [apiAuthSurveySlice.reducerPath]: apiAuthSurveySlice.reducer,
        [apiAuthUserSlice.reducerPath]: apiAuthUserSlice.reducer,
        [apiAuthClientSlice.reducerPath]: apiAuthClientSlice.reducer,
    },
    middleware: (getDefaultMiddleWare) => {
        return getDefaultMiddleWare()
            .concat(apiSlice.middleware)
            .concat(apiAuthSlice.middleware)
            .concat(apiAuthSurveySlice.middleware)
            .concat(apiAuthUserSlice.middleware)
            .concat(apiAuthClientSlice.middleware);
    },
});
