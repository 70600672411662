import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_EXPRESS_API}/api`,
        prepareHeaders: async (headers) => {
            headers.set("Content-Type", "application/json");
            return headers;
        },
    }),
    endpoints: (builder) => {
        return {
            getPreloadedState: builder.query({
                query: () => '/preload',
            })
        };
    },
});

export const { useGetPreloadedStateQuery } = apiSlice;
