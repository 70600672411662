import React, { useEffect, useState } from "react";
import styled from "styled-components";

/* RankItems ---------------------------*/
import { useRankItems } from "Common/useRankItems";

const HostDisplay = () => {
    const [hasEnoughRanked, hasEnoughRankedUpdate] = useState(false);

    const { ranked, unranked } = useRankItems();

    useEffect(() => {
        // Info: console.log('Host can do something whenever data changes.', items);
        hasEnoughRankedUpdate(ranked.length >= 4);
    }, [ranked]);

    const handleNextStep = () => {
        console.log("Move on to next step");
    };

    return (
        <HostDisplayStyled className="HostDisplay">
            <h3>Displaying Items in Host As They Change</h3>

            <h4>Are there enough Ranked? That is the question...</h4>
            {hasEnoughRanked ? (
                <p>
                    <b>YES:</b> User has at least 4 ranked.
                </p>
            ) : (
                <p>
                    <b>NO:</b> User needs at least 4 items ranked.
                </p>
            )}

            <button onClick={handleNextStep} disabled={!hasEnoughRanked}>
                Next Step
            </button>

            <h4>Ranked</h4>
            <ul className="items">
                {ranked.map((item) => {
                    return (
                        <li
                            key={item.id}
                            dangerouslySetInnerHTML={{ __html: item.value }}
                        />
                    );
                })}
            </ul>

            <h4>Unranked</h4>
            <ul className="items">
                {unranked.map((item) => {
                    return (
                        <li
                            key={item.id}
                            dangerouslySetInnerHTML={{ __html: item.value }}
                        />
                    );
                })}
            </ul>
        </HostDisplayStyled>
    );
};

export default HostDisplay;

const HostDisplayStyled = styled.div`
    margin-top: 100px;

    h4 {
        margin-top: 50px;
    }

    button {
        border: none;
        outline: none;
        background-color: #2b5f2b;
        color: white;
        padding: 10px 20px;

        &:hover {
            background-color: #153015;
        }

        &:disabled {
            background-color: #c1c1c1;
            color: #4e4e4e;
            cursor: not-allowed;
        }
    }
`;
