import styled from "styled-components";
import PropTypes from "prop-types";

// components
import ViewSurveyResult from "Pages/Auth/Common/ViewSurveyResult.jsx";

const SubmissionActions = ({ surveyId, subId }) => {
    return (
        <SubmissionActionsStyled>
            <ViewSurveyResult surveyId={surveyId} subId={subId} />
        </SubmissionActionsStyled>
    );
};

export default SubmissionActions;

// prop-types
SubmissionActions.propTypes = {
    surveyId: PropTypes.number.isRequired,
    subId: PropTypes.number.isRequired,
};

const SubmissionActionsStyled = styled.div`
    text-align: right;
`;
