import styled from "styled-components";

// components
import ScalingIFrame from "Common/ScalingIFrame";

const ResultsDiagram = () => {
    const src = `/auth/results-diagram-iframe`;

    return (
        <ResultsDiagramStyled>
            <h2>Results Diagram Example</h2>
            <ScalingIFrame src={src} aspectRatio="9/9" />
        </ResultsDiagramStyled>
    );
};

export default ResultsDiagram;

const ResultsDiagramStyled = styled.div``;
