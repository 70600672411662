import PropTypes from "prop-types";

// Modal Context
import {
    ModalBody as BSModalBody,
    useModalContext,
} from "Common/BootStrap/Modal";

// components
import OpenTextControl from "./OpenTextControl";

const ModalBody = ({ survey }) => {
    const { reqObjects } = useModalContext();

    return (
        <BSModalBody
            title="Update Opening Texts"
            saveButtonText="Save Opening Texts"
            fullscreen={true}
        >
            {reqObjects.map((reqObject, idx) => {
                return (
                    <OpenTextControl
                        key={reqObject.id}
                        count={idx + 1}
                        reqObject={reqObject}
                        survey={survey}
                    />
                );
            })}
        </BSModalBody>
    );
};

export default ModalBody;

// prop-types
ModalBody.propTypes = {
    survey: PropTypes.object.isRequired,
};
