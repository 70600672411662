import PropTypes from "prop-types";
import Nav from "react-bootstrap/Nav";

/* Hooks ---------------------------*/
import { useAuth } from "Auth0/useAuth.js";

const Logout = ({ display = "Logout" }) => {
    // props / hooks / state
    const { auth0 } = useAuth();

    // Methods
    const onClick = (e) => {
        e.preventDefault();
        auth0.logout({
            logoutParams: {
                returnTo: process.env.REACT_APP_AUTH0_LOGOUT,
                federated: true,
            },
        });
    };
    return <Nav.Link onClick={onClick}>{display}</Nav.Link>;
};

export default Logout;

// prop-types
Logout.propTypes = {
    display: PropTypes.string,
};
