// Api
import { apiCall } from "Axios/apiCall";

export const onSave = (state, config, dispatch, actions) => {
    const { formData } = state;
    const { clientId } = config;

    return async (e) => {
        e?.preventDefault();

        dispatch(actions.updateFormStatus("processing"));

        let reqObj = new FormData();

        formData.forEach((fd) => {
            const { id, value, isChecked } = fd;
            const theValue =
                typeof isChecked !== "undefined" ? isChecked : value;

            switch (id) {
                case "status":
                    reqObj.append(id, isChecked ? "active" : "inActive");
                    break;
                case "logo":
                    if (fd.fileFormData) {
                        reqObj.append("logo", fd.fileFormData);
                    }
                    break;
                default:
                    reqObj.append(id, theValue);
                    break;
            }
        });

        const resp = await apiCall(
            "put",
            `/auth/clients/v2/${clientId}`,
            reqObj
        );

        const { apiError } = resp;

        if (apiError) {
            dispatch(actions.updateFormStatus("error", resp.message));
        } else {
            dispatch(actions.updateFormStatus("listening"));
        }
    };
};
