import { ReactComponent as OuterRing } from "./svg/ring-green.svg";
import { ReactComponent as InnerRing } from "./svg/ring-blue.svg";
import { ReactComponent as BlueTitleBar } from "./svg/title-bar-blue.svg";
import { ReactComponent as GrayTitleBar } from "./svg/title-bar-gray.svg";
import { ReactComponent as WhiteTitleBar } from "./svg/title-bar-white.svg";
import { ReactComponent as InfoIcon } from "./svg/information-icon.svg";

export const legendSvgIcons = [
    {
        id: 1,
        SvgIcon: OuterRing,
    },
    {
        id: 2,
        SvgIcon: InnerRing,
    },
    {
        id: 3,
        SvgIcon: InfoIcon,
    },
    {
        id: 4,
        SvgIcon: BlueTitleBar,
    },
    {
        id: 5,
        SvgIcon: GrayTitleBar,
    },
    {
        id: 6,
        SvgIcon: WhiteTitleBar,
    },
];

// Merge with language specific provided by BE
export const mapSvgsToList = (list) => {
    return list.map((l) => {
        const lsi = legendSvgIcons.find((lsi) => lsi.id === l.id);
        return {
            ...l,
            SvgIcon: lsi.SvgIcon,
        };
    });
};
