import styled from "styled-components";

/* ClientDetailsContext ---------------------------*/
import { useClientDetailsContext } from "Pages/Auth/ControlPanel/ClientDetails/ClientDetailsContext/index.js";

const TableHead = () => {
    const {
        useInput,
        useCheckbox,
        config: { roleId, clientProfile, isLoading },
    } = useClientDetailsContext();

    return (
        <TableHeadStyled className="TableHead thead-dark">
            <tr>
                <th>#</th>
                <th>Title</th>
                <th>SurId / Token</th>
                {roleId === 1 && <th>Admin Actions</th>}
                <th>Survey Actions</th>
            </tr>
        </TableHeadStyled>
    );
};

export default TableHead;

const TableHeadStyled = styled.thead``;
