import React from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

/* Components ---------------------------*/
import Header from "../Common/Header.jsx";
import Main from "./Main.jsx";
import MainLogo from "./MainLogo/index.jsx";
import MainMenu from "./MainMenu/index.jsx";

const Layout = () => {
    return (
        <LayoutStyled className="Layout">
            <Header>
                <Navbar collapseOnSelect expand="lg" bg="green" variant="green">
                    <Container>
                        <Navbar.Brand href="/">
                            <MainLogo />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse
                            id="responsive-navbar-nav"
                            className="justify-content-end"
                        >
                            <MainMenu />
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Header>
            <Main>
                <Outlet />
            </Main>
        </LayoutStyled>
    );
};

export default Layout;

const LayoutStyled = styled.div`
    #main-logo.show {
        visibility: visible;
    }
    #main-logo.hide {
        visibility: hidden;
    }
`;
