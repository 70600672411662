import React from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

/* Components ---------------------------*/
import Header from '../Common/Header.jsx';
import Main from './Main.jsx';

const Layout = () => {
    return (
        <LayoutStyled className='Layout'>
            <Header></Header>
            <Main>
                <Outlet />
            </Main>
        </LayoutStyled>
    );
}

export default Layout;

const LayoutStyled = styled.div`

`;