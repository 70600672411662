import styled from "styled-components";
import PropTypes from "prop-types";

// Modal Context
import { ReqObjectWrapper, useModalContext } from "Common/BootStrap/Modal";

// components
import ControlWithLabel from "Common/BootStrap/FormControls/ControlWithLabel";
import ControlWithRemoveButton from "Common/BootStrap/FormControls/ControlWithRemoveButton";
import Input from "Common/BootStrap/FormControls/Input";
import Select from "Common/BootStrap/FormControls/Select";

// data
import { linkTypes } from "./data";

const ResourceControl = ({ count, reqObject, survey }) => {
    const { domains } = survey;
    const { reqObjectUpdate } = useModalContext();
    const { id, label, link, type, surveyDomainsId } = reqObject;

    const handleUpdate = (prop) => {
        reqObjectUpdate({
            ...reqObject,
            ...prop,
        });
    };

    const onRemove = () => {
        reqObjectUpdate({
            ...reqObject,
            restType: "destroy",
        });
    };

    const handleDomainSelectChange = (e) => {
        const value = e.target.value;
        handleUpdate({ surveyDomainsId: parseInt(value) });
    };
    const handleLinkTypeSelectChange = (e) => {
        const value = e.target.value;
        handleUpdate({ type: value });
    };

    return (
        <ReqObjectWrapper reqObject={reqObject}>
            <ResourceControlStyled>
                <ControlWithLabel
                    label={`Resource ${count}`}
                    id={`${id}-question`}
                >
                    <ControlWithRemoveButton onRemove={onRemove}>
                        <div className="controls">
                            <div className="text">
                                <Input
                                    id={`${id}-question`}
                                    placeholder="Enter Text"
                                    value={label}
                                    onChange={(e) => {
                                        handleUpdate({
                                            label: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className="url">
                                <Input
                                    id={"resource-placeholder"}
                                    placeholder="Enter Url"
                                    value={link}
                                    onChange={(e) => {
                                        handleUpdate({
                                            link: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className="dropdown">
                                <Select
                                    id={`${id}-dropdown`}
                                    onChange={handleDomainSelectChange}
                                    selectedOption={domains.find(
                                        (d) => d.id === surveyDomainsId
                                    )}
                                    options={domains}
                                    valueKey="id"
                                    displayKey="domain"
                                />
                            </div>
                            <div className="dropdown">
                                <Select
                                    id={"linkType-dropdown-placeholder"}
                                    onChange={handleLinkTypeSelectChange}
                                    selectedOption={linkTypes.find(
                                        (l) => l.value === type
                                    )}
                                    options={linkTypes}
                                />
                            </div>
                        </div>
                    </ControlWithRemoveButton>
                </ControlWithLabel>
            </ResourceControlStyled>
        </ReqObjectWrapper>
    );
};

export default ResourceControl;

// prop-types
ResourceControl.propTypes = {
    count: PropTypes.number.isRequired,
    reqObject: PropTypes.object.isRequired,
    survey: PropTypes.object.isRequired,
};

const ResourceControlStyled = styled.div`
    .controls {
        display: flex;

        > .text {
            flex: 1;
            margin-right: 10px;
        }
        > .url {
            flex: 1;
            margin-right: 10px;
        }
        > .dropdown {
            flex: 0 0 200px;
        }
    }
`;
