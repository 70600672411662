import React from 'react';
import styled from 'styled-components';
import ReactLoading from 'react-loading';

const Preloader = () => {
    return (
        <PreloaderStyled className='Preloader'>
            <ReactLoading type="spin" color="#000" />
        </PreloaderStyled>
    );
}

export default Preloader;

const PreloaderStyled = styled.div`
    margin: 250px auto;
    display: flex;
    justify-content: center;
`;