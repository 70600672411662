import styled from "styled-components";

/* ClientDetailsContext ---------------------------*/
import { useClientDetailsContext } from "Pages/Auth/ControlPanel/ClientDetails/ClientDetailsContext/index.js";

// components
import UniversalForm from "Pages/Auth/ControlPanel/ClientDetails/UniversalForm/index.jsx";
import ControlWithLabel from "Common/BootStrap/FormControls/ControlWithLabel";
import TextArea from "Common/BootStrap/FormControls/TextArea";

const InternalNotes = () => {
    const {
        useInput,
        config: { roleId, clientProfile, isLoading },
    } = useClientDetailsContext();

    // Controlled Inputs
    const internalNotes = useInput({
        id: "internalNotes",
        value: clientProfile?.internalNotes || "",
    });

    if (isLoading) {
        return <div>{isLoading}</div>;
    }
    return (
        <InternalNotesStyled>
            <UniversalForm>
                <ControlWithLabel label="Internal Notes" id="internalNotes">
                    <TextArea
                        id="internalNotes"
                        placeholder="Enter your internal notes"
                        value={internalNotes.value}
                        onChange={internalNotes.onChange}
                        disabled={roleId !== 1}
                        height="100px"
                    />
                </ControlWithLabel>
            </UniversalForm>
        </InternalNotesStyled>
    );
};

export default InternalNotes;

const InternalNotesStyled = styled.div``;
