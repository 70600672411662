import React, { useContext } from 'react';
import styled from 'styled-components';

/* Context ---------------------------*/
import ResultsContext from '../../../ResultsContext/index.js';
import { popUpOpen } from '../../../ResultsContext/actions.js';

/* Components ---------------------------*/
import InformationIcon from './InformationIcon/InformationIcon.jsx';

const Title = ({title, domainResult, hasPopUp, noRelation, bgColor='#42BDEE'}) => {

    const { dispatch } = useContext(ResultsContext);

    const {
        id,
        popUp,
    } = domainResult;

    const handlePopUpOpen = () => {
        if (popUp.messages.length) {
            dispatch(popUpOpen(id));
        }
    }

    return (
        <TitleStyled className={`Title ${noRelation ? 'white' : ''}`} onClick={ handlePopUpOpen }  bgColor={ bgColor }>
            {title}
            {
                hasPopUp &&
                <InformationIcon />
            }
        </TitleStyled>
    );
}

export default Title;

const TitleStyled = styled.h2`

    /* Anchor to center */
    position: absolute;
    left: 50%;
    bottom: 0%;
    transform: translate(-50%, 0%);

    /* Responsive font size = scales against width of parent */
    font-size: 2.7vw;

    /* governs vertical spacing/centering of text and box-height */
    line-height: 3.6vw;

    /* Scaling Border management */
    border-radius: 1.7vw;
    border: solid 0.3vw black;

    /* Other styles */
    color: black;
    text-align: center;
    background-color: ${({bgColor}) => bgColor};
    width: 75%;

    &.white {
        background-color: #fff;
    };
`;