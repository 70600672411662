import styled from "styled-components";
import PropTypes from "prop-types";

const Base64Image = ({ imgObj, fallbackSrc = "", alt, id = null }) => {
    let src = fallbackSrc;

    if (imgObj?.data) {
        const base64String = btoa(
            String.fromCharCode(...new Uint8Array(imgObj.data))
        );
        src = `data:image/png;base64,${base64String}`;
    }

    return <Base64ImageStyled id={id} src={src} alt={alt} />;
};

export default Base64Image;

// prop-types
Base64Image.propTypes = {
    imgObj: PropTypes.object,
    fallbackSrc: PropTypes.string,
    alt: PropTypes.string.isRequired,
    id: PropTypes.string,
};

const Base64ImageStyled = styled.img`
    max-width: 100%;
`;
