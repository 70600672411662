import styled from "styled-components";
import PropTypes from "prop-types";

// components
import SubmissionDate from "./SubmissionDate";
import UserDetails from "./UserDetails";
import SubmissionActions from "./SubmissionActions";

const SurveySubmission = ({
    count,
    survey,
    submission,
    roleId,
    clientIsRestricted,
}) => {
    const { id, submittedBy, createdAt } = submission;

    return (
        <SurveySubmissionStyled>
            <div className="count">
                <b>#</b> {count}
            </div>
            <SubmissionDate createdAt={createdAt} />
            <UserDetails
                roleId={roleId}
                clientIsRestricted={clientIsRestricted}
                submittedBy={submittedBy}
            />
            <SubmissionActions surveyId={survey.id} subId={id} />
        </SurveySubmissionStyled>
    );
};

export default SurveySubmission;

// prop-types
SurveySubmission.propTypes = {
    count: PropTypes.number.isRequired,
    survey: PropTypes.object.isRequired,
    submission: PropTypes.object.isRequired,
    roleId: PropTypes.number.isRequired,
    clientIsRestricted: PropTypes.bool,
};

const SurveySubmissionStyled = styled.div`
    display: grid;
    grid-template-columns: 50px 250px auto 200px;
    grid-gap: 16px;
    align-items: center;

    padding: 4px 20px;

    border-bottom: 1px solid #eee;

    &:last-child {
        border-bottom: none;
    }

    .count {
        text-align: center;
    }
`;
