import React from "react";
import styled from "styled-components";

import { ReactComponent as InformationIconSvg } from "./information-icon.svg";

const InformationLegendIcon = () => {
    return (
        <InformationLegendIconStyled className="InformationLegendIcon">
            <InformationIconSvg />
        </InformationLegendIconStyled>
    );
};

export default InformationLegendIcon;

const InformationLegendIconStyled = styled.div`
    width: 1.5vw;
    height: 1.5vw;
    margin-right: 5px;
    pointer-events: none;

    .information-icon-circle {
        fill: #42bdee;
        stroke: gray;
        stroke-miterlimit: 10;
        stroke-width: 0.63px;
    }
    .information-icon-text {
        fill: #fff;
    }

    &:hover,
    &:focus,
    &:active {
        .information-icon-circle {
            fill: #edc55c;
        }
    }
`;
