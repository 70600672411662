import React, { useContext } from "react";
import styled from "styled-components";

/* Context ---------------------------*/
import RankItemsContext from "../RankItemsContext/index.js";

const Feedback = () => {
    const { state } = useContext(RankItemsContext);

    const {
        openingText,
        rankedText,
        unrankedText,
        thresholds: { min: originalMin },
        conditions: { count },
    } = state;

    // Prefix :: Apparently Broke Examples.
    // Now I get why this was used. Templat string from backend includes it for Language support (e.g. Eng and Port)
    let message = openingText.replace("{numItems}", originalMin);

    return (
        <FeedbackStyled className="Feedback">
            <div
                className="message"
                dangerouslySetInnerHTML={{ __html: message }}
            />
        </FeedbackStyled>
    );
};

export default Feedback;

const FeedbackStyled = styled.div`
    margin: 10px 0px;
    padding: 20px;
    background-color: #43bdef;
    font-size: 16px;
    color: white;
    text-align: center;
`;
