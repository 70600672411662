import styled from "styled-components";
import PropTypes from "prop-types";

const Header = ({ survey }) => {
    console.log({ survey });

    const { name } = survey;
    return <HeaderStyled>{name} Survey</HeaderStyled>;
};

export default Header;

// prop-types
Header.propTypes = {
    survey: PropTypes.object.isRequired,
};

const HeaderStyled = styled.div`
    font-size: 1.75rem;
    font-weight: 600;
`;
