import PropTypes from "prop-types";

// components
import Modal, { ModalButton } from "Common/BootStrap/Modal";
import ModalBody from "./ModalBody";

const OpenText = ({ survey, clientId }) => {
    const { id, openingText } = survey;

    let reqObjects = [];

    if (openingText?.id) {
        reqObjects.push({
            ...openingText,
            restType: "update",
        });
    } else {
        reqObjects.push({
            id: 1000,
            surveyId: id,
            restType: "forge",
            intro: "",
            aboutYourStrength: "",
            aboutYourWeakness: "",
            whatIsImportantToYou: "",
            yourConfidence: "",
            yourStrength: "",
            yourWeakness: "",
            finalThoughts: "",
            userId: clientId,
        });
    }

    return (
        <Modal
            reqObjects={reqObjects}
            apiEndPoint={`/auth/surveys/opening-text`}
            method="post"
        >
            <ModalButton>OpTxt</ModalButton>
            <ModalBody survey={survey} />
        </Modal>
    );
};

export default OpenText;

// prop-types
OpenText.propTypes = {
    survey: PropTypes.object.isRequired,
    clientId: PropTypes.number.isRequired,
};
