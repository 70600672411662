import PropTypes from "prop-types";

// components
import Modal, { ModalButton } from "Common/BootStrap/Modal";
import ModalBody from "./ModalBody";

const OtherQuestions = ({ survey }) => {
    const { otherQuestions } = survey;
    const questions = otherQuestions.map((dq) => {
        return {
            ...dq,
            restType: "update",
        };
    });

    return (
        <Modal
            reqObjects={questions}
            apiEndPoint={`/auth/surveys/other-questions`}
            method="post"
        >
            <ModalButton>OQs</ModalButton>
            <ModalBody survey={survey} />
        </Modal>
    );
};

export default OtherQuestions;

// prop-types
OtherQuestions.propTypes = {
    survey: PropTypes.object.isRequired,
};
