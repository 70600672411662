import { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// context
import { useRankItems } from "Common/useRankItems";
import { useSurvey } from "Pages/Auth/Survey/SurveyForm/SurveyContext";

const Ranked = ({ section }) => {
    const state = useSurvey();
    const { Utils, answerUpdate } = state;

    // Note: sKey does not work here, otherwise it gets lumped into `otherQuestions` section.
    const { number, sKey } = Utils.getDecoratedSection(section, state);

    const { RankItems, ranked, unranked } = useRankItems();

    // These do not currently get stored with qDbId, making one up for now to normalize answer shape.
    const qDbId = 99999999999;

    useEffect(() => {
        ranked.forEach((r) => {
            const { id: qID, value: answer } = r;
            answerUpdate({
                sNumber: number,
                sKey: "rankedDomains",
                qID,
                qDbId,
                answer,
            });
        });
        unranked.forEach((r) => {
            const { id: qID, value: answer } = r;
            answerUpdate({
                sNumber: number,
                sKey: "unrankedDomains",
                qID,
                qDbId,
                answer,
            });
        });
    }, [ranked, unranked]);

    return (
        <RankedStyled>
            <RankItems />
        </RankedStyled>
    );
};

export default Ranked;

// prop-types
Ranked.propTypes = {
    section: PropTypes.object.isRequired,
};

const RankedStyled = styled.div`
    margin-bottom: 30px;
`;
