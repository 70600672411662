import styled from "styled-components";

// components
import SectionCollection from "./SectionCollection";
import Sharks from "./Sharks";
import PostShark from "./Sharks/PostShark";

const HealthCheck = () => {
    return (
        <HealthCheckStyled>
            <h2>Health Check</h2>
            <SectionCollection title="Sharks">
                <Sharks />
                <PostShark />
            </SectionCollection>
        </HealthCheckStyled>
    );
};

export default HealthCheck;

const HealthCheckStyled = styled.div``;
