/* Hooks ---------------------------*/
import { useAuth } from "Auth0/useAuth.js";

/* Components ---------------------------*/
import Base64Image from "Common/Base64Image";

const MainLogo = () => {
    const { branding } = useAuth();
    const { defaultLogo, client } = branding;
    const { logo } = client;
    return (
        <Base64Image
            id="main-logo"
            alt="Logo"
            imgObj={logo}
            fallbackSrc={defaultLogo}
        />
    );
};
export default MainLogo;
