export const rankItemsConfig = {
    openingText: `Please rank your top {numItems} items with 1 being the most important to you. Tap the + to choose an item.`,
    rankedText: `Ranked`,
    unrankedText: `Unranked`,
    thresholds: {
        min: 4,
        max: 1000,
    },
    ranked: [],
    unranked: [
        {
            id: "001",
            value: "<b>Mental Health</b>",
        },
        {
            id: "002",
            value: "<b>Finances</b>",
        },
        {
            id: "003",
            value: "<b>Academics</b>",
        },
        {
            id: "004",
            value: "<b>Physical Health</b>",
        },
        {
            id: "005",
            value: "<b>Family</b>",
        },
        {
            id: "006",
            value: "<b>Commuting</b>",
        },
        {
            id: "007",
            value: "<b>Concentration</b>",
        },
        {
            id: "008",
            value: "<b>Belonging</b>",
        },
    ],
};
