import { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Modal Context
import { useModalContext } from "Common/BootStrap/Modal";

// components
import ControlWithLabel from "Common/BootStrap/FormControls/ControlWithLabel";
import Input from "Common/BootStrap/FormControls/Input";

const AddDomain = ({ survey }) => {
    const { reqObjectAdd } = useModalContext();
    const [value, valueUpdate] = useState("");

    const handleAddDomain = () => {
        reqObjectAdd({
            surveyId: survey.id,
            userId: survey.userId,
            domain: value,
        });
        valueUpdate("");
    };
    return (
        <AddDomainStyled>
            <ControlWithLabel label={`Add New Domain`} id={"placeholder"}>
                <Input
                    id={"placeholder"}
                    placeholder="Enter Domain Name"
                    value={value}
                    onChange={(e) => valueUpdate(e.target.value)}
                />
            </ControlWithLabel>
            <button className="btn btn-secondary" onClick={handleAddDomain}>
                Add Domain
            </button>
        </AddDomainStyled>
    );
};

export default AddDomain;

// prop-types
AddDomain.propTypes = {
    survey: PropTypes.object.isRequired,
};

const AddDomainStyled = styled.div`
    margin-bottom: 40px;
`;
