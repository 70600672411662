import React, { useState, useRef } from 'react';
import styled from 'styled-components';

/* Components ---------------------------*/
import Text from './Text.jsx';
import Tooltip from './Tooltip.jsx';

const TextCol = ({ item, ranked, domOrder }) => {
    const [showToolTip, showToolTipUpdate] = useState(false);

    let timer = useRef();

    const onMouseEnter = () => {
        timer.current = setTimeout(() => showToolTipUpdate(true), 1000);
    };

    const onMouseLeave = () => {
        clearTimeout(timer.current);
        showToolTipUpdate(false);
    };

    return (
        <TextColStyled
            className="TextCol"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <Text item={item} domOrder={domOrder} ranked={ranked} />
            <Tooltip text={item.value} showToolTip={showToolTip} />
        </TextColStyled>
    );
};

export default TextCol;

const TextColStyled = styled.div`
    display: flex;
    align-items: center;
`;
