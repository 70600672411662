import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";

/* Redux ---------------------------*/
import { useGetPreloadedStateQuery } from "Redux/apiSlice";

/* Components ---------------------------*/
import Preloader from "Common/Preloader.jsx";

const AllRoutes = () => {
    const { data: appData } = useGetPreloadedStateQuery();

    if (!appData) {
        return <Preloader />;
    }

    const {
        config: { language },
    } = appData;

    return (
        <>
            <Helmet>
                <title>Assessment Tool</title>
                <meta name="region" content="EU" />
                <meta name="language" content={language} />
            </Helmet>
            <Outlet />
        </>
    );
};

export default AllRoutes;
