import PropTypes from "prop-types";

// context
import { RankItemsProvider } from "Common/useRankItems";
import { useSurvey } from "Pages/Auth/Survey/SurveyForm/SurveyContext";

// components
import Ranked from "./Ranked";

const Ranking = ({ section }) => {
    const state = useSurvey();
    const { Utils } = state;
    const { rankedItemsConfig } = Utils.getDecoratedSection(section, state);

    if (!rankedItemsConfig) {
        return null;
    }

    return (
        <RankItemsProvider config={rankedItemsConfig}>
            <Ranked section={section} />
        </RankItemsProvider>
    );
};

export default Ranking;

// prop-types
Ranking.propTypes = {
    section: PropTypes.object.isRequired,
};
