import { actionTypes } from './actionTypes.js';

/*---------------------------
| State Updater (reducer)
---------------------------*/
const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.RES_POPUP_OPEN: {
            const domains = state.domains.map((d) => {
                if (d.id === action.domainId) {
                    return {
                        ...d,
                        popUp: {
                            ...d.popUp,
                            show: true,
                            currentMessage: 0,
                        },
                    };
                }
                return {
                    ...d,
                    popUp: {
                        ...d.popUp,
                        show: false,
                        currentMessage: 0,
                    },
                };
            });

            return {
                ...state,
                domains: domains,
            };
        }
        case actionTypes.RES_POPUP_CLOSE: {
            const domains = state.domains.map((d) => {
                if (d.id === action.domainId) {
                    return {
                        ...d,
                        popUp: {
                            ...d.popUp,
                            show: false,
                            currentMessage: 0,
                        },
                    };
                }
                return {...d};
            });

            return {
                ...state,
                domains: domains,
            };
        }
        case actionTypes.RES_PERC_OPTIONS_NEXT: {
            const domains = state.domains.map((d) => {
                if (d.id === action.domainId) {

                    const {
                        currPercOptionIdx,
                        percOptions,
                    } = d.percents;

                    let newCurrPercOptionIdx = currPercOptionIdx;
                    const currentMessagePlusOne = newCurrPercOptionIdx + 1;

                    // Only add one if we have not reached the end
                    if (currentMessagePlusOne < percOptions.length) {
                        newCurrPercOptionIdx = currentMessagePlusOne;
                    }

                    return {
                        ...d,
                        percents: {
                            ...d.percents,
                            currPercOptionIdx: newCurrPercOptionIdx,
                        },
                    };
                }
                return {...d};
            });

            return {
                ...state,
                domains: domains,
            };
        }
        case actionTypes.RES_PERC_OPTIONS_PREV: {
            const domains = state.domains.map((d) => {
                if (d.id === action.domainId) {

                    const {
                        currPercOptionIdx,
                    } = d.percents;

                    let newCurrPercOptionIdx = currPercOptionIdx;
                    const currentMessageMinusOne = newCurrPercOptionIdx - 1;

                    // Only add one if we have not reached the end
                    if (currentMessageMinusOne >= 0) {
                        newCurrPercOptionIdx = currentMessageMinusOne;
                    }

                    return {
                        ...d,
                        percents: {
                            ...d.percents,
                            currPercOptionIdx: newCurrPercOptionIdx,
                        },
                    };
                }
                return {...d};
            });

            return {
                ...state,
                domains: domains,
            };
        }

        default: {
            return { ...state };
        }
    }
};

export default reducer;
