import { actionTypes } from "./actionTypes";

/*---------------------------
| Reducer
---------------------------*/
const reducer = (state, action) => {
    const { type } = action;
    // console.group("Survey Context");
    // console.log({ actionType: type });
    // console.log({ action });
    // console.groupEnd();

    switch (type) {
        case actionTypes.PRELOAD_STATE_UPDATE: {
            const { apiState } = action;
            return { ...state, ...apiState, hasLoaded: true };
        }
        case actionTypes.ANSWER_UPDATE: {
            const { answerObject } = action;
            const answers = getUpdatedAnswers(answerObject, state.answers);
            const sectionIdsCompleted = updateSecionIdsCompleted(
                state.sections,
                answers
            );
            const canSubmitForm = updateCanSubmitForm(
                state.sections,
                sectionIdsCompleted
            );
            return { ...state, answers, sectionIdsCompleted, canSubmitForm };
        }
        case actionTypes.IS_SUBMITTING_UPDATE: {
            const { isSubmitting } = action;
            return { ...state, isSubmitting };
        }
        default: {
            return { ...state };
        }
    }
};

export default reducer;

const getUpdatedAnswers = (newAnswer, stateAnswers) => {
    let newAnswers = [...stateAnswers];
    const answerExists = newAnswers.find((a) => a.qID === newAnswer.qID);
    const isNonAnswer = !newAnswer.answer || newAnswer.answer === "unanswered";

    if (answerExists) {
        if (isNonAnswer) {
            // Remove
            newAnswers = stateAnswers.filter((a) => {
                return a.qID !== newAnswer.qID;
            });
        } else {
            // Update
            newAnswers = stateAnswers.map((a) => {
                if (a.qID === newAnswer.qID) {
                    return newAnswer;
                }

                return a;
            });
        }
    } else if (!isNonAnswer) {
        // Add
        newAnswers.push(newAnswer);
    }

    return newAnswers;
};

// always assume none are completed
const updateSecionIdsCompleted = (sections, answers) => {
    let sectionIdsCompleted = ["introduction"];

    sections.forEach((s) => {
        const { id, number, sKey, questions, rankedItemsConfig } = s;
        const sectionAnswers = answers.filter(
            (a) => a.sNumber === number && a.sKey === sKey
        );
        let isComplete = true;

        // account for questions
        if (questions.length !== sectionAnswers.length) {
            isComplete = false;
        }

        // Ranked Items: If the section has it
        if (rankedItemsConfig) {
            const rankedItems = answers.filter(
                (a) => a.sNumber === number && a.sKey === "rankedDomains"
            );
            if (rankedItems) {
                const {
                    thresholds: { min, max },
                } = rankedItemsConfig;

                // evaluate, did they rank enough items?
                const rankedCount = rankedItems.length;
                if (!(rankedCount >= min && rankedCount <= max)) {
                    isComplete = false;
                }
            }
        }

        if (isComplete) {
            sectionIdsCompleted.push(id);
        }
    });

    return sectionIdsCompleted;
};

const updateCanSubmitForm = (sections, sectionIdsCompleted) => {
    // ned to account for introduction as well
    return sectionIdsCompleted.length > sections.length;
};
