import styled from "styled-components";
import PropTypes from "prop-types";

import ResultsInline from "Common/Results/ResultsInline";

const ImproveBy = ({ category }) => {
    const { title, questionsAndPercentages } = category;

    if (questionsAndPercentages.length < 1) {
        return null;
    }

    return (
        <ImproveByStyled>
            <h3>{title}</h3>
            {questionsAndPercentages.map((questionAndPercentage) => {
                return (
                    <QuestionAndPercentage
                        key={questionAndPercentage.id}
                        questionAndPercentage={questionAndPercentage}
                    />
                );
            })}
        </ImproveByStyled>
    );
};

const QuestionAndPercentage = ({ questionAndPercentage }) => {
    const { question, percentages } = questionAndPercentage;

    return (
        <QuestionAndPercentageStyled>
            <div className="question">{question}</div>
            <ResultsInline {...percentages} />
        </QuestionAndPercentageStyled>
    );
};

export default ImproveBy;

// prop-types
ImproveBy.propTypes = {
    category: PropTypes.object.isRequired,
};
QuestionAndPercentage.propTypes = {
    questionAndPercentage: PropTypes.object.isRequired,
};

// styles
const ImproveByStyled = styled.div`
    margin-top: 50px;
    h3 {
        font-size: 16px;
    }
`;
const QuestionAndPercentageStyled = styled.div`
    margin: 50px 0px;

    .question {
        max-width: 600px;
        background-color: #f7f6f6;
        padding: 10px;
        margin: auto;
    }

    .ResultsInline {
        max-width: 500px;
        margin: 10px auto;
    }
`;
