// components
import {
    ModalBody as BSModalBody,
    useModalContext,
} from "Common/BootStrap/Modal";

// components
import ControlWithLabel from "Common/BootStrap/FormControls/ControlWithLabel";
import Input from "Common/BootStrap/FormControls/Input";

const ModalBody = () => {
    const { reqObjects, reqObjectUpdate } = useModalContext();
    const reqObject = reqObjects.find((ro) => ro.id === 1000);
    const { name } = reqObject;

    const handleUpdate = (prop) => {
        reqObjectUpdate({
            ...reqObject,
            ...prop,
        });
    };

    return (
        <BSModalBody title="Add Survey" saveButtonText="Add Survey">
            <ControlWithLabel label="Survey Name" id="newSurvey">
                <Input
                    id="newSurvey"
                    placeholder="Enter survey name"
                    value={name}
                    onChange={(e) => {
                        handleUpdate({ name: e.target.value });
                    }}
                />
            </ControlWithLabel>
        </BSModalBody>
    );
};

export default ModalBody;
