import styled from "styled-components";
import PropTypes from "prop-types";

const OpeningText = ({ openingText }) => {
    if (!openingText) {
        return null;
    }

    return (
        <OpeningTextStyled>
            <div
                dangerouslySetInnerHTML={{
                    __html: openingText,
                }}
            />
        </OpeningTextStyled>
    );
};

export default OpeningText;

// prop-types
OpeningText.propTypes = {
    openingText: PropTypes.string,
};

const OpeningTextStyled = styled.div`
    margin-bottom: 50px;
`;
