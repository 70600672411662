import styled from "styled-components";
import PropTypes from "prop-types";

// components
import SelectMenu from "./SelectMenu";
import Textarea from "./Textarea";

const Controls = ({ decQuestion }) => {
    const { type } = decQuestion;

    return (
        <ControlsStyled>
            {type === "dropdown" ? (
                <SelectMenu {...{ decQuestion }} />
            ) : (
                <Textarea {...{ decQuestion }} />
            )}
        </ControlsStyled>
    );
};

export default Controls;

// prop-types
Controls.propTypes = {
    decQuestion: PropTypes.object.isRequired,
};

const ControlsStyled = styled.div``;
