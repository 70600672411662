import React from "react";

/* ClientDetailsContext ---------------------------*/
import { ClientDetailsContextProvider } from "Pages/Auth/ControlPanel/ClientDetails/ClientDetailsContext/index.js";

/* Components ---------------------------*/
import ClientSurveySubmissions from "./ClientSurveySubmissions";

export default () => {
    return (
        <ClientDetailsContextProvider>
            <ClientSurveySubmissions />
        </ClientDetailsContextProvider>
    );
};
