import styled from "styled-components";
import PropTypes from "prop-types";

// components
import SurveySubmission from "./SurveySubmission";

const SurveySubmissionList = ({ survey, roleId, clientIsRestricted }) => {
    const { submissions } = survey;

    if (!submissions.length) {
        return (
            <NoSubmissionsStyled>
                Currently, there are no submissions for this survey.
            </NoSubmissionsStyled>
        );
    }

    return (
        <SurveySubmissionListStyled>
            {submissions.map((submission, idx) => {
                return (
                    <SurveySubmission
                        count={idx + 1}
                        key={submission.id}
                        survey={survey}
                        submission={submission}
                        roleId={roleId}
                        clientIsRestricted={clientIsRestricted}
                    />
                );
            })}
        </SurveySubmissionListStyled>
    );
};

export default SurveySubmissionList;

// prop-types
SurveySubmissionList.propTypes = {
    survey: PropTypes.object.isRequired,
    roleId: PropTypes.number.isRequired,
    clientIsRestricted: PropTypes.bool,
};

const NoSubmissionsStyled = styled.div`
    padding: 20px 16px;
`;
const SurveySubmissionListStyled = styled.div``;
