/* Redux ---------------------------*/
import { useGetSurveyFormQuery } from "Redux/apiAuthSurveySlice";

/*===================================
||
|| SurveyContext
||
===================================*/
import React, {
    createContext,
    useReducer,
    useMemo,
    useEffect,
    useContext,
} from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

/* Hooks ---------------------------*/
import { useAuth } from "Auth0/useAuth.js";

/*---------------------------
| Context
---------------------------*/
const SurveyContext = createContext();
export default SurveyContext;
import reducer from "./reducer";
import getDefaultState from "./getDefaultState";
import * as Actions from "./actions";
import * as Utils from "./utils";

/*---------------------------
| Components
---------------------------*/
import Preloader from "Common/Preloader.jsx";

/*---------------------------
| Provider
---------------------------*/
export const SurveyContextProvider = ({ children }) => {
    const { surveyToken } = useParams();
    const { userProfile } = useAuth();

    const defaultState = {
        surveyToken,
        userProfile,
        questions: [],
    };

    const [state, dispatch] = useReducer(
        reducer,
        getDefaultState(defaultState)
    );

    // useMemo so it does not pass value on every render
    const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

    return (
        <SurveyContext.Provider value={value} displayName={"SurveyContext"}>
            <MountingWrapper>{children}</MountingWrapper>
        </SurveyContext.Provider>
    );
};

// prop-types
SurveyContextProvider.propTypes = {
    children: PropTypes.any,
};

/*---------------------------
| MountingWrapper for onMount and global sideEffect Listeners
---------------------------*/
const MountingWrapper = ({ children }) => {
    // Context
    const { state, dispatch } = useContext(SurveyContext);
    const { surveyToken, hasLoaded } = state;

    // Redux :: Should have just used Redux State to manage this instead of redux and Context
    const { data, isError } = useGetSurveyFormQuery(surveyToken);

    // Mounting API Calls and Setup
    useEffect(() => {
        if (data) {
            // Merge Redux State to Context State
            dispatch(Actions.preloadStateUpdate(data));
        }
    }, [data]);

    if (isError) return <div>Whoops! Something went wrong</div>;
    if (!hasLoaded) return <Preloader />;

    return <>{children}</>;
};

// prop-types
MountingWrapper.propTypes = {
    children: PropTypes.any,
};

/*---------------------------
| useSurveyContext
---------------------------*/
export const useSurvey = () => {
    const { state, dispatch } = useContext(SurveyContext);

    const answerUpdate = (answerObject) => {
        dispatch(Actions.answerUpdate(answerObject));
    };
    const isSubmittingUpdate = (isSubmitting) => {
        dispatch(Actions.isSubmittingUpdate(isSubmitting));
    };

    return {
        ...state,
        Utils,
        answerUpdate,
        isSubmittingUpdate,
    };
};
