import styled from "styled-components";

/* ClientDetailsContext ---------------------------*/
import { useClientDetailsContext } from "Pages/Auth/ControlPanel/ClientDetails/ClientDetailsContext/index.js";

// components
import Row from "./Row";

const RowsList = () => {
    const {
        config: { surveys },
    } = useClientDetailsContext();

    return (
        <RowsListStyled>
            {surveys.map((survey, idx) => {
                return <Row key={survey.id} count={idx + 1} survey={survey} />;
            })}
        </RowsListStyled>
    );
};

export default RowsList;

const RowsListStyled = styled.tbody``;
