import styled from "styled-components";
import PropTypes from "prop-types";

// context
import { useSurvey } from "Pages/Auth/Survey/SurveyForm/SurveyContext";

// components
import NextButton from "./NextButton";

const Body = ({ children, sID }) => {
    const state = useSurvey();
    const { Utils } = state;
    const { isComplete } = Utils.getDecoratedSectionById(sID, state);
    const bgComplete = sID !== "introduction" && isComplete;

    return (
        <BodyStyled bgComplete={bgComplete}>
            {children}
            <NextButton sID={sID} />
        </BodyStyled>
    );
};

export default Body;

// prop-types
Body.propTypes = {
    children: PropTypes.any,
    sID: PropTypes.string.isRequired,
};

const BodyStyled = styled.div`
    padding: 50px 20px;
    ${({ bgComplete }) => bgComplete && `background-color: #f4fcff;`}
`;
