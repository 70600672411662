import { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Modal Context
import { useModalContext } from "Common/BootStrap/Modal";

// components
import ControlWithLabel from "Common/BootStrap/FormControls/ControlWithLabel";
import TextArea from "Common/BootStrap/FormControls/TextArea";
import Select from "Common/BootStrap/FormControls/Select";

const AddQuestion = ({ survey }) => {
    const { domains } = survey;
    const { reqObjectAdd } = useModalContext();
    const [question, questionUpdate] = useState("");
    const [selectedDomain, selectedDomainUpdate] = useState(domains[0]);

    const handleAddQuestion = () => {
        reqObjectAdd({
            surveyId: survey.id,
            userId: survey.userId,
            surveyDomainId: selectedDomain.id,
            domain: selectedDomain.domain,
            question,
        });
        questionUpdate("");
    };

    const handleOnSelectChange = (e) => {
        const value = e.target.value;
        const newSelectedDomain = domains.find((d) => d.id === parseInt(value));
        selectedDomainUpdate(newSelectedDomain);
    };

    return (
        <AddQuestionStyled>
            <ControlWithLabel
                label={`Add New Question`}
                id={"question-placeholder"}
            >
                <div className="controls">
                    <div className="question">
                        <TextArea
                            id={"question-placeholder"}
                            placeholder="Enter Question"
                            value={question}
                            onChange={(e) => questionUpdate(e.target.value)}
                            height="38px"
                        />
                    </div>
                    <div className="dropdown">
                        <Select
                            id={"dropdown-placeholder"}
                            onChange={handleOnSelectChange}
                            selectedOption={selectedDomain}
                            options={domains}
                            valueKey="id"
                            displayKey="domain"
                        />
                    </div>
                </div>
            </ControlWithLabel>
            <footer>
                <button
                    className="btn btn-secondary"
                    onClick={handleAddQuestion}
                >
                    Add Question
                </button>
            </footer>
        </AddQuestionStyled>
    );
};

export default AddQuestion;

// prop-types
AddQuestion.propTypes = {
    survey: PropTypes.object.isRequired,
};

const AddQuestionStyled = styled.div`
    margin-bottom: 40px;

    .controls {
        display: flex;

        > .question {
            flex: 1;
            margin-right: 10px;
        }
        > .dropdown {
            flex: 0 0 200px;
        }
    }

    footer {
        text-align: right;
    }
`;
