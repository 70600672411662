import React from "react";
import styled from "styled-components";

const Percentages = ({ confPercent, impPercent }) => {
    return (
        <PercentagesStyled className="Percentages">
            <h2 className="confidence">Confidence {confPercent}%</h2>
            <h2 className="importance">Importance {impPercent}%</h2>
        </PercentagesStyled>
    );
};

export default Percentages;

const PercentagesStyled = styled.div`
    h2 {
        margin: 0px;
        font-weight: 600;
        color: #1f7d67;
        font-size: 20px;

        &.importance {
            color: #0073ba;
        }
    }
`;
