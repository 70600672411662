import styled from "styled-components";
import PropTypes from "prop-types";
import { MDBFile } from "mdb-react-ui-kit";

const FileUpload = ({ id, onChange }) => {
    return (
        <FileUploadStyled>
            <MDBFile size="sm" id={id} onChange={onChange} accept="image/*" />
        </FileUploadStyled>
    );
};

export default FileUpload;

// prop-types
FileUpload.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
};

const FileUploadStyled = styled.div``;
