import styled from "styled-components";
import PropTypes from "prop-types";

// data
import { mapSvgsToList } from "./data";

// styles
import { mediaQueries } from "Common/useMediaQuery.js";

const LegendList = ({ list }) => {
    return (
        <>
            {mapSvgsToList(list).map((legendItem) => {
                return (
                    <LegendListItem
                        key={legendItem.id}
                        legendItem={legendItem}
                    />
                );
            })}
        </>
    );
};
const LegendListItem = ({ legendItem }) => {
    const { SvgIcon, text } = legendItem;

    return (
        <LegendListItemStyled>
            <div className="icon">
                <SvgIcon />
            </div>
            <div className="text" dangerouslySetInnerHTML={{ __html: text }} />
        </LegendListItemStyled>
    );
};

export default LegendList;

// prop-types
LegendList.propTypes = {
    list: PropTypes.array.isRequired,
};
LegendListItem.propTypes = {
    legendItem: PropTypes.object.isRequired,
};

const LegendListItemStyled = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 3px;

    .icon {
        flex: 0 0 20px;
        margin-right: 5px;
    }
    .text {
        font-size: 14px;
        flex: 1;
    }
    @media screen and ${mediaQueries.mdUp} {
        margin-bottom: 5px;
        .icon {
            flex: 0 0 40px;
            margin-right: 10px;
        }
        .text {
            font-size: 18px;
            flex: 1;
        }
    }
`;
