import React from "react";
import styled from "styled-components";

/* Data ---------------------------*/
// import { rankItemsConfig } from './rankItemsConfigFruit.js';
import { rankItemsConfig } from "./rankItemsConfigDomains.js";
// import { rankItemsConfig } from './rankItemsConfigDomainsThree.js';

/* RankItems ---------------------------*/
import { RankItemsProvider } from "Common/useRankItems";

/* Components ---------------------------*/
import RankItemsHost from "./RankItemsHost.jsx";

const RankItemsPage = () => {
    return (
        <RankItemsPageStyled className="RankItemsPage">
            <h2>Rank Items Example</h2>
            <RankItemsProvider config={rankItemsConfig}>
                <RankItemsHost />
            </RankItemsProvider>
        </RankItemsPageStyled>
    );
};

export default RankItemsPage;

const RankItemsPageStyled = styled.div``;
