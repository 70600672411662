import styled from "styled-components";

const Divider = () => {
    return <DividerStyled />;
};

export default Divider;

const DividerStyled = styled.div`
    height: 20px;
    background-color: #42bdef;
    margin: 40px auto 20px;
`;
