import styled from "styled-components";

/* ClientDetailsContext ---------------------------*/
import { useClientDetailsContext } from "Pages/Auth/ControlPanel/ClientDetails/ClientDetailsContext/index.js";

// components
import UniversalForm from "Pages/Auth/ControlPanel/ClientDetails/UniversalForm/index.jsx";
import ControlWithLabel from "Common/BootStrap/FormControls/ControlWithLabel";
import Input from "Common/BootStrap/FormControls/Input";
import TextArea from "Common/BootStrap/FormControls/TextArea";
import Checkbox from "Common/BootStrap/FormControls/Checkbox";

const AccountDetails = () => {
    const {
        useInput,
        useCheckbox,
        config: { roleId, clientProfile, isLoading },
    } = useClientDetailsContext();

    // Controlled Inputs
    const organization = useInput({
        id: "organization",
        value: clientProfile?.organization,
    });
    const firstName = useInput({
        id: "first_name",
        value: clientProfile?.first_name,
    });
    const lastname = useInput({
        id: "last_name",
        value: clientProfile?.last_name,
    });
    const email = useInput({ id: "email", value: clientProfile?.email });
    const phone = useInput({
        id: "phone_number",
        value: clientProfile?.phone_number,
    });
    const intro = useInput({ id: "intro", value: clientProfile?.intro });
    const restricted = useCheckbox({
        id: "restricted",
        isChecked: !!clientProfile?.restricted,
    });

    if (isLoading) {
        return <div>{isLoading}</div>;
    }

    return (
        <AccountDetailsStyled>
            <UniversalForm>
                <div className="mb-3">
                    <div className="col-md-12">
                        <ControlWithLabel
                            label="Organization name"
                            id="organization"
                        >
                            <Input
                                id="organization"
                                placeholder="Enter your organization name"
                                value={organization.value}
                                onChange={organization.onChange}
                            />
                        </ControlWithLabel>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <ControlWithLabel label="First name" id="first_name">
                            <Input
                                id="first_name"
                                placeholder="Enter your first name"
                                value={firstName.value}
                                onChange={firstName.onChange}
                            />
                        </ControlWithLabel>
                    </div>
                    <div className="col-md-6">
                        <ControlWithLabel label="Last name" id="last_name">
                            <Input
                                id="last_name"
                                placeholder="Enter your last name"
                                value={lastname.value}
                                onChange={lastname.onChange}
                            />
                        </ControlWithLabel>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <ControlWithLabel label="Email address" id="email">
                            <Input
                                id="email"
                                placeholder="Enter your email address"
                                value={email.value}
                                onChange={email.onChange}
                                disabled={roleId !== 1}
                            />
                        </ControlWithLabel>
                    </div>
                    <div className="col-md-6">
                        <ControlWithLabel
                            label="Phone number"
                            id="phone_number"
                        >
                            <Input
                                id="phone_number"
                                placeholder="Enter your phone number"
                                value={phone.value}
                                onChange={phone.onChange}
                            />
                        </ControlWithLabel>
                    </div>
                </div>
                <div className="mb-3">
                    <ControlWithLabel
                        label="Introduction (deprecated)"
                        id="intro"
                    >
                        <p>
                            This should no longer be used in favor of Survey
                            Opening text Intro. Leaving this here for now in
                            case you want to copy it over somewhere.
                        </p>
                        <TextArea
                            id="intro"
                            placeholder="Enter your introduction"
                            value={intro.value}
                            onChange={intro.onChange}
                        />
                    </ControlWithLabel>
                </div>
                {roleId === 1 && (
                    <div className="mb-3">
                        <ControlWithLabel
                            label="Restrict Client from seeing user info"
                            id="restricted"
                        >
                            <Checkbox
                                id="restricted"
                                isChecked={restricted.isChecked}
                                onChange={restricted.onChange}
                                textIfChecked="Is Restricted"
                                textIfNotChecked="Is Not Restricted"
                            />
                        </ControlWithLabel>
                    </div>
                )}
            </UniversalForm>
        </AccountDetailsStyled>
    );
};

export default AccountDetails;

const AccountDetailsStyled = styled.div``;
