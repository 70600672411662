import styled from "styled-components";
import PropTypes from "prop-types";

// components
import LegendGroups from "./LegendGroups";

import { mediaQueries } from "Common/useMediaQuery.js";

const Legend = ({ legend }) => {
    const { title, description, lists } = legend;
    const { primary, secondary } = lists;

    return (
        <LegendStyled>
            <h3>{title}</h3>
            <div dangerouslySetInnerHTML={{ __html: description }} />
            <LegendGroups primary={primary} secondary={secondary} />
        </LegendStyled>
    );
};

export default Legend;

// prop-types
Legend.propTypes = {
    legend: PropTypes.object.isRequired,
};

const LegendStyled = styled.div`
    background-color: #dfeed2;
    padding: 20px;
    margin-bottom: 30px;

    h3 {
        font-weight: bold;
        font-size: 14px;
        font-family: Helvetica, Arial, sans-serif;
    }

    p {
        font-size: 14px;
    }

    @media screen and ${mediaQueries.mdUp} {
        h3 {
            font-size: 18px;
        }

        p {
            font-size: 18px;
        }
    }
`;
