import { actionTypes } from "./actionTypes";

/*---------------------------
| Action Creators
---------------------------*/
export const preloadStateUpdate = (apiState) => {
    return {
        type: actionTypes.PRELOAD_STATE_UPDATE,
        apiState,
    };
};

export const answerUpdate = (answerObject) => {
    return {
        type: actionTypes.ANSWER_UPDATE,
        answerObject,
    };
};

export const isSubmittingUpdate = (isSubmitting) => {
    return {
        type: actionTypes.IS_SUBMITTING_UPDATE,
        isSubmitting,
    };
};
