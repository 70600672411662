import { useContext, useEffect, useRef } from "react";
import BSAccordionContext from "react-bootstrap/AccordionContext";
import styled from "styled-components";
import PropTypes from "prop-types";

const Header = ({ id, eventKey, title, accordionStatusMessage }) => {
    const { activeEventKey } = useContext(BSAccordionContext);

    // FIXME: I think we no longer need or use the Ref
    const headerRef = useRef(null);

    // Add section to sections state
    useEffect(() => {
        if (eventKey === activeEventKey) {
            const nextAccordionItem = document.getElementById(id);
            setTimeout(() => {
                nextAccordionItem.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                });
            }, 500);
        }
    }, [activeEventKey, eventKey]);

    return (
        <HeaderStyled ref={headerRef}>
            <div className="title">{title}</div>
            {accordionStatusMessage && (
                <div className="accordionStatusMessage">
                    {accordionStatusMessage}
                </div>
            )}
        </HeaderStyled>
    );
};

export default Header;

// prop-types
Header.propTypes = {
    title: PropTypes.string.isRequired,
    accordionStatusMessage: PropTypes.string,
    eventKey: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
};

const HeaderStyled = styled.div`
    display: grid;
    grid-template-columns: auto 150px;
    align-items: center;

    width: 100%;
    margin-right: 20px;

    .title {
        font-weight: bold;
        font-size: 20px;
    }
    .accordionStatusMessage {
        text-align: right;
    }
`;
