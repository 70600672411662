import PropTypes from "prop-types";

const Input = ({ id, placeholder, value, onChange, disabled = false }) => {
    return (
        <input
            id={id}
            className="form-control"
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
        />
    );
};

export default Input;

// prop-types
Input.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
};
