import styled from "styled-components";
import PropTypes from "prop-types";

const ControlWithRemoveButton = ({ children, onRemove }) => {
    return (
        <ControlWithRemoveButtonStyled>
            <div className="control">{children}</div>
            <div className="remove">
                <button type="button" onClick={onRemove}>
                    X
                </button>
            </div>
        </ControlWithRemoveButtonStyled>
    );
};

export default ControlWithRemoveButton;

// prop-types
ControlWithRemoveButton.propTypes = {
    children: PropTypes.any,
    onRemove: PropTypes.func,
};

const ControlWithRemoveButtonStyled = styled.div`
    display: flex;
    align-items: center;
    min-height: 38px;

    .control {
        flex: 1;
    }
    .remove {
        flex: 0 0 30px;
        width: 100%;
        height: 38px;

        button {
            background-color: transparent;
            border: none;
            display: block;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: solid 1px #ccc;
            border-left: none;
            border-radius: 0px 5px 5px 0px;

            &:hover {
                background-color: #eee;
            }
        }
    }
`;
