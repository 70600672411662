import React from "react";
import styled from "styled-components";
import { roundPercentage } from "../../../../Common/utils";

const Percentages = ({ percents }) => {
    const { confidence, importance, hidePercentages } = percents;

    if (hidePercentages) return null;

    return (
        <PercentagesStyled className="Percentages">
            <div className="percent confidence">
                {roundPercentage(confidence)}%
            </div>
            <div className="percent importance">
                {roundPercentage(importance)}%
            </div>
        </PercentagesStyled>
    );
};

export default Percentages;

const PercentagesStyled = styled.div`
    background-color: rgba(255, 255, 255, 0.8);
    padding: 0.7vw;

    /* Anchor to center */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(4%, -155%);

    width: 11vw;

    .percent {
        font-size: 3.7vw;
        font-weight: 600;
        line-height: 2vw;
        font-family: "Poppins", sans-serif;

        &.confidence {
            color: #1f7d67;
            margin-bottom: 2.5vw;
        }
        &.importance {
            color: #0071ba;
        }
    }
`;
