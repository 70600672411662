import React from "react";
import styled from "styled-components";

// SurveyContext
import { SurveyContextProvider } from "./SurveyContext";

// components
import Form from "./components/Form";
import Sections from "./components/Sections/index";
import FormSubmitButton from "./components/FormSubmitButton";

const SurveyForm = () => {
    return (
        <SurveyContextProvider>
            <SurveyStyled className="SurveyForm inset">
                <Form>
                    <Sections />
                    <FormSubmitButton />
                </Form>
            </SurveyStyled>
        </SurveyContextProvider>
    );
};

export default SurveyForm;

const SurveyStyled = styled.div``;
