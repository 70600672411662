import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareHeadersAuth } from "./utils";

export const apiAuthSurveySlice = createApi({
    reducerPath: "apiAuthSurvey",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_EXPRESS_API}/api/auth`,
        prepareHeaders: prepareHeadersAuth,
    }),
    endpoints: (builder) => {
        return {
            getSurveyForm: builder.query({
                query: (surveyToken) => `/surveys/form/${surveyToken}`,
            }),
            createSurveyForm: builder.mutation({
                query: ({ surveyToken, body }) => {
                    return {
                        method: "post",
                        url: `/surveys/form/${surveyToken}`,
                        body: body,
                    };
                },
            }),
            getCsvExport: builder.query({
                query: (surveyId) => `/surveys/results/export/${surveyId}`,
            }),
            getSurveyResults: builder.query({
                query: ({ surveyId, surveySubmissionId }) =>
                    `/surveys/results/${surveyId}/${surveySubmissionId}`,
            }),
        };
    },
});

export const {
    useGetSurveyFormQuery,
    useCreateSurveyFormMutation,
    useGetCsvExportQuery,
    useGetSurveyResultsQuery,
} = apiAuthSurveySlice;
