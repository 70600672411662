import styled from "styled-components";
import PropTypes from "prop-types";

// Modal Context
import { ReqObjectWrapper, useModalContext } from "Common/BootStrap/Modal";

// components
import ControlWithLabel from "Common/BootStrap/FormControls/ControlWithLabel";
import ControlWithRemoveButton from "Common/BootStrap/FormControls/ControlWithRemoveButton";
import TextArea from "Common/BootStrap/FormControls/TextArea";
import Select from "Common/BootStrap/FormControls/Select";

const QuestionControl = ({ count, reqObject, survey }) => {
    const { domains } = survey;
    const { reqObjectUpdate } = useModalContext();
    const { id, question, surveyDomainId } = reqObject;

    const handleUpdate = (prop) => {
        reqObjectUpdate({
            ...reqObject,
            ...prop,
        });
    };

    const onRemove = () => {
        reqObjectUpdate({
            ...reqObject,
            restType: "destroy",
        });
    };

    const handleOnSelectChange = (e) => {
        const value = e.target.value;
        handleUpdate({ surveyDomainId: parseInt(value) });
    };

    return (
        <ReqObjectWrapper reqObject={reqObject}>
            <QuestionControlStyled>
                <ControlWithLabel
                    label={`Question ${count}`}
                    id={`${id}-question`}
                >
                    <ControlWithRemoveButton onRemove={onRemove}>
                        <div className="controls">
                            <div className="question">
                                <TextArea
                                    id={`${id}-question`}
                                    placeholder="Enter Question"
                                    value={question}
                                    onChange={(e) => {
                                        handleUpdate({
                                            question: e.target.value,
                                        });
                                    }}
                                    height="38px"
                                />
                            </div>
                            <div className="dropdown">
                                <Select
                                    id={`${id}-dropdown`}
                                    onChange={handleOnSelectChange}
                                    selectedOption={domains.find(
                                        (d) => d.id === surveyDomainId
                                    )}
                                    options={domains}
                                    valueKey="id"
                                    displayKey="domain"
                                />
                            </div>
                        </div>
                    </ControlWithRemoveButton>
                </ControlWithLabel>
            </QuestionControlStyled>
        </ReqObjectWrapper>
    );
};

export default QuestionControl;

// prop-types
QuestionControl.propTypes = {
    count: PropTypes.number.isRequired,
    reqObject: PropTypes.object.isRequired,
    survey: PropTypes.object.isRequired,
};

const QuestionControlStyled = styled.div`
    .controls {
        display: flex;

        > .question {
            flex: 1;
            margin-right: 10px;
        }
        > .dropdown {
            flex: 0 0 200px;
            margin-right: 10px;
        }
    }
`;
