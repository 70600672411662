import styled from "styled-components";
import PropTypes from "prop-types";

// components
import ImproveBy from "./ImproveBy";

const DetailedReport = ({ detailedReport }) => {
    const { title, introduction, workToImprove, youCanAlsoImprove } =
        detailedReport;

    return (
        <DetailedReportStyled>
            <h2>{title}</h2>
            <div
                className="introduction"
                dangerouslySetInnerHTML={{ __html: introduction }}
            />
            <ImproveBy category={workToImprove} />
            <ImproveBy category={youCanAlsoImprove} />
        </DetailedReportStyled>
    );
};

export default DetailedReport;

// prop-types
DetailedReport.propTypes = {
    detailedReport: PropTypes.object.isRequired,
};

const DetailedReportStyled = styled.div`
    max-width: 800px;
    margin: 50px auto;
`;
