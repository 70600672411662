import React from "react";
import styled from "styled-components";
import { Outlet, NavLink } from "react-router-dom";

/* Components ---------------------------*/
import Header from "../Common/Header.jsx";
import Main from "./Main.jsx";

const Layout = () => {
    return (
        <LayoutStyled className="Layout">
            <Header>
                <h1>Healthcheck and Examples</h1>
            </Header>
            <Main>
                <nav className="sublinks">
                    <NavLink to="/auth/health/">Health</NavLink>
                    <NavLink to="/auth/health/examples/">Examples</NavLink>
                </nav>
                <Outlet />
            </Main>
        </LayoutStyled>
    );
};

export default Layout;

const LayoutStyled = styled.div`
    .Header {
        margin: 0px;
    }
`;
