import React, { useContext } from 'react';
import styled from 'styled-components';

/* Context ---------------------------*/
import RankItemsContext from '../../RankItemsContext/index.js';
import { rankedItemsOrderUpdate } from '../../RankItemsContext/actions.js';

/* Components ---------------------------*/
import Item from './Item/Item.jsx';

const RankedItem = ({item, ranked, domOrder, dragItem, dragOverItem}) => {

    const { state: { ranked: rankedItems }, dispatch } = useContext(RankItemsContext);

    const onDragStart = (e) => {
        dragItem.current = domOrder;
    }

    const onDragEnter = (e) => {
        dragOverItem.current = domOrder;
    }

    const onDragEnd = (e) => {
        // Clone State Items
        const newRankedItems = [...rankedItems];

        // The Magic
        const dragItemContent = newRankedItems[dragItem.current];

        // Remove Item being dragged
        newRankedItems.splice(dragItem.current, 1);

        // Add item back in, at index of the item being dragged is hover over
        newRankedItems.splice(dragOverItem.current, 0, dragItemContent);

        // Reset Refs?
        dragItem.current = null;
        dragOverItem.current = null;

        // Update State Items
        dispatch(rankedItemsOrderUpdate(newRankedItems));
    }

    // SPEED: This is what speeds it up - for some reason this slows things down
    const onDragOver = (e) => {
        e.preventDefault()
    }

    return (
        <RankedItemStyled
            className="RankedItem"
            draggable
            onDragStart={onDragStart}
            onDragEnter={onDragEnter}
            onDragEnd={onDragEnd}
            onDragOver={ onDragOver }
            item={ item }
        >
            <Item item={ item } ranked={ ranked } domOrder={ domOrder } />
        </RankedItemStyled>
    );
};

export default RankedItem;

const RankedItemStyled = styled.li`
    outline: solid 1px #ccc;
    margin: 5px 0px;
    .Item {
        background-color: ${({item}) => item.rankOrderColor};
    }
`;
