export const colors = [
    "#77aaff",
    "#99ccff",
    "#bbeeff",
    "#5588ff",
    "#3366ff",
    "#65cfe0",
    "#31a8bb",
    "#b2eaf3",
    "#659fe0",
    "#9ae7f2",
    "#60c0d4",
];
