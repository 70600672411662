import { Auth0Provider as A0P } from '@auth0/auth0-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return <Component {...props} router={{ location, navigate, params }} />;
    }

    return ComponentWithRouterProp;
};

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const auth0CallBackUrl = process.env.REACT_APP_AUTH0_CALLBACK;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const scope = process.env.REACT_APP_AUTH0_SCOPE;

const Auth0Provider = ({ children, router }) => {
    const { navigate, location } = router;

    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo || location.pathname);
    };

    return (
        <A0P
            domain={auth0Domain}
            clientId={auth0ClientId}
            authorizationParams={{
                redirect_uri: auth0CallBackUrl,
                audience: audience,
                scope: scope
            }}
            useRefreshTokens={true}
            cacheLocation="localstorage"
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </A0P>
    );
};

export default withRouter(Auth0Provider);

// We are setting fetchToken here as a global variable
// This way Redux RTK can use it outside of useAuth0 hook
// Redux RTK leverages it to add as bearer token in API Authenticated routes.
// See src/Redux/utils.js
let fetchToken = null;

export const auth0Token = () => {

    const setFetchToken = (getAccessTokenSilently) => (fetchToken = getAccessTokenSilently);

    const hasTokenExpired = async (hasTokenUpdate, getAccessTokenSilently, logout) => {
        try {
            await getAccessTokenSilently();
            hasTokenUpdate(true);
        } catch (err) {
            logout({
                logoutParams: {
                    returnTo: process.env.REACT_APP_AUTH0_LOGOUT,
                    federated: true,
                }
            });
            console.log({"accessTokenHasExpired": err});
            hasTokenUpdate(false);
        }
    }

    return {
        fetchToken,
        setFetchToken,
        hasTokenExpired,
    }
}