import PropTypes from "prop-types";

// exposed components
import { AccordionWrapper } from "Common/BootStrap/Accordion.jsx";
export { AccordionItem } from "./AccordionItem/index";

export const Accordion = ({ children, defaultActiveKey = 0 }) => {
    return (
        <AccordionWrapper defaultActiveKey={defaultActiveKey}>
            {children}
        </AccordionWrapper>
    );
};

// prop-types
Accordion.propTypes = {
    children: PropTypes.any,
    defaultActiveKey: PropTypes.number,
};
