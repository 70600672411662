
import { auth0Token } from 'Auth0/Auth0Provider.jsx';

export const prepareHeadersAuth = async (headers) => {
    headers.set('Content-Type', 'application/json');
    try {
        const sec = auth0Token();
        const accessToken = await sec.fetchToken();
        headers.set('Authorization', `Bearer ${accessToken}`);
    } catch (err) {
        console.log({"prepareHeadersAuth fetchToken Error": err});
    }
    return headers;
}