import React from 'react';
import styled from 'styled-components';

/* Components ---------------------------*/
import Item from './Item/Item.jsx';

const UnrankedItem = ({ item, ranked }) => {
    return (
        <UnrankedItemStyled className='UnrankedItem'>
            <Item item={ item } ranked={ ranked } />
        </UnrankedItemStyled>
    );
}

export default UnrankedItem;

const UnrankedItemStyled = styled.li`
    outline: solid 1px #ccc;
    margin: 5px 0px;
    .Item {
        background-color: rgba(255, 255, 255, 0.3);
    }
`;