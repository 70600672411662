import styled from "styled-components";
import PropTypes from "prop-types";

// Modal Context
import { ReqObjectWrapper, useModalContext } from "Common/BootStrap/Modal";

// components
import ControlWithLabel from "Common/BootStrap/FormControls/ControlWithLabel";
import TextArea from "Common/BootStrap/FormControls/TextArea";

const OpenTextControl = ({ count, reqObject, survey }) => {
    const { reqObjectUpdate } = useModalContext();
    const {
        id,
        intro,
        yourStrength,
        aboutYourStrength,
        yourConfidence,
        whatIsImportantToYou,
        yourWeakness,
        aboutYourWeakness,
        finalThoughts,
    } = reqObject;

    const handleUpdate = (prop) => {
        reqObjectUpdate({
            ...reqObject,
            ...prop,
        });
    };

    return (
        <ReqObjectWrapper reqObject={reqObject}>
            <OpenTextControlStyled>
                <ControlWithLabel label={`Intro`} id={`${id}-intro`}>
                    <TextArea
                        id={`${id}-intro`}
                        placeholder="Enter Opening Text"
                        value={intro}
                        onChange={(e) => {
                            handleUpdate({
                                intro: e.target.value,
                            });
                        }}
                        height="150px"
                    />
                </ControlWithLabel>
                <ControlWithLabel
                    label={`Your Strength`}
                    id={`${id}-yourStrength`}
                >
                    <TextArea
                        id={`${id}-yourStrength`}
                        placeholder="Enter Opening Text"
                        value={yourStrength}
                        onChange={(e) => {
                            handleUpdate({
                                yourStrength: e.target.value,
                            });
                        }}
                        height="150px"
                    />
                </ControlWithLabel>
                <ControlWithLabel
                    label={`About Your Strength`}
                    id={`${id}-aboutYourStrength`}
                >
                    <TextArea
                        id={`${id}-aboutYourStrength`}
                        placeholder="Enter Opening Text"
                        value={aboutYourStrength}
                        onChange={(e) => {
                            handleUpdate({
                                aboutYourStrength: e.target.value,
                            });
                        }}
                        height="150px"
                    />
                </ControlWithLabel>
                <ControlWithLabel
                    label={`Your Confidence`}
                    id={`${id}-yourConfidence`}
                >
                    <TextArea
                        id={`${id}-yourConfidence`}
                        placeholder="Enter Opening Text"
                        value={yourConfidence}
                        onChange={(e) => {
                            handleUpdate({
                                yourConfidence: e.target.value,
                            });
                        }}
                        height="150px"
                    />
                </ControlWithLabel>
                <ControlWithLabel
                    label={`What's Important To You`}
                    id={`${id}-whatIsImportantToYou`}
                >
                    <TextArea
                        id={`${id}-whatIsImportantToYou`}
                        placeholder="Enter Opening Text"
                        value={whatIsImportantToYou}
                        onChange={(e) => {
                            handleUpdate({
                                whatIsImportantToYou: e.target.value,
                            });
                        }}
                        height="150px"
                    />
                </ControlWithLabel>
                <ControlWithLabel
                    label={`Your Weakness`}
                    id={`${id}-yourWeakness`}
                >
                    <TextArea
                        id={`${id}-yourWeakness`}
                        placeholder="Enter Opening Text"
                        value={yourWeakness}
                        onChange={(e) => {
                            handleUpdate({
                                yourWeakness: e.target.value,
                            });
                        }}
                        height="150px"
                    />
                </ControlWithLabel>
                <ControlWithLabel
                    label={`About Your Weakness`}
                    id={`${id}-aboutYourWeakness`}
                >
                    <TextArea
                        id={`${id}-aboutYourWeakness`}
                        placeholder="Enter Opening Text"
                        value={aboutYourWeakness}
                        onChange={(e) => {
                            handleUpdate({
                                aboutYourWeakness: e.target.value,
                            });
                        }}
                        height="150px"
                    />
                </ControlWithLabel>
                <ControlWithLabel
                    label={`Final Thoughts`}
                    id={`${id}-finalThoughts`}
                >
                    <TextArea
                        id={`${id}-finalThoughts`}
                        placeholder="Enter Opening Text"
                        value={finalThoughts}
                        onChange={(e) => {
                            handleUpdate({
                                finalThoughts: e.target.value,
                            });
                        }}
                        height="150px"
                    />
                </ControlWithLabel>
            </OpenTextControlStyled>
        </ReqObjectWrapper>
    );
};

export default OpenTextControl;

// prop-types
OpenTextControl.propTypes = {
    count: PropTypes.number.isRequired,
    reqObject: PropTypes.object.isRequired,
    survey: PropTypes.object.isRequired,
};

const OpenTextControlStyled = styled.div``;
