import styled from "styled-components";
import PropTypes from "prop-types";

/* ClientDetailsContext ---------------------------*/
import { useClientDetailsContext } from "Pages/Auth/ControlPanel/ClientDetails/ClientDetailsContext/index.js";

// components
import SaveButton from "./SaveButton";
import FormStatusOverlay from "./FormStatusOverlay";

const UniversalForm = ({ children, saveButtonText = "Save" }) => {
    const { onSave } = useClientDetailsContext();

    return (
        <UniversalFormStyled onSubmit={onSave}>
            {children}
            <footer className="footer">
                <SaveButton saveButtonText={saveButtonText} />
            </footer>
            <FormStatusOverlay />
        </UniversalFormStyled>
    );
};

export default UniversalForm;

// prop-types
UniversalForm.propTypes = {
    children: PropTypes.any,
    saveButtonText: PropTypes.string,
};

const UniversalFormStyled = styled.form`
    position: relative;

    footer {
        text-align: right;
    }
`;
