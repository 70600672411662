import styled from "styled-components";

const Error404 = () => {
    return (
        <Error404Styled>
            <div className="inset">
                <h1>404: Page Not Found</h1>
                <p>
                    Whoops! We were unable to locate the page you were looking
                    for.
                </p>
            </div>
        </Error404Styled>
    );
};

export default Error404;

const Error404Styled = styled.div`
    .inset {
        max-width: 600px;
        padding: 20px;
        margin: 100px auto 0px;
    }
`;
