import React, { useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

/* Context ---------------------------*/
import RankItemsContext from '../../../RankItemsContext/index.js';
import {
    addRankedItem,
    removeRankedItem,
} from '../../../RankItemsContext/actions.js';

const AddRemove = ({ item, ranked }) => {
    const {
        state: {
            conditions: { count },
        },
        dispatch,
    } = useContext(RankItemsContext);

    const buttonText = ranked ? 'Remove' : 'Add';
    const icon = ranked ? faMinusCircle : faPlusCircle;
    const addRemoveItem = ranked ? removeRankedItem : addRankedItem;

    const { minThresholdMet, min, max } = count;

    const disabled = (!ranked && minThresholdMet && max.isMax) || (ranked && minThresholdMet && min.isMin);

    const onClick = () => {
        dispatch(addRemoveItem(item));
    };

    const theClassName = classNames({
        AddRemove: true,
        ranked: ranked,
        ['unranked']: !ranked,
    });

    return (
        <AddRemoveStyled
            className={theClassName}
            onClick={onClick}
            disabled={disabled}
        >
            <FontAwesomeIcon icon={icon} />
            <span className="sr-only">{buttonText}</span>
        </AddRemoveStyled>
    );
};

export default AddRemove;

const AddRemoveStyled = styled.button`
    padding: 7px;

    background-color: transparent;
    border: none;
    outline: none;
    color: #087908;

    display: block;

    width: 100%;
    height: 100%;

    &.ranked {
        color: #c43d3d;
    }

    &:hover {
        background-color: #f5f5f5;
    }
    &:active {
        background-color: #eee;
    }
    &:disabled {
        color: #666;
        cursor: not-allowed;
    }
`;
