import { format } from "date-fns";

export const commaSep = (arr) => {
    arr = arr.filter((item) => item);
    return [arr.slice(0, -1).join(", "), arr.slice(-1)[0]].join(
        arr.length < 2 ? "" : " & "
    );
};

export const getUserName = (userProfile) => {
    const { name, lastName, firstName } = userProfile;
    if (name) {
        return userProfile.name;
    } else if (firstName || lastName) {
        return `${firstName} ${lastName}`;
    }

    return "No Profile Name";
};

export const getHumanDate = (dateString) => {
    const dateObj = new Date(dateString);
    return {
        dayOfWeek: `${format(dateObj, "EEEE")}`,
        month: `${format(dateObj, "MMM")}`,
        day: `${format(dateObj, "do")}`,
        year: `${format(dateObj, "Y")}`,
        time: `${format(dateObj, "h:mmaaa")}`,
        date: {
            full: `${format(dateObj, "EEEE, MMM do, Y")}`,
            mid: `${format(dateObj, "MMM do, Y")}`,
            slim: `${format(dateObj, "EEEE, MMM do")}`,
        },
    };
};
