import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGripLines,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

const DragHandle = ({ranked}) => {

    const theClassName = classNames({
        'DragHandle': true,
        'ranked': ranked,
        ['unranked']: !ranked,
    });

    return (
        <DragHandleStyled className={ theClassName }>
            <FontAwesomeIcon icon={ faGripLines } />
        </DragHandleStyled>
    );
}

export default DragHandle;

const DragHandleStyled = styled.div`
    font-size: 20px;
    color: #333;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%; height: 100%;

    cursor: pointer;

    &.unranked {
        cursor: not-allowed;
    }
`;