import styled from "styled-components";
import PropTypes from "prop-types";

/* ClientDetailsContext ---------------------------*/
import { useClientDetailsContext } from "Pages/Auth/ControlPanel/ClientDetails/ClientDetailsContext/index.js";

// components
import ViewSurveyResults from "Pages/Auth/Common/ViewSurveyResults.jsx";
import TakeSurvey from "Pages/Auth/Common/TakeSurvey.jsx";
import ExportCSVButton from "Pages/Auth/Common/ExportCSVButton";

const SurveyActions = ({ survey }) => {
    const {
        config: { clientProfile, roleId },
    } = useClientDetailsContext();
    return (
        <SurveyActionsStyled>
            <TakeSurvey surveyToken={survey.token} />
            <ViewSurveyResults
                surveyId={survey.id}
                roleId={roleId}
                clientId={clientProfile.id}
            />
            <ExportCSVButton survey={survey} />
        </SurveyActionsStyled>
    );
};

export default SurveyActions;

// prop-types
SurveyActions.propTypes = {
    survey: PropTypes.object.isRequired,
};

const SurveyActionsStyled = styled.div`
    a {
        margin: 0px 5px;
    }
`;
