import styled from "styled-components";
import PropTypes from "prop-types";

// context
import { useSurvey } from "Pages/Auth/Survey/SurveyForm/SurveyContext";

// components
import { AccordionItem } from "./Accordion/index.jsx";
import QuestionList from "./Questions/QuestionList";
import Ranking from "./Ranking";
import OpeningText from "./OpeningText";

const Section = ({ section }) => {
    const state = useSurvey();
    const { Utils } = state;
    const {
        id,
        sKey,
        number,
        title,
        openingText,
        questions,
        isDisabled,
        statusMessage,
    } = Utils.getDecoratedSection(section, state);

    return (
        <AccordionItem
            title={title}
            eventKey={number}
            id={id}
            disabled={isDisabled}
            accordionStatusMessage={statusMessage}
        >
            <SectionStyled>
                <OpeningText openingText={openingText} />
                <Ranking section={section} />
                <QuestionList
                    sNumber={number}
                    sKey={sKey}
                    questions={questions}
                />
            </SectionStyled>
        </AccordionItem>
    );
};

export default Section;

// prop-types
Section.propTypes = {
    section: PropTypes.object.isRequired,
};

const SectionStyled = styled.div``;
