import styled from "styled-components";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlasses } from "@fortawesome/free-solid-svg-icons";

/* Styles ---------------------------*/
import { buttonLinkStyles } from "./shared";

const ViewSurveyResults = ({ surveyId, roleId, clientId }) => {
    const href =
        roleId === 1
            ? `/auth/cp/admin/client/${clientId}/survey-submissions/${surveyId}`
            : `/auth/cp/client/survey-submissions/${surveyId}`;

    return (
        <ViewSurveyResultsStyled
            className="btn btn-info"
            title="View Survey Results List"
            href={href}
        >
            <FontAwesomeIcon icon={faGlasses} />
        </ViewSurveyResultsStyled>
    );
};

export default ViewSurveyResults;

// prop-types
ViewSurveyResults.propTypes = {
    surveyId: PropTypes.number.isRequired,
    roleId: PropTypes.number.isRequired,
    clientId: PropTypes.number.isRequired,
};

const ViewSurveyResultsStyled = styled.a`
    ${buttonLinkStyles}
`;
