import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

/* Redux ---------------------------*/
import { useCreateSurveyFormMutation } from "Redux/apiAuthSurveySlice.js";

// context
import { useSurvey } from "Pages/Auth/Survey/SurveyForm/SurveyContext";

const Form = ({ children }) => {
    const [postSurveyForm] = useCreateSurveyFormMutation();

    const navigate = useNavigate();
    const {
        answers,
        canSubmitForm,
        isSubmitting,
        surveyToken,
        isSubmittingUpdate,
    } = useSurvey();

    const onSubmit = async (e) => {
        if (canSubmitForm && !isSubmitting) {
            isSubmittingUpdate(true);

            e.preventDefault();

            const body = { answers };

            const { data: survSub } = await postSurveyForm({
                surveyToken,
                body,
            });

            if (survSub) {
                const { resultsUrl } = survSub;

                if (resultsUrl) {
                    // /auth/survey/results/:surveyId/:surveySubmissionId
                    navigate(resultsUrl);
                }
            } else {
                isSubmittingUpdate(false);

                // TODO: Create a more UX friendly error messaging system
                alert(
                    "There was a problem with submitting your form. Please check the form and try again. Should the problem persist, please contact the authors of this Survey."
                );
            }
        }
    };

    return (
        <form className="Form" onSubmit={onSubmit}>
            {children}
        </form>
    );
};

export default Form;

// prop-types
Form.propTypes = {
    children: PropTypes.any,
};
