/*---------------------------
| Action Types
---------------------------*/
const prefix = "Survey:";

export const actionTypes = {
    PRELOAD_STATE_UPDATE: `${prefix} preloadStateUpdate`,
    ANSWER_UPDATE: `${prefix} answerUpdate`,
    IS_SUBMITTING_UPDATE: `${prefix} isSubmittingUpdate`,
};
