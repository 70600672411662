import React, { useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

/* Context ---------------------------*/
import RankItemsContext from "../../../RankItemsContext/index.js";
import {
    moveRankedItemUp,
    moveRankedItemDown,
} from "../../../RankItemsContext/actions.js";

const UpDown = ({ item, domOrder }) => {
    const {
        state: { ranked: rankedItems },
        dispatch,
    } = useContext(RankItemsContext);

    const moveUpDisabled = domOrder === 0;
    const moveDownDisabled = domOrder === rankedItems.length - 1;

    const moveUp = () => {
        if (!moveUpDisabled) {
            dispatch(moveRankedItemUp(item, domOrder));
        }
    };

    const moveDown = () => {
        if (!moveDownDisabled) {
            dispatch(moveRankedItemDown(item, domOrder));
        }
    };

    return (
        <UpDownStyled className="UpDown">
            <button
                type="button"
                className="faArrowUp"
                disabled={moveUpDisabled}
                onClick={moveUp}
            >
                <FontAwesomeIcon icon={faArrowUp} />
                <span className="sr-only">Move Up</span>
            </button>
            <button
                type="button"
                className="faArrowDown"
                disabled={moveDownDisabled}
                onClick={moveDown}
            >
                <FontAwesomeIcon icon={faArrowDown} />
                <span className="sr-only">Move Down</span>
            </button>
        </UpDownStyled>
    );
};

export default UpDown;

const UpDownStyled = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;

    button {
        background-color: transparent;
        border: none;
        outline: none;
        display: block;
        height: 100%;
        width: 35px;

        &:hover {
            background-color: #f5f5f5;
        }
        &:active {
            background-color: #eee;
        }

        &:disabled {
            cursor: not-allowed;
        }
    }
`;
