import React from "react";
import { Routes, Route } from "react-router-dom";

/*---------------------------
| Layouts
---------------------------*/
import LayoutPublic from "./Layout/Public/Layout.jsx";
import LayoutBare from "./Layout/Bare/Layout.jsx";
import LayoutHealthCheck from "./Layout/HealthCheck/Layout.jsx";
import LayoutHealthCheckExamples from "./Layout/HealthCheck/Examples.jsx";
import LayoutAuthenticated from "./Layout/Authenticated/Layout.jsx";

/*---------------------------
| Error Pages
---------------------------*/
import Error404 from "./Error404.jsx";

/*---------------------------
| Public
---------------------------*/
import AllRoutes from "./Layout/AllRoutes.jsx";

/* Pages :: Public ---------------------------*/
import Login from "./Pages/Login";
import LoggedOut from "./Pages/LoggedOut";

/*---------------------------
| Authenticated
---------------------------*/
import AuthenticatedRoutes from "Auth0/AuthenticatedRoutes"; // middleware that ensure user cannot access any of these subroutes

/* Pages/Auth :: Healthcheck and Examples ---------------------------*/
import HealthCheck from "./Pages/Auth/HealthCheck/index.jsx";
// examples
import ExampleResultsDiagram from "./Pages/Auth/HealthCheck/Examples/ResultsDiagram/index.jsx";
import ExampleResultsDiagramForIframe from "./Pages/Auth/HealthCheck/Examples/ResultsDiagram/ResultsDiagramForIframe.jsx";
import ExampleInlineDiagram from "./Pages/Auth/HealthCheck/Examples/InlineDiagram.jsx";
import ExampleRankItems from "./Pages/Auth/HealthCheck/Examples/RankItems/index.jsx";

/* PagesAuth :: Survey ---------------------------*/
import SurveyForm from "./Pages/Auth/Survey/SurveyForm";
import SurveyResults from "./Pages/Auth/Survey/SurveyResults/index.jsx";
import ResultsDiagram from "./Pages/Auth/Survey/SurveyResults/ResultsDiagramWrapper/ResultsDiagram.jsx";

/* PagesAuth :: Legacy ---------------------------*/
// TODO: Legacy: Remove once we have ironed out the kinks
import LegacyResults from "./Pages/Auth/Legacy/Results/index.jsx";
import LegacyResultsInline from "./Pages/Auth/Legacy/Results/ResultsInline.jsx";
import LegacyResultsDiagram from "./Pages/Auth/Legacy/Results/ResultsDiagram.jsx";

/* PagesAuth :: ControlPanel ---------------------------*/
import ControlPanel from "./Pages/Auth/ControlPanel/index.jsx";
import ClientDetails from "./Pages/Auth/ControlPanel/ClientDetails/index.jsx";
import ClientSurveySubmissions from "./Pages/Auth/ControlPanel/ClientDetails/ClientSurveySubmissions";
import ClientList from "./Pages/Auth/ControlPanel/ClientList";
import AdminList from "./Pages/Auth/ControlPanel/AdminList";
import AddUser from "./Pages/Auth/ControlPanel/AddUser";
import SurveyUserLanding from "./Pages/Auth/ControlPanel/SurveyUserLanding";

/* Global Styles ---------------------------*/
import "./Common/styles/normalize.css";
import "./Common/styles/global.scss";

const UI = () => {
    return (
        <Routes>
            <Route element={<Error404 />} path="*" />
            <Route element={<AllRoutes />}>
                {/* Public Routes :: No Main Authentication Logic */}
                <Route element={<LayoutPublic />}>
                    <Route element={<Login />} path="/" />
                    <Route element={<LoggedOut />} path="/loggedout" />
                </Route>

                {/* Authenticated Routes :: Primary Layout */}
                <Route element={<AuthenticatedRoutes />} path="auth">
                    <Route element={<Error404 />} path="" />
                    {/* Bare Authenticated Layout */}
                    <Route element={<LayoutBare />}>
                        <Route element={<ExampleResultsDiagramForIframe />} path="results-diagram-iframe" />
                    </Route>

                    {/* OG Legacy Results  */}
                    <Route element={<LegacyResultsInline />} path="bare/survey/results-inline/:confPercent/:impPercent" />
                    <Route element={<LegacyResultsDiagram />} path="bare/survey/results-diagram-legacy/:reportType/:clientId/:createdBy/:surveySubmissionId/:surveyUserId/:surveyId" />

                    {/* NEW ResultsDiagram */}
                    <Route element={<ResultsDiagram />} path="bare/survey/results-diagram/:surveyId/:surveySubmissionId/:reportType" />

                    {/* Healthcheck and Examples  */}
                    <Route element={<LayoutHealthCheck />}  path="health">
                        <Route element={<HealthCheck />} path="" />
                        <Route element={<LayoutHealthCheckExamples />} path="examples">
                            <Route element={<ExampleResultsDiagram />} path="" />
                            <Route element={<ExampleInlineDiagram />} path="inline" />
                            <Route element={<ExampleRankItems />} path="rank" />
                        </Route>
                    </Route>

                    {/* Authenticated Layout :: With header, footer, etc */}
                    <Route element={<LayoutAuthenticated />}>
                        {/* Survey */}
                        <Route element={<SurveyForm />} path="survey/:surveyToken" />

                        {/* OG http://localhost:3050/auth/survey/92/219/32/42/42/result */}
                        <Route element={<LegacyResults />} path="results-legacy/:surveyId/:surveySubmissionId/:clientId/:createdBy/:userId" />

                        {/* NEW */}
                        <Route element={<SurveyResults />} path="results/:surveyId/:surveySubmissionId" />

                        {/* Control Panel */}
                        <Route path="cp">
                            <Route element={<ControlPanel />} path="" />
                            <Route element={<SurveyUserLanding />} path="survey-user" />
                            <Route path="client">
                                <Route element={<ClientDetails />} path="" />
                                <Route element={<ClientSurveySubmissions />} path="survey-submissions/:surveyId?" />
                            </Route>
                            <Route path="admin">
                                <Route element={<ClientList />} path="" />
                                <Route element={<AdminList />} path="admin-users" />
                                <Route element={<AddUser />} path="add-user" />
                                <Route path="client/:clientId">
                                    <Route element={<ClientDetails />} path="" />
                                    <Route element={<ClientSurveySubmissions />} path="survey-submissions/:surveyId?" />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
};

export default UI;
