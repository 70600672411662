import PropTypes from "prop-types";

// components
import {
    AccordionItem,
    AccordionHeader,
    AccordionBody,
} from "Common/BootStrap/Accordion.jsx";
import Header from "./Header";
import BodyOpener from "./BodyOpener";
import SurveySubmissionList from "./SurveySubmissionList";

const Survey = ({ survey, roleId, clientIsRestricted, eventKey }) => {
    return (
        <AccordionItem eventKey={eventKey}>
            <AccordionHeader>
                <Header survey={survey} roleId={roleId} />
            </AccordionHeader>
            <AccordionBody>
                <BodyOpener survey={survey} roleId={roleId} />
                <SurveySubmissionList
                    survey={survey}
                    roleId={roleId}
                    clientIsRestricted={clientIsRestricted}
                />
            </AccordionBody>
        </AccordionItem>
    );
};

export default Survey;

// prop-types
Survey.propTypes = {
    survey: PropTypes.object.isRequired,
    roleId: PropTypes.number.isRequired,
    clientIsRestricted: PropTypes.bool,
    eventKey: PropTypes.number.isRequired,
};
