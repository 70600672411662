import styled from "styled-components";
import PropTypes from "prop-types";

import Controls from "./Controls";

const Question = ({ number, decQuestion }) => {
    const { id, question } = decQuestion;

    return (
        <QuestionStyled>
            <div className="number">{number}.</div>
            <div className="right">
                <div className="question">
                    <label htmlFor={id}>{question}</label>
                </div>
                <div className="control">
                    <Controls {...{ decQuestion }} />
                </div>
            </div>
        </QuestionStyled>
    );
};

export default Question;

// prop-types
Question.propTypes = {
    number: PropTypes.number.isRequired,
    decQuestion: PropTypes.object.isRequired,
};

const QuestionStyled = styled.div`
    display: grid;
    grid-template-columns: 35px auto;
    margin: 0px 0px 20px;

    .number {
        text-align: right;
        padding-right: 10px;
    }
    .control {
        margin-top: 10px;
    }
`;
