/*===================================
||
|| ClientDetailsContext
||
===================================*/
import React, {
    createContext,
    useReducer,
    useMemo,
    useEffect,
    useContext,
} from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

/* Redux RTK ---------------------------*/
import {
    useGetClientQuery,
    useGetSurveysQuery,
} from "Redux/apiAuthClientSlice.js";

/* Hooks ---------------------------*/
import { useAuth } from "Auth0/useAuth.js";

// store
import { reducer, actions, getDefaultState } from "./store";
import { onSave } from "./onSave";

/*---------------------------
| Context
---------------------------*/
const ClientDetailsContext = createContext();

// Provider
export const ClientDetailsContextProvider = ({ children }) => {
    const { userProfile } = useAuth();
    const { clientId, surveyId } = useParams();
    const { client } = userProfile;

    const theClientId = clientId || client.id;

    const { data: clientProfile } = useGetClientQuery(theClientId);
    const { data: surveys } = useGetSurveysQuery(theClientId);

    // Not managed by State
    const config = {
        ...userProfile,
        clientId: theClientId,
        clientProfile,
        surveys,
        selectedSurveyId: parseInt(surveyId),
        isLoading:
            !clientProfile || !surveys ? "Waiting on client details" : "",
    };

    // State Managed
    const defaultState = {
        formData: [],
        formStatus: "listening",
        error: "",
    };

    const [state, dispatch] = useReducer(
        reducer,
        getDefaultState(defaultState)
    );

    // useMemo so it does not pass value on every render
    const value = useMemo(
        () => ({ state, dispatch, config }),
        [state, dispatch, config]
    );

    return (
        <ClientDetailsContext.Provider
            value={value}
            displayName={"ClientDetailsContext"}
        >
            <MountingWrapper>{children}</MountingWrapper>
        </ClientDetailsContext.Provider>
    );
};

// prop-types
ClientDetailsContextProvider.propTypes = {
    children: PropTypes.any,
};

// MountingWrapper
const MountingWrapper = ({ children }) => {
    // const { state, dispatch } = useContext(ClientDetailsContext);

    // useEffect(() => {
    //     console.log("Do something on mount, like api calls");
    // }, []);

    return <>{children}</>;
};

// prop-types
MountingWrapper.propTypes = {
    children: PropTypes.any,
};

// useInput hook
const useInput = ({ id, value }) => {
    const { state, dispatch } = useContext(ClientDetailsContext);

    useEffect(() => {
        dispatch(actions.updateFormInput({ id, value }));
    }, [value]);

    const stateInput = state.formData.find((i) => i.id === id);
    const currValue = stateInput?.value || value;

    return {
        onChange: (e) => {
            dispatch(actions.updateFormInput({ id, value: e.target.value }));
        },
        value: currValue,
    };
};
// useInput hook
const useCheckbox = ({ id, isChecked }) => {
    const { state, dispatch } = useContext(ClientDetailsContext);

    useEffect(() => {
        dispatch(actions.updateFormInput({ id, isChecked }));
    }, [isChecked]);

    const stateInput = state.formData.find((i) => i.id === id);

    let currIsChecked = isChecked;

    if (stateInput) {
        currIsChecked = stateInput.isChecked;
    }

    return {
        onChange: () => {
            dispatch(
                actions.updateFormInput({ id, isChecked: !currIsChecked })
            );
        },
        isChecked: currIsChecked,
    };
};
// useFile hook
const useFileImage = ({ id, base64Original }) => {
    const { state, dispatch } = useContext(ClientDetailsContext);

    let imageInputObj = {
        id,
        base64Original,
        fileFormData: null,
        browserSrc: null,
    };

    useEffect(() => {
        dispatch(
            actions.updateFormInput({
                ...imageInputObj,
                base64Original,
            })
        );
    }, [base64Original]);

    const stateInput = state.formData.find((i) => i.id === id);
    const newImageInputObject = stateInput || imageInputObj;

    return {
        onChange: () => {
            const fileInput = document.getElementById(id);
            if (fileInput?.files?.length) {
                imageInputObj.fileFormData = fileInput.files[0];
            }

            // FileReader support
            if (FileReader && imageInputObj.fileFormData) {
                let fr = new FileReader();
                fr.onload = () => {
                    imageInputObj.browserSrc = fr.result;
                    dispatch(actions.updateFormInput(imageInputObj));
                };
                fr.readAsDataURL(imageInputObj.fileFormData);
            } else {
                dispatch(actions.updateFormInput(imageInputObj));
            }
        },
        imageInputObj: newImageInputObject,
    };
};

// useClientDetailsContext
export const useClientDetailsContext = () => {
    const { state, dispatch, config } = useContext(ClientDetailsContext);

    return {
        ...state,
        config,
        dispatch,
        actions,
        onSave: onSave(state, config, dispatch, actions),
        useInput,
        useCheckbox,
        useFileImage,
    };
};
