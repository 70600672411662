import styled from "styled-components";

// components
import AddSurvey from "./AddSurvey/index";

const Header = () => {
    return (
        <HeaderStyled>
            <div className="title">Surveys</div>
            <AddSurvey />
        </HeaderStyled>
    );
};

export default Header;

const HeaderStyled = styled.div`
    display: flex;
    align-items: center;
    height: 40px;

    .title {
        flex: 1;
        font-size: 25px;
    }
    .AddSurvey {
        flex: 0 0 40px;
    }
`;
