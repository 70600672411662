import React, {useContext} from 'react';
import styled from 'styled-components';

/* Components ---------------------------*/
import DomainWrapper from './DomainWrapper/DomainWrapper.jsx';
import CenterStrengthWeakness from './CenterStrengthWeakness.jsx';
import ResultsContext from '../ResultsContext/index.js';

const Results = () => {
    const { state: { domains } } = useContext(ResultsContext);

    return (
        <ResultsStyled className='Results'>
            <div className="results-row top">
                <div className="results-col"><DomainWrapper domainId={ domains[0] ? domains[0].id : undefined } /></div>
                <div className="results-col"><DomainWrapper domainId={ domains[1] ? domains[1].id : undefined } /></div>
                <div className="results-col"><DomainWrapper domainId={ domains[2] ? domains[2].id : undefined } /></div>
            </div>
            <div className="results-row middle">
                <div className="results-col"><DomainWrapper domainId={ domains[3] ? domains[3].id : undefined } /></div>
                <div className="results-col center"><CenterStrengthWeakness /></div>
                <div className="results-col"><DomainWrapper domainId={ domains[4] ? domains[4].id : undefined } /></div>
            </div>
            <div className="results-row bottom">
                <div className="results-col"><DomainWrapper domainId={ domains[5] ? domains[5].id : undefined } /></div>
                <div className="results-col"><DomainWrapper domainId={ domains[6] ? domains[6].id : undefined } /></div>
                <div className="results-col"><DomainWrapper domainId={ domains[7] ? domains[7].id : undefined } /></div>
            </div>
        </ResultsStyled>
    );
}

export default Results;

const ResultsStyled = styled.div`
    position: relative;
    z-index: 2;

    .results-row {
        display: flex;
        justify-content: center;

        .results-col {
            /* border: solid 1px red; */
            width: 33%;
        }
    }
`;