import styled from "styled-components";
import PropTypes from "prop-types";

// components
import Deets from "./Deets";
import BodyOpenerActions from "./BodyOpenerActions";

const BodyOpener = ({ survey, roleId }) => {
    if (roleId === 3) return null;

    return (
        <BodyOpenerStyled>
            <Deets survey={survey} />
            <BodyOpenerActions survey={survey} roleId={roleId} />
        </BodyOpenerStyled>
    );
};

export default BodyOpener;

// prop-types
BodyOpener.propTypes = {
    survey: PropTypes.object.isRequired,
    roleId: PropTypes.number.isRequired,
};

const BodyOpenerStyled = styled.div`
    display: grid;
    grid-template-columns: auto 135px;
    grid-gap: 20px;
    align-items: center;

    background-color: #fffcd1;
    padding: 20px;
    border-bottom: solid 1px #eee;
`;
