import { useEffect, useState } from "react";

/* Auth0 ---------------------------*/
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { auth0Token } from "Auth0/Auth0Provider.jsx";

/* Components ---------------------------*/
import Preloader from "Common/Preloader.jsx";
import AuthenticatedLayout from "./AuthenticatedLayout.jsx";

const AuthenticatedRoutes = () => {
    const [hasToken, hasTokenUpdate] = useState(false);
    const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

    useEffect(() => {
        const sec = auth0Token();
        sec.setFetchToken(getAccessTokenSilently);
        sec.hasTokenExpired(hasTokenUpdate, getAccessTokenSilently, logout);
    }, []);

    return !hasToken || !isAuthenticated ? (
        <Preloader />
    ) : (
        <AuthenticatedLayout />
    );
};

export default withAuthenticationRequired(AuthenticatedRoutes);
