import { Outlet } from "react-router-dom";

/* Hooks ---------------------------*/
import { useAuth } from "Auth0/useAuth.js";

const AuthenticatedLayout = () => {
    const { isLoading, Preloader } = useAuth();

    if (isLoading) {
        return <Preloader />;
    }

    return <Outlet />;
};

export default AuthenticatedLayout;
