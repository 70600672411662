/*
    Animate SVG stroke-dasharray to reveal percentage circle stroke
    Great Example of how this works: https://codepen.io/sergiopedercini/pen/jmKdbj
*/
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import classnames from "classnames";
import { useInViewport } from "react-in-viewport";

import { ReactComponent as CircleOfDestinySvg } from "./circle-of-destiny-svg.svg";

const CircleOfDestiny = ({
    reveal = false,
    fill = "none",
    width = 2.8,
    color = "#1f7d67",
    speed = "1.5s",
    percentage = 30,
    listener = "inViweport", // inViweport, Manual (Parent compoonent handles state)
}) => {
    // State of reveal or hide
    const [revealCircle, revealCircleUpdate] = useState(false);

    // InViewport - if being used
    const myRef = useRef();
    const { inViewport } = useInViewport(myRef);
    useEffect(() => {
        if (listener === "inViweport") {
            // adding slight delay
            setTimeout(() => revealCircleUpdate(inViewport), 500);
        }
    }, [inViewport]);

    // Listen for parent state changes
    useEffect(() => {
        revealCircleUpdate(reveal);
    }, [reveal]);

    const theClassName = classnames({
        CircleOfDestiny: true,
        reveal: revealCircle,
    });

    return (
        <CircleOfDestinyStyled
            className={theClassName}
            fill={fill}
            width={width}
            color={color}
            speed={speed}
            percentage={percentage}
            ref={myRef}
        >
            <CircleOfDestinySvg />
        </CircleOfDestinyStyled>
    );
};

export default CircleOfDestiny;

const CircleOfDestinyStyled = styled.div`
    .circle-of-destiny-svg {
        /* outline: solid 2px red; */
    }

    .circle-of-destiny-svg .circle {
        fill: ${({ fill }) => fill};

        stroke-width: ${({ width }) => width};
        stroke: ${({ color }) => color};
        /*   stroke-linecap: round; */
        stroke-dasharray: 0 100;
    }

    &.reveal {
        .circle-of-destiny-svg .circle {
            animation: progress ${({ speed }) => speed} ease-out forwards;
            stroke-dasharray: ${({ percentage }) => percentage} 100;
        }
    }

    @media print {
        .circle-of-destiny-svg .circle {
            stroke-dasharray: ${({ percentage }) => percentage} 100;
        }
    }

    @keyframes progress {
        0% {
            stroke-dasharray: 0 100;
        }
    }
`;
