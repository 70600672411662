import styled from "styled-components";

// components
import { Accordion } from "./Accordion/index.jsx";
import Intro from "./Intro";
import SectionList from "./SectionList";

const Sections = () => {
    return (
        <SectionsStyled>
            <Accordion defaultActiveKey={0}>
                <Intro />
                <SectionList />
            </Accordion>
        </SectionsStyled>
    );
};

export default Sections;

const SectionsStyled = styled.div`
    .accordion-body {
        padding: 0px;
    }
`;
