import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const Message = ({message}) => {

    const theClassName = classNames({
        'Message': true,
        [message.type]: true,
    });

    return (
        <MessageStyled className={ theClassName }>
            <span dangerouslySetInnerHTML={ { __html: message.label } } />{ ' ' }
            <a href={ message.link } target="_blank">{ message.link }</a>
        </MessageStyled>
    );
}

export default Message;

const MessageStyled = styled.li`
    margin-bottom: 5px;
`;