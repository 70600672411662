import React, { useRef } from 'react';
import styled from 'styled-components';

/* Components ---------------------------*/
import RankedItem from './RankedItem.jsx';
import UnrankedItem from './UnrankedItem.jsx';

const ItemsList = ({items, ranked=false}) => {

    const dragItem = useRef();
    const dragOverItem = useRef();

    const Item = (ranked) ? RankedItem : UnrankedItem;

    return (
        <ItemsListStyled className='ItemsList'>
            {items.map((item, idx) => {
                return (
                    <Item
                        key={item.id}
                        item={item}
                        ranked={ranked}
                        domOrder={idx}
                        dragItem={dragItem}
                        dragOverItem={dragOverItem}
                    />
                );
            })}
        </ItemsListStyled>
    );
}

export default ItemsList;

const ItemsListStyled = styled.ol`
    padding: 5px;
    margin: 0px;
    list-style-type: none;
`;