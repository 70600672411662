import styled from "styled-components";
import PropTypes from "prop-types";

const TextArea = ({
    id,
    placeholder,
    value,
    onChange,
    disabled = false,
    height = "300px",
}) => {
    return (
        <TextAreaStyled
            className="form-control"
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            height={height}
        />
    );
};

export default TextArea;

// prop-types
TextArea.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

// styled
const TextAreaStyled = styled.textarea`
    height: ${({ height }) => height};
`;
