import React, { useReducer, useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

/* Context ---------------------------*/
import ResultsContext from "./ResultsContext/index.js";
import reducer from "./ResultsContext/reducer.js";

/* Components ---------------------------*/
import SwConnectors from "./SwConnectors/SwConnectors.jsx";
import Results from "./Results/Results.jsx";

const ResultsDiagram = ({ results }) => {
    const [state, dispatch] = useReducer(reducer, results);

    // useMemo so it does not pass value on every render
    const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

    return (
        <ResultsContext.Provider value={value} displayName={`Results Diagram`}>
            <ResultsDiagramStyled className="ResultsDiagram">
                <SwConnectors />
                <Results />
            </ResultsDiagramStyled>
        </ResultsContext.Provider>
    );
};

export default ResultsDiagram;

// prop-types
ResultsDiagram.propTypes = {
    results: PropTypes.object.isRequired,
};

const ResultsDiagramStyled = styled.div`
    position: relative;
`;
