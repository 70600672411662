import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Hooks ---------------------------*/
import { useAuth } from "Auth0/useAuth.js";

// components
import Preloader from "Common/Preloader";

const redirectUrls = [
    { roleId: 1, url: `/auth/cp/admin` },
    { roleId: 2, url: `/auth/cp/client` },
    { roleId: 3, url: `/auth/cp/survey-user` },
];

const ControlPanel = () => {
    let navigate = useNavigate();
    const { userProfile } = useAuth();

    useEffect(() => {
        const roleId = userProfile?.roleId || 3;
        const redirectUrl = redirectUrls.find((ru) => ru.roleId === roleId);
        navigate(redirectUrl.url);
    }, []);

    return <Preloader />;
};

export default ControlPanel;
