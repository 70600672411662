import styled from "styled-components";
import PropTypes from "prop-types";

const Checkbox = ({
    id,
    isChecked,
    onChange,
    textIfChecked = "",
    textIfNotChecked = "",
    disabled = false,
}) => {
    const displayText = isChecked ? textIfChecked : textIfNotChecked;

    return (
        <CheckboxStyled>
            <input
                className="form-check-input"
                type="checkbox"
                id={id}
                checked={isChecked}
                onChange={onChange}
                disabled={disabled}
            />
            <span className="displayText">{displayText}</span>
        </CheckboxStyled>
    );
};

export default Checkbox;

// prop-types
Checkbox.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isChecked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    textIfChecked: PropTypes.string,
    textIfNotChecked: PropTypes.string,
    disabled: PropTypes.bool,
};

const CheckboxStyled = styled.span`
    .displayText {
        margin-left: 5px;
    }
`;
