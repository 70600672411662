import React from "react";
import styled from "styled-components";

/* Context ---------------------------*/
import RankItemsContext from "../../RankItemsContext/index";

const Header = ({ title, count }) => {
    return (
        <HeaderStyled className="Header">
            <h3>{title}</h3>
            <div className="count">({count})</div>
        </HeaderStyled>
    );
};

export default Header;

const HeaderStyled = styled.header`
    padding: 10px;
    border-bottom: solid 3px rgba(51, 51, 51, 0.2);
    h3 {
        display: inline-block;
        margin: 0px 10px;
        font-size: 20px;
    }
    .count {
        display: inline-block;
        font-size: 16px;
    }
`;
