import styled from "styled-components";
import PropTypes from "prop-types";

import { getHumanDate } from "Common/utils.js";

const SubmissionDate = ({ createdAt }) => {
    const submDate = getHumanDate(createdAt);
    return (
        <SubmissionDateStyled>
            <b>Date:</b> {submDate.date.mid} {submDate.time}
        </SubmissionDateStyled>
    );
};

export default SubmissionDate;

// prop-types
SubmissionDate.propTypes = {
    createdAt: PropTypes.string.isRequired,
};

const SubmissionDateStyled = styled.div``;
