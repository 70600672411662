/*---------------------------
| IE 11 Support
---------------------------*/
import "react-app-polyfill/ie11";

/*---------------------------
| Auth0
---------------------------*/
import Auth0Provider from "Auth0/Auth0Provider.jsx";

/*---------------------------
| Redux
---------------------------*/
import reduxStore from "Redux/index";
import { Provider as ReduxProvider } from "react-redux";

/*---------------------------
| React and 3rd Party Libraries
---------------------------*/
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { MediaQueryProvider } from "Common/useMediaQuery.js";

/*---------------------------
| Components
---------------------------*/
import Routes from "./Routes.jsx";

/*---------------------------
| Compose
---------------------------*/
const Compose = () => {
    return (
        <ReduxProvider store={reduxStore}>
            <BrowserRouter>
                <Auth0Provider>
                    <MediaQueryProvider>
                        <Routes />
                    </MediaQueryProvider>
                </Auth0Provider>
            </BrowserRouter>
        </ReduxProvider>
    );
};

/*---------------------------
| Render to DOM
---------------------------*/
const container = document.getElementById("root");
const root = createRoot(container);
root.render(<Compose />);
