import PropTypes from "prop-types";

// Modal Context
import {
    ModalBody as BSModalBody,
    useModalContext,
} from "Common/BootStrap/Modal";

// components
import QuestionControl from "./QuestionControl";
import AddQuestion from "./AddQuestion";

const ModalBody = ({ survey }) => {
    const { reqObjects } = useModalContext();

    return (
        <BSModalBody
            title="Update Other Questions"
            saveButtonText="Save Other Questions"
            fullscreen={true}
        >
            <AddQuestion survey={survey} />

            <h3>Other Questions</h3>

            {reqObjects.map((reqObject, idx) => {
                return (
                    <QuestionControl
                        key={reqObject.id}
                        count={idx + 1}
                        reqObject={reqObject}
                        survey={survey}
                    />
                );
            })}
        </BSModalBody>
    );
};

export default ModalBody;

// prop-types
ModalBody.propTypes = {
    survey: PropTypes.object.isRequired,
};
