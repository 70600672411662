/*---------------------------
| Store
---------------------------*/
// Action Types
const actionPrefix = "ModalContext:";
const actionTypes = {
    SHOW_UPDATE: `${actionPrefix} showUpdate`,
    REQ_OBJECT_ADD: `${actionPrefix} reqObjectAdd`,
    REQ_OBJECT_UPDATE: `${actionPrefix} reqObjectUpdate`,
};

// Default state
export const getDefaultState = (defaultState) => {
    return {
        ...defaultState,
    };
};

// Action Creators
export const actions = {
    showUpdate: (show) => {
        return {
            type: actionTypes.SHOW_UPDATE,
            show,
        };
    },
    reqObjectAdd: (reqObject) => {
        return {
            type: actionTypes.REQ_OBJECT_ADD,
            reqObject,
        };
    },
    reqObjectUpdate: (reqObject) => {
        return {
            type: actionTypes.REQ_OBJECT_UPDATE,
            reqObject,
        };
    },
};

// Reducer
export const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SHOW_UPDATE: {
            return { ...state, show: action.show };
        }
        case actionTypes.REQ_OBJECT_ADD: {
            const { reqObject } = action;
            const newReqObjects = [
                ...state.reqObjects,
                {
                    id: state.newReqObjCount,
                    restType: "forge",
                    ...reqObject,
                },
            ];
            return {
                ...state,
                reqObjects: newReqObjects,
                newReqObjCount: state.newReqObjCount + 1,
            };
        }
        case actionTypes.REQ_OBJECT_UPDATE: {
            const { reqObject } = action;
            let newReqObjects = state.reqObjects.map((r) => {
                if (r.id === reqObject.id) {
                    return reqObject;
                }
                return r;
            });
            return { ...state, reqObjects: newReqObjects };
        }
        default: {
            return { ...state };
        }
    }
};
