import React, { useContext } from 'react';
import styled from 'styled-components';

/* Context ---------------------------*/
import ResultsContext from '../../ResultsContext/index.js';

/* Scripts ---------------------------*/
import { getDomainById } from '../../../Common/utils.js';

/* Components ---------------------------*/
import Mortar from './Mortar.jsx';
import Domain from './Domain/Domain.jsx';

const DomainWrapper = ({domainId}) => {

    const { state: { domains } } = useContext(ResultsContext);

    const domainResult = getDomainById(domains, domainId);

    return (
        <DomainWrapperStyled className='DomainWrapper'>
            <Mortar />
            {
                domainResult && <Domain domainResult={ domainResult } />
            }
        </DomainWrapperStyled>
    );
}

export default DomainWrapper;

const DomainWrapperStyled = styled.div`
    position: relative;
`;