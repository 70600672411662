import styled from "styled-components";
import PropTypes from "prop-types";

const Option = ({ option, isFirstNonOption = false }) => {
    const { value, display } = option;
    return (
        <OptionStyled
            value={value}
            hidden={isFirstNonOption}
            disabled={isFirstNonOption}
        >
            {display}
        </OptionStyled>
    );
};

export default Option;

// prop-types
Option.propTypes = {
    option: PropTypes.object.isRequired,
    isFirstNonOption: PropTypes.bool,
};

const OptionStyled = styled.option``;
