import React from 'react';
import styled from 'styled-components';

const Mortar = () => {
    return (
        <MortarStyled
            className="Mortar"
            src="/assets/img/square-transparent-pixel.png"
            aria-hidden="true"
        />
    );
};

export default Mortar;

const MortarStyled = styled.img`
    width: 100%;
`;
