import styled from "styled-components";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlasses } from "@fortawesome/free-solid-svg-icons";

/* Styles ---------------------------*/
import { buttonLinkStyles } from "./shared";

const ViewSurveyResult = ({ surveyId, subId }) => {
    return (
        <ViewSurveyResultStyled
            className="btn btn-info"
            title={`View Survey Submission Result #${subId}`}
            target={`survey-${surveyId}-submission-${subId}`}
            href={`/auth/results/${surveyId}/${subId}`}
        >
            <FontAwesomeIcon icon={faGlasses} />
        </ViewSurveyResultStyled>
    );
};

export default ViewSurveyResult;

// prop-types
ViewSurveyResult.propTypes = {
    surveyId: PropTypes.number.isRequired,
    subId: PropTypes.number.isRequired,
};

const ViewSurveyResultStyled = styled.a`
    ${buttonLinkStyles}
`;
