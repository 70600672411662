import PropTypes from "prop-types";

// components
import Modal, { ModalButton } from "Common/BootStrap/Modal";
import ModalBody from "./ModalBody";

const Resources = ({ survey }) => {
    const { domains } = survey;

    if (!domains.length) {
        return null;
    }

    let resources = [];

    domains.forEach((dom) => {
        dom.resources.forEach((res) => {
            resources.push({
                ...res,
                restType: "update",
            });
        });
    });

    return (
        <Modal
            reqObjects={resources}
            apiEndPoint={`/auth/surveys/resources`}
            method="post"
        >
            <ModalButton>Res</ModalButton>
            <ModalBody survey={survey} />
        </Modal>
    );
};

export default Resources;

// prop-types
Resources.propTypes = {
    survey: PropTypes.object.isRequired,
};
