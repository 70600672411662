import PropTypes from "prop-types";
import Nav from "react-bootstrap/Nav";

const UniversalLink = ({ display, href }) => {
    return <Nav.Link href={href}>{display}</Nav.Link>;
};

export default UniversalLink;

// prop-types
UniversalLink.propTypes = {
    display: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
};
