import styled from "styled-components";
import PropTypes from "prop-types";

const FooterMessages = ({ messages }) => {
    return (
        <FooterMessagesStyled dangerouslySetInnerHTML={{ __html: messages }} />
    );
};

export default FooterMessages;

// prop-types
FooterMessages.propTypes = {
    messages: PropTypes.string.isRequired,
};

const FooterMessagesStyled = styled.ul`
    padding: 40px 40px 40px 50px;
    background-color: #f7f6f6;
    li {
        margin: 0px 0px 10px;
        font-size: 16px;

        &:last-child {
            margin-bottom: 0px;
        }
    }
`;
