import React, { useContext } from "react";
import styled from "styled-components";

/* Context ---------------------------*/
import ResultsContext from "../ResultsContext/index.js";

const CenterStrengthWeakness = () => {
    const {
        state: { meta },
    } = useContext(ResultsContext);

    const { title, userChosen } = meta;

    return (
        <CenterStrengthWeaknessStyled className="CenterStrengthWeakness">
            <div className="inner">
                <h2>{title}</h2>
                <p>{userChosen}</p>
            </div>
        </CenterStrengthWeaknessStyled>
    );
};

export default CenterStrengthWeakness;

const CenterStrengthWeaknessStyled = styled.div`
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;

    .inner {
        width: 70%;
    }

    h2 {
        font-size: 2.9vw;
        font-weight: 700;
        color: #808080;
        margin: 0px;
    }
    p {
        font-size: 2.9vw;
        color: #808080;
        font-weight: 400;
        margin: 0px;
        line-height: 108%;
    }
`;
