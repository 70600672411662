import styled from "styled-components";

/* ClientDetailsContext ---------------------------*/
import { useClientDetailsContext } from "Pages/Auth/ControlPanel/ClientDetails/ClientDetailsContext/index.js";

/* Components ---------------------------*/
import Preloader from "Common/Preloader";
import SurveySubmissions from "../../SurveySubmissions";

const ClientSurveySubmissions = () => {
    const {
        config: { clientProfile, surveys, roleId, isLoading, selectedSurveyId },
    } = useClientDetailsContext();

    if (isLoading) return <Preloader />;

    let clientName = "";

    if (clientProfile) {
        const { organization, first_name, last_name } = clientProfile;
        clientName = organization || `${first_name} ${last_name}`;
    }

    return (
        <ClientSurveySubmissionsStyled className="ClientSurveySubmissions inset">
            <h1>{clientName} Surveys</h1>
            <SurveySubmissions
                surveys={surveys}
                roleId={roleId}
                clientIsRestricted={clientProfile.restricted}
                selectedSurveyId={selectedSurveyId}
            />
        </ClientSurveySubmissionsStyled>
    );
};

export default ClientSurveySubmissions;

const ClientSurveySubmissionsStyled = styled.div``;
