import styled from "styled-components";
import InformationLegendIcon from "Common/Results/ResultsDiagram/Results/DomainWrapper/Domain/InformationIcon/InformationIconLegend.jsx";

const Legend = () => {
    return (
        <LegendStyled>
            <div className="legend-section">
                <div className="content">
                    <b>Legend</b>
                    <div className="green-circle">
                        <span /> Outer Ring: Confidence
                    </div>
                    <div className="blue-circle">
                        <span /> Inner Ring: Importance{" "}
                    </div>
                    <div className="light-blue-circle">
                        <span /> Blue = Positively Related{" "}
                    </div>
                    <div className="gray-circle">
                        <span /> Gray = Negatively Related{" "}
                    </div>
                    <div className="white-circle">
                        <span /> No Rings/White = No Relation{" "}
                    </div>
                    <div className="center">
                        <InformationLegendIcon />
                        Information & Resources
                    </div>
                </div>
            </div>
        </LegendStyled>
    );
};

export default Legend;

const LegendStyled = styled.div`
    .legend-section {
        background-color: #dfeed2;
        padding: 20px;
        margin-top: 20px;
    }
    .legend-section b {
        margin-bottom: 10px;
    }
    .legend-section > div > div {
        margin-bottom: 1px;
        display: flex;
    }
    .legend-section > div > div span {
        border-radius: 50%;
        width: 20px;
        display: flex;
        height: 20px;
        margin-right: 10px;
    }
    .legend-section div.light-blue-circle,
    .legend-section div.gray-circle,
    .legend-section div.white-circle {
        align-items: center;
    }
    .legend-section div.green-circle {
        margin-top: 10px;
    }
    .legend-section div.green-circle span {
        border: 5px solid rgba(31, 124, 102, 1);
    }
    .legend-section div.blue-circle span {
        border: 5px solid rgba(37, 113, 187, 1);
    }
    .legend-section div.light-blue-circle span {
        background-color: #42bdef;
        border: 1px solid rgba(37, 113, 187, 1);
        width: 40px;
        border-radius: 20%;
        height: 10px;
        align-items: center;
    }
    .legend-section div.gray-circle span {
        background-color: #979797;
        border: 1px solid rgba(37, 113, 187, 1);
        width: 40px;
        border-radius: 20%;
        height: 10px;
        align-items: center;
    }
    .legend-section div.white-circle span {
        background-color: #fff;
        border: 1px solid rgba(37, 113, 187, 1);
        width: 40px;
        border-radius: 20%;
        height: 10px;
        align-items: center;
    }
    .legend-section i.bi-info-circle {
        margin-right: 10px;
    }
`;
