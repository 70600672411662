import Nav from "react-bootstrap/Nav";

/* Redux ---------------------------*/
import { useGetPreloadedStateQuery } from "Redux/apiSlice";

/* Hooks ---------------------------*/
import { useAuth } from "Auth0/useAuth.js";

/* Links ---------------------------*/
import { getLinks } from "./links";

const MainMenu = () => {
    // props / hooks / state
    const {
        userProfile: { roleId },
    } = useAuth();

    const { data: appData } = useGetPreloadedStateQuery();

    if (!appData) return <div>Loading...</div>;

    const {
        ui: { mainmenu },
    } = appData;

    const links = getLinks(roleId, mainmenu);

    return (
        <Nav>
            {links.map((link) => (
                <link.Component key={link.id} />
            ))}
        </Nav>
    );
};

export default MainMenu;
