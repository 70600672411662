import styled from "styled-components";
import PropTypes from "prop-types";

// styles
import { mediaQueries } from "Common/useMediaQuery.js";

// components
import LegendList from "./LegendList";

const LegendGroups = ({ primary, secondary }) => {
    return (
        <LegendGroupsStyled>
            <div className="primary">
                <LegendList list={primary} />
            </div>
            <div className="secondary">
                <LegendList list={secondary} />
            </div>
        </LegendGroupsStyled>
    );
};

export default LegendGroups;

// prop-types
LegendGroups.propTypes = {
    primary: PropTypes.array.isRequired,
    secondary: PropTypes.array.isRequired,
};

const LegendGroupsStyled = styled.div`
    @media screen and ${mediaQueries.mdUp} {
        display: flex;
        flex-wrap: wrap;

        > div {
            flex: 0 0 50%;
        }
    }
`;
