import PropTypes from "prop-types";
import BSModal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

// Context
import { useModalContext } from "Common/BootStrap/Modal/ModalContext/index";

export const ModalBody = ({
    title,
    children,
    fullscreen = false,
    saveButtonText = "Save",
    modalFooterButtons = [],
}) => {
    const { show, dispatch, actions, onSave } = useModalContext();

    const onHide = () => {
        dispatch(actions.showUpdate(false));
    };

    const footerButtons = [
        {
            id: 100,
            variant: "secondary",
            onClick: onHide,
            displayText: "Close",
        },
        ...modalFooterButtons,
        {
            id: 101,
            variant: "primary",
            onClick: onSave,
            displayText: saveButtonText,
        },
    ];

    return (
        <BSModal show={show} onHide={onHide} fullscreen={fullscreen}>
            <BSModal.Header closeButton>
                <BSModal.Title>{title}</BSModal.Title>
            </BSModal.Header>
            <BSModal.Body>{children}</BSModal.Body>
            {footerButtons && (
                <BSModal.Footer>
                    {footerButtons.map((footerButton) => {
                        const { id, variant, onClick, displayText } =
                            footerButton;
                        return (
                            <Button
                                key={id}
                                variant={variant}
                                onClick={onClick}
                            >
                                {displayText}
                            </Button>
                        );
                    })}
                </BSModal.Footer>
            )}
        </BSModal>
    );
};

export default ModalBody;

// prop-types
ModalBody.propTypes = {
    title: PropTypes.any.isRequired,
    children: PropTypes.any.isRequired,
    fullscreen: PropTypes.bool,
    saveButtonText: PropTypes.string,
    modalFooterButtons: PropTypes.array,
};
