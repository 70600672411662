/*===================================
||
|| ModalContext
||
===================================*/
import React, { createContext, useReducer, useMemo, useContext } from "react";
import PropTypes from "prop-types";

// store
import { reducer, actions, getDefaultState } from "./store";

// api
import { apiCall } from "Axios/apiCall";

/*---------------------------
| Context
---------------------------*/
const ModalContext = createContext();

// Provider
export const ModalContextProvider = ({
    children,
    apiEndPoint,
    method = "post",
    reqObjects = [],
    newReqObjCount = 1000,
}) => {
    // State Managed
    const defaultState = {
        show: false,
        reqObjects,
        newReqObjCount,
        apiEndPoint,
        method,
    };

    const [state, dispatch] = useReducer(
        reducer,
        getDefaultState(defaultState)
    );

    // useMemo so it does not pass value on every render
    const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

    return (
        <ModalContext.Provider value={value} displayName={"ModalContext"}>
            <MountingWrapper>{children}</MountingWrapper>
        </ModalContext.Provider>
    );
};

// prop-types
ModalContextProvider.propTypes = {
    children: PropTypes.any,
    apiEndPoint: PropTypes.string.isRequired,
    method: PropTypes.string,
    reqObjects: PropTypes.array,
    newReqObjCount: PropTypes.number,
};

// MountingWrapper
const MountingWrapper = ({ children }) => {
    // const { state, dispatch } = useContext(ModalContext);

    // useEffect(() => {
    //     console.log("Do something on mount, like api calls");
    // }, []);

    return <>{children}</>;
};

// prop-types
MountingWrapper.propTypes = {
    children: PropTypes.any,
};

const handleSave = (state, dispatch) => {
    const { reqObjects, apiEndPoint, method } = state;

    return async (e) => {
        e?.preventDefault();
        const reqData = reqObjects.filter((fd) => {
            const isNewAndRemove = fd.id >= 1000 && fd.restType === "destroy";
            return !isNewAndRemove;
        });

        const servResp = await apiCall(method, apiEndPoint, reqData);

        // TODO: handle error and pending response - loader like client details
        console.log({ servResp });

        // Refresh the page
        setTimeout(() => {
            location.reload();
        }, 300);
    };
};

// useModalContext
export const useModalContext = () => {
    const { state, dispatch } = useContext(ModalContext);

    const reqObjectAdd = (reqObject) => {
        dispatch(actions.reqObjectAdd(reqObject));
    };
    const reqObjectUpdate = (reqObject) => {
        dispatch(actions.reqObjectUpdate(reqObject));
    };

    return {
        ...state,
        dispatch,
        actions,
        onSave: handleSave(state, dispatch),
        reqObjectAdd,
        reqObjectUpdate,
    };
};
