import styled from "styled-components";

// ReduxRTK API
import { useGetSharksQuery } from "Redux/apiAuthSlice.js";

// components
import Preloader from "Common/Preloader.jsx";
import Shark from "./Shark";

const Sharks = () => {
    const { data: sharks, isLoading } = useGetSharksQuery();

    if (isLoading) {
        return <Preloader />;
    }

    return (
        <SharksStyled>
            <h4>Get Sharks</h4>

            {sharks.map((shark) => {
                return <Shark key={shark.id} shark={shark} />;
            })}
        </SharksStyled>
    );
};

export default Sharks;

const SharksStyled = styled.div``;
