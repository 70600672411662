import React, { useReducer, useContext } from "react";
import styled from "styled-components";

/* Context ---------------------------*/
import RankItemsContext from "./RankItemsContext/index.js";
import reducer from "./RankItemsContext/reducer.js";

/* Scripts ---------------------------*/
import { colors } from "./colors.js";

/* Components ---------------------------*/
import RankItems from "./RankItems/RankItems.jsx";

export const RankItemsProvider = ({ children, config }) => {
    let { thresholds, unranked, ranked } = config;

    const rankCount = ranked.length;

    const totalItems = unranked.length + ranked.length;
    const min = thresholds.min <= totalItems ? thresholds.min : totalItems;

    const defaultState = {
        ...config,
        conditions: {
            count: {
                minThresholdMet: rankCount >= thresholds.min, // essentially in the window between min and max. We have to get to min first, before enforcing conditions.
                min: {
                    value: min,
                    isMin: rankCount === min,
                    satisfied: rankCount >= min,
                },
                max: {
                    value: thresholds.max,
                    isMax: rankCount === thresholds.max,
                    satisfied: rankCount <= thresholds.max,
                },
            },
        },
        unranked: config.unranked.map((i, idx) => {
            return {
                ...i,
                rankOrderColor: colors[idx],
            };
        }),
    };

    const [state, dispatch] = useReducer(reducer, defaultState);

    return (
        <RankItemsContext.Provider
            value={{ state, dispatch }}
            displayName={`Rank Items`}
        >
            {children}
        </RankItemsContext.Provider>
    );
};

export const useRankItems = () => {
    const {
        state: { ranked, unranked },
    } = useContext(RankItemsContext);
    return {
        RankItems,
        ranked,
        unranked,
    };
};

const RankItemsStyled = styled.div``;
