import styled from "styled-components";
import PropTypes from "prop-types";

// context
import { useSurvey } from "Pages/Auth/Survey/SurveyForm/SurveyContext";

// components
import Option from "./Option";

const SelectMenu = ({ decQuestion }) => {
    const { id: qID, sKey, sNumber, qDbId, options } = decQuestion;
    const { answers, answerUpdate } = useSurvey();

    let selectedOption = options[0];

    const thisQuestionsAnswer = answers.find((a) => a.qID === qID);

    if (thisQuestionsAnswer) {
        const { answer } = thisQuestionsAnswer;
        selectedOption = options.find((tqo) => tqo.value === answer);
    }

    const onChange = (e) => {
        const option = options.find((o) => o.value === e.target.value);
        answerUpdate({ sNumber, sKey, qID, qDbId, answer: option.value });
    };

    return (
        <SelectmenuStyled
            id={qID}
            className="form-select"
            onChange={onChange}
            defaultValue={selectedOption.value}
            required
        >
            {options.map((option, idx) => {
                const isFirstNonOption = idx === 0;

                return (
                    <Option
                        key={option.id}
                        option={option}
                        isFirstNonOption={isFirstNonOption}
                    />
                );
            })}
        </SelectmenuStyled>
    );
};

export default SelectMenu;

// prop-types
SelectMenu.propTypes = {
    decQuestion: PropTypes.object.isRequired,
};

const SelectmenuStyled = styled.select``;
