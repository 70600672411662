export const getDecoratedSectionById = (sID, state) => {
    const section = state.sections.find((s) => s.id === sID);
    const thisSection = section || { id: sID };
    return getDecoratedSection(thisSection, state);
};

export const getDecoratedSection = (section, state) => {
    return {
        ...section,
        eventKey: getSectionEventKey(section, state.sections),
        ...getSectionState(section, state),
    };
};

const getSectionEventKey = (section, sections) => {
    const thisSection = sections.find((s) => s.id === section.id);
    return thisSection ? thisSection.number + 1 : 1;
};

const getSectionState = (section, state) => {
    const { sections, sectionIdsCompleted, rankedItemsConfig } = state;
    const { id, number, hasRankedItems } = section;
    const totalSections = sections.length;

    // calculate
    const isComplete = getIsSectionCompleted(id, sectionIdsCompleted);
    const statusMessage = getSectionStatusMessage(
        number,
        totalSections,
        isComplete
    );
    const isDisabled = getIsSectionDisabled(id, sections, sectionIdsCompleted);
    const last = sections[sections.length - 1];
    const isLastSection = last.id === id;

    return {
        isComplete,
        statusMessage,
        isDisabled,
        isLastSection,
        rankedItemsConfig: hasRankedItems ? rankedItemsConfig : null,
    };
};

const getSectionStatusMessage = (
    sectionNumber,
    totalSections,
    isSectionComplete
) => {
    // Must not be a Section (e.g. intro)
    if (!sectionNumber) {
        return "";
    }

    let sectionStatusMessage = `${sectionNumber} of ${totalSections}`;

    if (isSectionComplete) {
        sectionStatusMessage = `Completed ${sectionStatusMessage}`;
    }

    return sectionStatusMessage;
};

const getIsSectionCompleted = (sectionId, sectionIdsCompleted) => {
    return sectionIdsCompleted.includes(sectionId);
};

const getIsSectionDisabled = (sectionId, sections, sectionIdsCompleted) => {
    let prevSectionId = "introduction";

    sections.forEach((s, idx) => {
        if (s.id === sectionId) {
            const previous = sections[idx - 1];
            if (previous) {
                prevSectionId = previous.id;
            }
        }
    });

    return !sectionIdsCompleted.includes(prevSectionId);
};
