import styled from "styled-components";
import PropTypes from "prop-types";

const Deets = ({ survey }) => {
    const { id: surveyId, submissions, token } = survey;
    return (
        <DeetsStyled>
            <div className="deet">
                <b>ID:</b> {surveyId}
            </div>

            <div className="deet">
                <b>Token:</b> {token}
            </div>

            <div className="deet">
                <b>Submissions:</b> {submissions.length}
            </div>
        </DeetsStyled>
    );
};

export default Deets;

// prop-types
Deets.propTypes = {
    survey: PropTypes.object.isRequired,
};

const DeetsStyled = styled.div`
    display: inline-flex;

    .deet {
        margin-right: 30px;
    }
`;
