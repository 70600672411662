import styled from "styled-components";
import PropTypes from "prop-types";

const ControlWithLabel = ({ label, id, children }) => {
    return (
        <ControlWithLabelStyled className="ControlWithLabel">
            <label className="small" htmlFor={id}>
                {label}
            </label>

            {children}
        </ControlWithLabelStyled>
    );
};

export default ControlWithLabel;

// prop-types
ControlWithLabel.propTypes = {
    children: PropTypes.any,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
};

const ControlWithLabelStyled = styled.div`
    margin-bottom: 10px;

    label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
    }
`;
