// components
import Section from "./Section";

// context
import { useSurvey } from "Pages/Auth/Survey/SurveyForm/SurveyContext";

const SectionList = () => {
    const { sections } = useSurvey();
    return sections.map((section) => {
        return <Section key={section.id} section={section} />;
    });
};

export default SectionList;
