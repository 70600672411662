import React from "react";
import styled from "styled-components";
import { Outlet, NavLink } from "react-router-dom";

const ExamplesLayout = () => {
    return (
        <ExamplesLayoutStyled className="Layout">
            <nav className="sublinks">
                <NavLink to="/auth/health/examples/">Results Diagram</NavLink>
                <NavLink to="/auth/health/examples/inline">
                    Inline Diagram
                </NavLink>
                <NavLink to="/auth/health/examples/rank">Rank Items</NavLink>
            </nav>
            <Outlet />
        </ExamplesLayoutStyled>
    );
};

export default ExamplesLayout;

const ExamplesLayoutStyled = styled.div`
    .Header {
        margin: 0px;
    }
`;
