import styled from "styled-components";
import PropTypes from "prop-types";

// context
import { useSurvey } from "Pages/Auth/Survey/SurveyForm/SurveyContext";

const Textarea = ({ decQuestion }) => {
    const { id: qID, sKey, sNumber, qDbId } = decQuestion;
    const { answers, answerUpdate } = useSurvey();

    const thisQuestionsAnswer = answers.find((a) => a.qID === qID);

    let answerValue = "";
    if (thisQuestionsAnswer) {
        const { answer } = thisQuestionsAnswer;
        answerValue = answer;
    }

    const onChange = (e) => {
        answerUpdate({ sNumber, sKey, qID, qDbId, answer: e.target.value });
    };

    // TODO: This placeholder (and other attrs) should change to Weakness if its in that context
    // Also used in more than just Strength and Weakness - like other questions
    return (
        <TextareaStyled
            className="form-control"
            id={qID}
            aria-describedby="selfDeclaredStrength"
            // placeholder="Self Declared Strength"
            value={answerValue}
            onChange={onChange}
            required
        />
    );
};

export default Textarea;

// prop-types
Textarea.propTypes = {
    decQuestion: PropTypes.object.isRequired,
};

const TextareaStyled = styled.textarea`
    font-size: 14px;

    &::placeholder {
        font-size: 10px;
    }
`;
