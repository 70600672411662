import styled from "styled-components";
import PropTypes from "prop-types";

// components
import Button from "./Button";

const ExportCSVButton = ({ survey }) => {
    const { id, submissions } = survey;
    if (submissions.length === 0) return null;
    return <Button surveyId={id} />;
};

export default ExportCSVButton;

// prop-types
ExportCSVButton.propTypes = {
    survey: PropTypes.object.isRequired,
};

const ExportCSVButtonStyled = styled.div``;
