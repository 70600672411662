import React from "react";
import styled from "styled-components";

const SectionCollection = ({ children, title }) => {
    return (
        <SectionCollectionStyled className="SectionCollection">
            <h3>{title}</h3>
            <div className="test-set">{children}</div>
        </SectionCollectionStyled>
    );
};

export default SectionCollection;

const SectionCollectionStyled = styled.section`
    border: solid 1px #999;
    padding: 10px;
    margin: 0px 0px 10px;

    h3 {
        font-size: 20px;
        margin: 0px;
    }

    .test-set {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        & > div {
            border: solid 1px #ccc;
            width: 100%;
            max-width: 500px;
            margin: 10px;
            padding: 10px;
        }
    }
`;
