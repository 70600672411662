import { actionTypes } from './actionTypes.js';

/*---------------------------
| Actions (Action Creators)
---------------------------*/
export const popUpOpen = (domainId) => {
    return {
        type: actionTypes.RES_POPUP_OPEN,
        domainId: domainId,
    };
}
export const popUpClose = (domainId) => {
    return {
        type: actionTypes.RES_POPUP_CLOSE,
        domainId: domainId,
    };
}
export const percOptionsNext = (domainId) => {
    return {
        type: actionTypes.RES_PERC_OPTIONS_NEXT,
        domainId: domainId,
    };
}
export const percOptionsPrev = (domainId) => {
    return {
        type: actionTypes.RES_PERC_OPTIONS_PREV,
        domainId: domainId,
    };
}