import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";

/* Redux ---------------------------*/
import { useGetPreloadedStateAuthQuery } from "Redux/apiAuthSlice.js";

/* Components ---------------------------*/
import Preloader from "Common/Preloader.jsx";

export const useAuth = () => {
    // We want to show Survey Branding whenever surveyId and surveyToken params are present
    // Otherwise, we default to See Degree Survey branding
    // So its intentional that these params are often undefined
    const { surveyId, surveyToken } = useParams();
    const auth0 = useAuth0();
    const { isLoading, data } = useGetPreloadedStateAuthQuery({
        surveyId,
        surveyToken,
    });

    return {
        isLoading,
        Preloader,
        userProfile: data?.user || {},
        branding: data?.branding || {},
        auth0,
    };
};
