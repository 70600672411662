import React, { useContext } from "react";
import styled from "styled-components";

/* Context ---------------------------*/
import RankItemsContext from "../../RankItemsContext/index.js";

/* Components ---------------------------*/
import Header from "./Header.jsx";
import ItemsList from "../Items/ItemsList.jsx";

const RankedItems = () => {
    const {
        state: { ranked, rankedText },
    } = useContext(RankItemsContext);

    return (
        <RankedItemsStyled className="RankedItems">
            <Header title={rankedText} count={ranked.length} />
            <ItemsList items={ranked} ranked={true} />
        </RankedItemsStyled>
    );
};

export default RankedItems;

const RankedItemsStyled = styled.div`
    background-color: #fff;
    list-style-type: none;
    margin: 0px;
    padding: 0px;

    .Header {
        background-color: #e5f1e3;
    }
`;
