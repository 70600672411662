import styled from "styled-components";
import PropTypes from "prop-types";

import { getUserName } from "Common/utils.js";

const Username = ({ roleId, clientIsRestricted, submittedBy }) => {
    if (roleId !== 2 || clientIsRestricted) return null;

    return (
        <UsernameStyled>
            <div className="name">
                <b>Name:</b> {getUserName(submittedBy)}
            </div>
            <div className="email">
                <b>Email:</b>{" "}
                <a href="mailto:{submittedBy.email}">{submittedBy.email}</a>
            </div>
        </UsernameStyled>
    );
};

export default Username;

// prop-types
Username.propTypes = {
    roleId: PropTypes.number.isRequired,
    clientIsRestricted: PropTypes.bool.isRequired,
    submittedBy: PropTypes.object.isRequired,
};

const UsernameStyled = styled.div`
    display: flex;
    > div {
        margin-right: 8px;
    }
`;
