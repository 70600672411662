import styled from "styled-components";
import PropTypes from "prop-types";

const Shark = ({ shark }) => {
    const { id, name } = shark;

    return (
        <SharkStyled>
            {id}: {name}
        </SharkStyled>
    );
};

export default Shark;

// prop-types
Shark.propTypes = {
    shark: PropTypes.object.isRequired,
};

const SharkStyled = styled.div`
    padding: 10px 0px;
    border-top: solid 2px #eee;
`;
