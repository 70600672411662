import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// components
import { AccordionWrapper } from "Common/BootStrap/Accordion.jsx";
import Survey from "./Survey";

const getDefaultActiveKey = (selectedSurveyId, surveys) => {
    return selectedSurveyId || surveys[0]?.id || 0;
};

const SurveySubmissions = ({
    surveys,
    roleId = 3,
    clientIsRestricted = false,
    selectedSurveyId,
}) => {
    const defaultActiveKey = getDefaultActiveKey(selectedSurveyId, surveys);

    return (
        <SurveySubmissionsStyled className="SurveySubmissions inset">
            <AccordionWrapper defaultActiveKey={defaultActiveKey}>
                {surveys.map((survey, idx) => {
                    return (
                        <Survey
                            key={survey.id}
                            eventKey={survey.id}
                            survey={survey}
                            roleId={roleId}
                            clientIsRestricted={clientIsRestricted}
                        />
                    );
                })}
            </AccordionWrapper>
        </SurveySubmissionsStyled>
    );
};

export default SurveySubmissions;

// prop-types
SurveySubmissions.propTypes = {
    surveys: PropTypes.array.isRequired,
    roleId: PropTypes.number.isRequired,
    clientIsRestricted: PropTypes.bool,
    selectedSurveyId: PropTypes.number,
};

const SurveySubmissionsStyled = styled.div``;
