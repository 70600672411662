import PropTypes from "prop-types";
import classNames from "classnames";

// components
import {
    AccordionItem as BSAccordionItem,
    AccordionHeader,
    AccordionBody,
} from "Common/BootStrap/Accordion.jsx";
import Header from "./Header";
import Body from "./Body";

export const AccordionItem = ({
    children,
    title,
    eventKey,
    id,
    disabled = false,
    accordionStatusMessage,
}) => {
    const theClassName = classNames({
        disabled: disabled,
    });

    return (
        <BSAccordionItem eventKey={eventKey} id={id} className={theClassName}>
            <AccordionHeader>
                <Header {...{ id, eventKey, title, accordionStatusMessage }} />
            </AccordionHeader>
            <AccordionBody>
                <Body sID={id}>{children}</Body>
            </AccordionBody>
        </BSAccordionItem>
    );
};

// prop-types
AccordionItem.propTypes = {
    children: PropTypes.any,
    eventKey: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    title: PropTypes.string.isRequired,
    accordionStatusMessage: PropTypes.string,
};
