import styled from "styled-components";
import PropTypes from "prop-types";

const SaveButton = ({ saveButtonText = "Save" }) => {
    return (
        <SaveButtonStyled>
            <button className="btn btn-primary" type="submit">
                {saveButtonText}
            </button>
        </SaveButtonStyled>
    );
};

export default SaveButton;

// prop-types
SaveButton.propTypes = {
    saveButtonText: PropTypes.string,
};

const SaveButtonStyled = styled.div``;
