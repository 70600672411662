import styled from "styled-components";
import PropTypes from "prop-types";

const OpeningText = ({ text }) => {
    return <OpeningTextStyled dangerouslySetInnerHTML={{ __html: text }} />;
};

export default OpeningText;

// prop-types
OpeningText.propTypes = {
    text: PropTypes.string,
};

const OpeningTextStyled = styled.ul`
    margin: 0px 0px 50px;
    padding: 40px 40px 40px 50px;
    background-color: #f7f6f6;

    li {
        margin: 0px 0px 10px;
        font-size: 16px;

        &:last-child {
            margin-bottom: 0px;
        }
    }
`;
