import { actionTypes } from './actionTypes.js';

/*---------------------------
| Actions (Action Creators)
---------------------------*/
export const rankedItemsOrderUpdate = (rankedItems) => {
    return {
        type: actionTypes.RO_RANKED_ITEMS_ORDER_UPDATE,
        rankedItems: rankedItems,
    };
}

export const addRankedItem = (item) => {
    return {
        type: actionTypes.RO_ADD_RANKED_ITEM,
        item: item,
    };
}

export const removeRankedItem = (item) => {
    return {
        type: actionTypes.RO_REMOVE_RANKED_ITEM,
        item: item,
    };
}

export const moveRankedItemUp = (item, domOrder) => {
    return {
        type: actionTypes.RO_MOVE_RANKED_ITEM_UP,
        item: item,
        domOrder: domOrder,
    };
}

export const moveRankedItemDown = (item, domOrder) => {
    return {
        type: actionTypes.RO_MOVE_RANKED_ITEM_DOWN,
        item: item,
        domOrder: domOrder,
    };
}

