import React from "react";
import styled from "styled-components";

/* ClientDetailsContext ---------------------------*/
import { ClientDetailsContextProvider } from "Pages/Auth/ControlPanel/ClientDetails/ClientDetailsContext/index.js";

/* Components ---------------------------*/
import CardTemplate from "Common/BootStrap/CardTemplate.jsx";
import AccountDetails from "./AccountDetails";
import AccountLimits from "./AccountLimits";
import Logo from "./Logo";
import InternalNotes from "./InternalNotes";
import Surveys from "./Surveys";

const ClientDetails = () => {
    return (
        <ClientDetailsContextProvider>
            <ClientDetailsStyled className="ClientDetails">
                <div className="container-xl mt-4">
                    <div className="row">
                        <div className="col-xl-8">
                            <CardTemplate title="Account Details">
                                <AccountDetails />
                            </CardTemplate>
                        </div>
                        <div className="col-xl-4">
                            <CardTemplate title="Account Limits">
                                <AccountLimits />
                            </CardTemplate>
                            <CardTemplate title="Account Logo">
                                <Logo />
                            </CardTemplate>
                            <CardTemplate title="Internal Notes">
                                <InternalNotes />
                            </CardTemplate>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12">
                    <Surveys />
                </div>
            </ClientDetailsStyled>
        </ClientDetailsContextProvider>
    );
};

export default ClientDetails;

const ClientDetailsStyled = styled.div``;
