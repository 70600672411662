import React from 'react';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';

const Header = ({children}) => {
    return (
        <HeaderStyled className="Header">
            {children}
        </HeaderStyled>
    );
};

export default Header;

const HeaderStyled = styled.div`
    background-color: #42bdef;
    text-align: center;
    padding: 20px 0px;
    margin: 0px 0px 50px;
    color: white;
    min-height: 106px;

    &.Header {
        img {
            height: 40px;
        }
    }

    .container {
        .navbar-brand,
        .nav-link {
            color: white;
        }
    }
`;
