import React from 'react';
import styled from 'styled-components';

/* Components ---------------------------*/
import RankedAndUnranked from './RankedAndUnranked/RankedAndUnranked.jsx';
import Feedback from './Feedback.jsx';

const RankItems = () => {
    return (
        <RankItemsStyled className='RankItems'>
            <Feedback />
            <RankedAndUnranked />
        </RankItemsStyled>
    );
}

export default RankItems;

const RankItemsStyled = styled.div`

`;