import styled from "styled-components";

// components
import TableHead from "./TableHead";
import RowsList from "./RowsList";

const SurveyTable = () => {
    return (
        <SurveyTableStyled className="SurveyTable table">
            <TableHead />
            <RowsList />
        </SurveyTableStyled>
    );
};

export default SurveyTable;

const SurveyTableStyled = styled.table``;
