import React from 'react';
import styled from 'styled-components';

/* Components ---------------------------*/
import RankedItems from './RankedItems.jsx';
import UnrankedItems from './UnrankedItems.jsx';

const RankedAndUnranked = () => {
    return (
        <RankedAndUnrankedStyled className='RankedAndUnranked'>
            <UnrankedItems />
            <RankedItems />
        </RankedAndUnrankedStyled>
    );
}

export default RankedAndUnranked;

const RankedAndUnrankedStyled = styled.div`

    @media screen and (min-width: 800px) {
        display: flex;
    }

    justify-content: space-between;

    .UnrankedItems {
        min-width: 290px;
        border: solid 3px #a0a0a0;
        flex: 0 0 49.7%;
        margin-bottom: 10px;
    }
    .RankedItems {
        min-width: 290px;
        border: solid 3px #689f6f;
        flex: 0 0 49.7%;
    }

    @media screen and (min-width: 800px) {
        .UnrankedItems {
            margin-bottom: 0px;
        }
    }
`;