import { useState } from "react";
import styled from "styled-components";

/* Redux ---------------------------*/
import { useCreateSharksMutation } from "Redux/apiAuthSlice.js";

// components
import ControlWithLabel from "Common/BootStrap/FormControls/ControlWithLabel";
import Input from "Common/BootStrap/FormControls/Input";

const PostShark = () => {
    const [updateData] = useCreateSharksMutation();

    const [name, nameUpdate] = useState("");

    const onChange = (e) => {
        nameUpdate(e.target.value);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const { data: resp } = await updateData([{ name }]);
    };

    return (
        <PostSharkStyled>
            <h4>Add Shark</h4>
            <form onSubmit={onSubmit}>
                <ControlWithLabel label="Add A Shark" id="shark-name">
                    <Input id="shark-name" value={name} onChange={onChange} />
                </ControlWithLabel>
                <button type="submit" className="btn btn-primary">
                    Add
                </button>
            </form>
        </PostSharkStyled>
    );
};

export default PostShark;

const PostSharkStyled = styled.div``;
