import styled from "styled-components";
import PropTypes from "prop-types";

// components
import Domains from "./Domains/index";
import Questions from "./Questions/index";
import OtherQuestions from "./OtherQuestions/index";
import Resources from "./Resources/index";
import Opentext from "./OpenText/index";

const AdminActions = ({ survey, clientId }) => {
    return (
        <AdminActionsStyled>
            <Domains survey={survey} />
            <Questions survey={survey} />
            <OtherQuestions survey={survey} />
            <Resources survey={survey} />
            <Opentext survey={survey} clientId={clientId} />
        </AdminActionsStyled>
    );
};

export default AdminActions;

// prop-types
AdminActions.propTypes = {
    survey: PropTypes.object.isRequired,
    clientId: PropTypes.number.isRequired,
};

const AdminActionsStyled = styled.div``;
