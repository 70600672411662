import styled from "styled-components";
import PropTypes from "prop-types";

export const ReqObjectWrapper = ({ reqObject, children }) => {
    if (reqObject.restType === "destroy") {
        return null;
    }

    return <ReqObjectWrapperStyled>{children}</ReqObjectWrapperStyled>;
};

export default ReqObjectWrapper;

// prop-types
ReqObjectWrapper.propTypes = {
    children: PropTypes.any,
    reqObject: PropTypes.object.isRequired,
};

const ReqObjectWrapperStyled = styled.div``;
