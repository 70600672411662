import React from 'react';
import styled from 'styled-components';

/* Components ---------------------------*/
import CircleOfDestiny from './CircleOfDestiny/CircleOfDestiny.jsx';

const ImportanceCircle = ({ percentage }) => {
    return (
        <ImportanceCircleStyled className="ImportanceCircle">
            <CircleOfDestiny
                percentage={percentage}
                color="#0071ba"
                width={4.5}
            />
        </ImportanceCircleStyled>
    );
};

export default ImportanceCircle;

const ImportanceCircleStyled = styled.div``;
