import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const LoggedOut = () => {
    return (
        <LoggedOutStyled className="LoggedOut inset">
            <h1>Log Out Success</h1>
            <p>
                You have been logged out successfully.{" "}
                <Link to="/auth/cp">Log back in.</Link>
            </p>
        </LoggedOutStyled>
    );
};

export default LoggedOut;

const LoggedOutStyled = styled.div``;
