import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';

/* Context ---------------------------*/
import ResultsContext from '../../../../ResultsContext/index.js';
import { popUpClose, percOptionsPrev, percOptionsNext } from '../../../../ResultsContext/actions.js';

/* Components ---------------------------*/
import TextBox from './TextBox.jsx';

const PopUp = ({popUp, id}) => {

    const { dispatch } = useContext(ResultsContext);

    const {
        messages
    } = popUp

    useEffect(() => {
        const handleOnKeyDown = (e) => {
            switch (e.keyCode) {
                case 27:
                    dispatch(popUpClose(id));
                    break;
                case 37:
                    dispatch(percOptionsPrev(id));
                    break;
                case 39:
                    dispatch(percOptionsNext(id));
                    break;
            }
        }

        document.addEventListener("keydown", handleOnKeyDown);

        // dismounts
        return () => {
            document.removeEventListener("keydown", handleOnKeyDown);
        }
    }, []);

    return (
        <PopUpStyled className='PopUp'>
            <TextBox id={ id } messages={ messages } />
        </PopUpStyled>
    );
}

export default PopUp;

const PopUpStyled = styled.div`

`;