import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

const Select = ({
    id,
    options,
    selectedOption,
    onChange,
    disabled = false,
    valueKey = "value",
    displayKey = "display",
}) => {
    return (
        <Form.Select
            id={id}
            aria-label="Default select example"
            onChange={onChange}
            disabled={disabled}
            value={selectedOption[valueKey]}
        >
            {options.map((option) => {
                return (
                    <option key={option.id} value={option[valueKey]}>
                        {option[displayKey]}
                    </option>
                );
            })}
        </Form.Select>
    );
};

export default Select;

// prop-types
Select.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    options: PropTypes.array.isRequired,
    selectedOption: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    valueKey: PropTypes.string,
    displayKey: PropTypes.string,
};
