import PropTypes from "prop-types";

// Context
import { ModalContextProvider } from "Common/BootStrap/Modal/ModalContext/index";

// components
export { useModalContext } from "Common/BootStrap/Modal/ModalContext/index";
export { ModalButton } from "./ModalButton.jsx";
export { ModalBody } from "./ModalBody.jsx";
export { ReqObjectWrapper } from "./ReqObjectWrapper.jsx";

const Modal = ({ children, apiEndPoint, method, reqObjects }) => {
    return (
        <ModalContextProvider
            apiEndPoint={apiEndPoint}
            method={method}
            reqObjects={reqObjects}
        >
            {children}
        </ModalContextProvider>
    );
};

export default Modal;

// prop-types
Modal.propTypes = {
    children: PropTypes.any,
    apiEndPoint: PropTypes.string.isRequired,
    method: PropTypes.string,
    reqObjects: PropTypes.array,
};
