import React from "react";
import styled from "styled-components";

/* Components ---------------------------*/
import CommonResultsDiagram from "Common/Results/ResultsDiagram";

/* Scripts ---------------------------*/
import { resultsData } from "./sampleData/index.js";
// import { resultsData } from "./sampleData/domains5.js";

const ResultsDiagramForIframe = () => {
    return (
        <ResultsDiagramForIframeStyled className="ResultsDiagramForIframe">
            <CommonResultsDiagram results={resultsData} />
        </ResultsDiagramForIframeStyled>
    );
};

export default ResultsDiagramForIframe;

const ResultsDiagramForIframeStyled = styled.div``;
