import React from 'react';
import styled, { css } from 'styled-components';

/* Components ---------------------------*/
import DragHandle from './DragHandle.jsx';
import UpDown from './UpDown.jsx';
import AddRemove from './AddRemove.jsx';
import TextCol from './TextCol/TextCol.jsx';

const Item = ({item, ranked, domOrder=0}) => {

    return (
        <ItemStyled
            className='Item'
            ranked={ ranked }
        >
            <div className='drag-handle-col'><DragHandle ranked={ ranked } /></div>
            <TextCol item={ item } domOrder={ domOrder } ranked={ ranked } />
            {
                ranked &&
                <div className='up-down-col'><UpDown item={ item } domOrder={ domOrder } /></div>
            }
            <div className='add-remove-col'><AddRemove item={ item } ranked={ ranked } /></div>
        </ItemStyled>
    );
}

export default Item;

const ItemStyled = styled.div`
    display: flex;

    .drag-handle-col {
        flex: 0 0 35px;
    }
    .TextCol {
        position: relative;
        padding: 0px;

        ${({ranked}) => {
            if (ranked) {
                return css`
                    flex: 0 0 calc(100% - 35px - 70px - 38px);
                    max-width: calc(100% - 35px - 70px - 38px);
                `;
            } else {
                return css`
                    flex: 0 0 calc(100% - 35px - 38px);
                    max-width: calc(100% - 35px - 38px);
                `;
            }
        }}
    }
    .up-down-col {
        flex: 0 0 70px;
    }
    .add-remove-col {
        flex: 0 0 38px;
    }
`;