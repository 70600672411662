import React from 'react';
import styled, { css } from 'styled-components';

const Tooltip = ({text, showToolTip}) => {
    return (
        <TooltipStyled className='Tooltip' dangerouslySetInnerHTML={{ __html: text }} showToolTip={ showToolTip } />
    );
}

export default Tooltip;

const TooltipStyled = styled.div`
    position: absolute;
    right: 0px;
    bottom: 27px;
    width: calc(80%);

    padding: 10px;
    background-color: white;
    font-size: 12px;

    box-shadow: 0px 0px 15px rgba(0,0,0,.3);

    ${({showToolTip}) => {
        if (!showToolTip) {
            return css`
                display: none;
            `;
        }
    }}
`;