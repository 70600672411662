import styled from "styled-components";
import PropTypes from "prop-types";

// Modal Context
import { ReqObjectWrapper, useModalContext } from "Common/BootStrap/Modal";

// components
import ControlWithLabel from "Common/BootStrap/FormControls/ControlWithLabel";
import ControlWithRemoveButton from "Common/BootStrap/FormControls/ControlWithRemoveButton";
import Input from "Common/BootStrap/FormControls/Input";

const DomainControl = ({ count, reqObject, survey }) => {
    const { reqObjectUpdate } = useModalContext();
    const { id, domain } = reqObject;

    // TODO: this depends on more than just domain questions
    // Don't let them delete if we have attached relationships
    const { domainQuestions } = survey;
    const hasDomainQuestions = domainQuestions.find(
        (dq) => dq.surveyDomainId === id
    );

    const handleUpdate = (prop) => {
        reqObjectUpdate({
            ...reqObject,
            ...prop,
        });
    };

    const onRemove = () => {
        if (hasDomainQuestions) {
            alert(
                `Unable to Remove Domain: This domain (${domain}) has questions attached to it. Remove all questions before attempting to remove this Domain`
            );
        } else {
            reqObjectUpdate({
                ...reqObject,
                restType: "destroy",
            });
        }
    };

    return (
        <ReqObjectWrapper reqObject={reqObject}>
            <DomainControlStyled>
                <ControlWithLabel label={`Domain ${count}`} id={id}>
                    <ControlWithRemoveButton onRemove={onRemove}>
                        <Input
                            id={id}
                            placeholder="Enter Domain Name"
                            value={domain}
                            onChange={(e) => {
                                handleUpdate({ domain: e.target.value });
                            }}
                        />
                    </ControlWithRemoveButton>
                </ControlWithLabel>
            </DomainControlStyled>
        </ReqObjectWrapper>
    );
};

export default DomainControl;

// prop-types
DomainControl.propTypes = {
    count: PropTypes.number.isRequired,
    reqObject: PropTypes.object.isRequired,
    survey: PropTypes.object.isRequired,
};

const DomainControlStyled = styled.div``;
