/*---------------------------
| Store
---------------------------*/
// Action Types
const actionPrefix = "ClientDetailsContext:";
const actionTypes = {
    UPDATE_FORM_INPUT: `${actionPrefix} updateFormInput`,
    UPDATE_FORM_STATUS: `${actionPrefix} updateFormStatus`,
};

// Default state
export const getDefaultState = (defaultState) => {
    return {
        ...defaultState,
    };
};

// Action Creators
export const actions = {
    updateFormInput: (input) => {
        return {
            type: actionTypes.UPDATE_FORM_INPUT,
            input,
        };
    },
    updateFormStatus: (formStatus, error) => {
        return {
            type: actionTypes.UPDATE_FORM_STATUS,
            formStatus,
            error,
        };
    },
};

// Reducer
export const reducer = (state, action) => {
    const { type } = action;
    // console.group("ClientDetailsContext");
    // console.log({ actionType: type });
    // console.log({ action });
    // console.groupEnd();

    switch (type) {
        case actionTypes.UPDATE_FORM_INPUT: {
            const { input } = action;
            let inputFound = false;
            let newFormData = state.formData.map((i) => {
                if (i.id === input.id) {
                    inputFound = true;
                    return input;
                }
                return i;
            });

            if (!inputFound) {
                newFormData.push(input);
            }

            return { ...state, formData: newFormData };
        }
        case actionTypes.UPDATE_FORM_STATUS: {
            return {
                ...state,
                formStatus: action.formStatus,
                error: action.error || "",
            };
        }
        default: {
            return { ...state };
        }
    }
};
