import PropTypes from "prop-types";

// Modal Context
import {
    ModalBody as BSModalBody,
    useModalContext,
} from "Common/BootStrap/Modal";

// components
import ResourceControl from "./ResourceControl";
import AddResource from "./AddResource";

const ModalBody = ({ survey }) => {
    const { reqObjects } = useModalContext();

    return (
        <BSModalBody
            title="Update Resources"
            saveButtonText="Save Resources"
            fullscreen={true}
        >
            <AddResource survey={survey} />

            <h3>Resources</h3>

            {reqObjects.map((reqObject, idx) => {
                return (
                    <ResourceControl
                        key={reqObject.id}
                        count={idx + 1}
                        reqObject={reqObject}
                        survey={survey}
                    />
                );
            })}
        </BSModalBody>
    );
};

export default ModalBody;

// prop-types
ModalBody.propTypes = {
    survey: PropTypes.object.isRequired,
};
