import styled from "styled-components";

/* Context ---------------------------*/
import { useClientDetailsContext } from "Pages/Auth/ControlPanel/ClientDetails/ClientDetailsContext/index.js";

// components
import Modal, { ModalButton } from "Common/BootStrap/Modal/index";
import ModalBody from "./ModalBody";

const AddSurvey = () => {
    const {
        config: { roleId, clientId },
    } = useClientDetailsContext();

    const newSurvey = {
        id: 1000,
        restType: "forge",
        userId: clientId,
        name: "",
    };

    if (roleId !== 1) {
        return null;
    }

    return (
        <AddSurveyStyled className="AddSurvey">
            <Modal
                reqObjects={[newSurvey]}
                apiEndPoint={`/auth/surveys`}
                method="post"
            >
                <ModalButton>+</ModalButton>
                <ModalBody />
            </Modal>
        </AddSurveyStyled>
    );
};

export default AddSurvey;

const AddSurveyStyled = styled.div`
    display: flex;
    justify-content: center;

    button.ModalButton {
        position: relative;
        top: -3px;

        display: block;
        width: 100%;
        height: 100%;

        margin: 0;
        padding: 0;
        font-size: 36px;
        line-height: 36px;
        border: none;
        background: transparent;
    }
`;
