import styled from "styled-components";
import { useParams } from "react-router-dom";

/* Redux ---------------------------*/
import { useGetSurveyResultsQuery } from "Redux/apiAuthSurveySlice";

/* Components ---------------------------*/
import Preloader from "Common/Preloader.jsx";
import CommonResultsDiagram from "Common/Results/ResultsDiagram";

/* Sample Data ---------------------------*/
import { sampleResults } from "./sampleResults";

const ResultsDiagram = () => {
    // /survey/results/:surveyId/:createdBy/:reportType
    const { surveyId, surveySubmissionId, reportType } = useParams();

    // Redux
    const { data: surveyResults, isLoading } = useGetSurveyResultsQuery({
        surveyId,
        surveySubmissionId,
    });

    if (isLoading) {
        return <Preloader />;
    }

    const { diagramReports } = surveyResults;
    const liveResults = diagramReports[reportType];
    const showSampleResults = false;
    const results = showSampleResults ? sampleResults : liveResults;

    return (
        <ResultsDiagramStyled>
            <CommonResultsDiagram results={results} />
        </ResultsDiagramStyled>
    );
};

export default ResultsDiagram;

const ResultsDiagramStyled = styled.div``;
