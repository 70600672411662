const getDefaultState = (defaultProps) => {
    return {
        ...defaultProps,
        hasLoaded: false,
        canSubmitForm: false,
        isSubmitting: false,
        sectionIdsCompleted: ["introduction"],
        answers: [],
    };
};

export default getDefaultState;
