import React, { useContext } from "react";
import styled from "styled-components";

/* Context ---------------------------*/
import ResultsContext from "../../../../ResultsContext/index.js";
import {
    percOptionsNext,
    percOptionsPrev,
} from "../../../../ResultsContext/actions.js";

/* Components ---------------------------*/
import Arrow from "./Arrow.jsx";

const Nav = ({ id, percents }) => {
    const { currPercOptionIdx, percOptions } = percents;

    const { dispatch } = useContext(ResultsContext);

    if (percOptions.length < 2) {
        return "";
    }

    const handlePrev = (e) => {
        e.stopPropagation();
        dispatch(percOptionsPrev(id));
    };

    const handleNext = (e) => {
        e.stopPropagation();
        dispatch(percOptionsNext(id));
    };

    return (
        <NavStyled className="Nav">
            <Arrow
                direction={"left"}
                onClick={handlePrev}
                active={currPercOptionIdx === 0}
            />
            <Arrow
                direction={"right"}
                onClick={handleNext}
                active={currPercOptionIdx === 1}
            />
        </NavStyled>
    );
};

export default Nav;

const NavStyled = styled.nav`
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    justify-content: center;
    align-items: center;

    width: 24%;
    margin: 0px auto;

    @media print {
        display: none;
    }
`;
