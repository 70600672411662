import React from 'react';
import styled from 'styled-components';

import { ReactComponent as InformationIconSvg } from './information-icon.svg';

const InformationIcon = () => {
    return (
        <InformationIconStyled className="InformationIcon">
            <InformationIconSvg />
        </InformationIconStyled>
    );
};

export default InformationIcon;

const InformationIconStyled = styled.div`

    position: absolute;
    top: -2.3vw;
    right: -1.4vw;

    width: 3vw;
    height: 3vw;

    .information-icon-circle {
        fill: #42bdee;
        stroke: gray;
        stroke-miterlimit: 10;
        stroke-width: 0.63px;
    }
    .information-icon-text {
        fill: #fff;
    }

    &:hover, &:focus, &:active {
        .information-icon-circle {
            fill: #EDC55C;
        }
    }
`;
