import styled from "styled-components";

/* ClientDetailsContext ---------------------------*/
import { useClientDetailsContext } from "Pages/Auth/ControlPanel/ClientDetails/ClientDetailsContext/index.js";

// components
import Preloader from "Common/Preloader";
import ErrorMessage from "./ErrorMessage";

const FormStatusOverlay = () => {
    const { formStatus, error } = useClientDetailsContext();

    if (formStatus === "listening") {
        return null;
    }

    return (
        <FormStatusOverlayStyled>
            {formStatus === "processing" && <Preloader />}
            {formStatus === "error" && <ErrorMessage error={error} />}
        </FormStatusOverlayStyled>
    );
};

export default FormStatusOverlay;

const FormStatusOverlayStyled = styled.div`
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;

    background-color: rgba(255, 255, 255, 0.8);

    display: flex;
    justify-content: center;
    align-items: center;
`;
