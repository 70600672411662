import React from "react";
import styled from "styled-components";

const ScalingIFrame = ({ src, aspectRatio = "9/9" }) => {
    return (
        <ScalingIFrameStyled
            className="ScalingIFrame"
            scrolling="no"
            src={src}
            aspectRatio={aspectRatio}
        />
    );
};

export default ScalingIFrame;

const ScalingIFrameStyled = styled.iframe`
    width: 100%;
    /* border: solid 2px purple; */
    outline: none;

    aspect-ratio: ${({ aspectRatio }) => aspectRatio};
`;
