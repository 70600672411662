import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import download from "downloadjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faSpinner } from "@fortawesome/free-solid-svg-icons";

/* Redux ---------------------------*/
import { useGetCsvExportQuery } from "Redux/apiAuthSurveySlice";

/* Styles ---------------------------*/
import { buttonLinkStyles } from "../shared";

const Button = ({ surveyId }) => {
    const { data, isLoading } = useGetCsvExportQuery(surveyId);

    const onClick = () => {
        const { csvObj } = data;
        const { fileData, filename } = csvObj;
        download(fileData, filename);
    };

    const icon = isLoading ? faSpinner : faFileCsv;

    return (
        <ExportApiCallStyled
            className="Button btn btn-secondary"
            type="button"
            title="Download Survey Results to CSV"
            disabled={isLoading}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={icon} spin={isLoading} />
        </ExportApiCallStyled>
    );
};

export default Button;

// prop-types
Button.propTypes = {
    surveyId: PropTypes.number.isRequired,
};

const ExportApiCallStyled = styled.button`
    ${buttonLinkStyles}
`;
