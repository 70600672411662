import React from 'react';
import styled from 'styled-components';

const Text = ({item, domOrder, ranked}) => {

    const text = (ranked)
        ? `[${domOrder+1}] ${item.value}`
        : `${item.value}`

    return (
        <TextStyled className='Text' dangerouslySetInnerHTML={{ __html: text }} />
    );
}

export default Text;

const TextStyled = styled.div`
    text-overflow: ellipsis;
    max-width: calc(100%);
    overflow: hidden;
    white-space: nowrap;

    font-size: 12px;
`;