import styled from "styled-components";

// context
import { useSurvey } from "Pages/Auth/Survey/SurveyForm/SurveyContext";

// components
import { AccordionItem } from "./Accordion/index.jsx";

const Intro = () => {
    const {
        meta: { introduction },
    } = useSurvey();
    const { title, text } = introduction;

    return (
        <AccordionItem title={title} eventKey={0} id="introduction">
            <IntroStyled>
                {text && (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: text,
                        }}
                    />
                )}
            </IntroStyled>
        </AccordionItem>
    );
};

export default Intro;

const IntroStyled = styled.div``;
