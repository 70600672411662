import styled from "styled-components";

/* ClientDetailsContext ---------------------------*/
import { useClientDetailsContext } from "Pages/Auth/ControlPanel/ClientDetails/ClientDetailsContext/index.js";

// components
import UniversalForm from "Pages/Auth/ControlPanel/ClientDetails/UniversalForm/index.jsx";
import ControlWithLabel from "Common/BootStrap/FormControls/ControlWithLabel";
import ImageUpload from "Common/BootStrap/FormControls/ImageUpload";

const Logo = () => {
    const {
        useFileImage,
        config: { roleId, clientProfile, isLoading },
    } = useClientDetailsContext();

    const logo = useFileImage({
        id: "logo",
        base64Original: clientProfile?.image,
    });

    if (isLoading) {
        return <div>{isLoading}</div>;
    }

    return (
        <LogoStyled>
            <UniversalForm>
                <div className="mb-3">
                    <ControlWithLabel label="Logo" id="logo">
                        <ImageUpload
                            id="logo"
                            imageInputObj={logo.imageInputObj}
                            onChange={logo.onChange}
                        />
                    </ControlWithLabel>
                </div>
            </UniversalForm>
        </LogoStyled>
    );
};

export default Logo;

const LogoStyled = styled.div``;
