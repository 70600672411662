import styled from "styled-components";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

/* Styles ---------------------------*/
import { buttonLinkStyles } from "./shared";

const TakeSurvey = ({ surveyToken }) => {
    return (
        <TakeSurveyStyled
            className="btn btn-primary"
            title="Take Survey"
            href={`/auth/survey/${surveyToken}`}
        >
            <FontAwesomeIcon icon={faPenToSquare} />
        </TakeSurveyStyled>
    );
};

export default TakeSurvey;

// prop-types
TakeSurvey.propTypes = {
    surveyToken: PropTypes.string.isRequired,
};

const TakeSurveyStyled = styled.a`
    ${buttonLinkStyles}
`;
