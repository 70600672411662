import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { apiCall } from "Axios/apiCall";

// Helpers
import { commaSep } from "Common/utils";
import {
    isNegativeHelper,
    isPositiveHelper,
} from "Pages/Auth/Legacy/helper/positive-negative";

// Components
import ScalingIFrame from "Common/ScalingIFrame.jsx";
import Legend from "./Legend";

/* Hooks ---------------------------*/
import { useAuth } from "Auth0/useAuth.js";

const ResultsLegacy = () => {
    const { userProfile } = useAuth();
    const { surveyId, surveySubmissionId, createdBy, userId, clientId } =
        useParams();

    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState({});
    const [SurveyDetails, setSurveyDetails] = useState({});
    const [createdByUserId, setCreatedByUserId] = useState("");
    const [categoryWithHighConfidence, setcategoryWithHighConfidence] =
        useState([]);
    const [categoryWithLowConfidence, setCategoryWithLowConfidence] = useState(
        []
    );
    const [highestImportantStrength, setHighestImportantStrength] = useState(
        {}
    );
    const [highestImportantWeakness, setHighestImportantWeakness] = useState(
        {}
    );
    const [highestConfident, setHighestConfident] = useState("");
    const [lowestConfident, setLowestConfident] = useState("");
    const [categoryNotRatedOne, setCategoryNotRatedOne] = useState([]);
    const [surveyUserId, setSurveyUserId] = useState("");
    const [
        categoryRatedOneInRelationToStrengthWithHighestImportance,
        setCategoryRatedOneInRelationToStrengthWithHighestImportance,
    ] = useState([]);
    const [
        categoryRatedOneInRelationToWeaknessWithHighestImportance,
        setCategoryRatedOneInRelationToWeaknessthWithHighestImportance,
    ] = useState([]);
    const [
        categoryRatedOneInRelationToWeakness,
        setCategoryRatedOneInRelationToWeakness,
    ] = useState([]);
    const [
        categoryRatedOneInRelationToStrength,
        setCategoryRatedOneInRelationToStrength,
    ] = useState([]);

    const [
        categoryRatedLowInRelationToStrength,
        setCategoryRatedLowInRelationToStrength,
    ] = useState([]);
    const [
        categoryRatedLowInRelationToWeakness,
        setCategoryRatedLowInRelationToWeakness,
    ] = useState("");

    const [
        importantButWithLessConfidentBy11PercentAndGreaterThan60,
        setImportantButWithLessConfidentBy11PercentAndGreaterThan60,
    ] = useState([]);
    const [
        importantButWithLessConfidentBy11PercentAndLessOrEqualTo60,
        setImportantButWithLessConfidentBy11PercentAndLessOrEqualTo60,
    ] = useState([]);

    let createdByUser = createdBy;
    const callBack = async (createdByUser) => {
        setCreatedByUserId(createdByUser);

        // Get Test Taker User
        const userRes = await apiCall("get", `/auth/users/${createdByUser}`);
        setUserDetails(userRes);

        // Get test Takers Survey
        const surveyRes = await apiCall(
            "get",
            `/auth/users/survey-results/${surveySubmissionId}/${surveyId}`
        );

        const confident = surveyRes.confident;
        const important = surveyRes.important;
        const strength = surveyRes.strength;
        const weakness = surveyRes.weakness;
        // const domain = surveyRes.domain;
        // const domainCopy = domain.filter((item, i) => i !== 0);
        // const domainName = domainCopy.map((item, i) => item.domain);

        // const highestDomain = domain[domain.length - 1];
        // const highestImportant = important[important.length - 1];
        const highestConfident = confident[confident.length - 1];
        const lowestConfident = confident[0];
        // const lowDomain = domain[0];
        const domainWithHightConfident = strength.filter(
            (item) =>
                isPositiveHelper(item.option) &&
                confident.some(
                    (confItem) =>
                        confItem.domainQuestionsId === item.domainQuestionsId &&
                        parseInt(confItem.percentage) >= 50
                )
        );
        const domainWithLowtConfident = strength.filter(
            (item) =>
                isPositiveHelper(item.option) &&
                confident.some(
                    (confItem) =>
                        confItem.domainQuestionsId === item.domainQuestionsId &&
                        parseInt(confItem.percentage) < 50
                )
        );
        const categoryNotRatedOneOrFive = strength.filter(
            (item) =>
                !isPositiveHelper(item.option) && !isNegativeHelper(item.option)
        );
        setCategoryNotRatedOne([
            ...new Set(
                categoryNotRatedOneOrFive.map(
                    (item) => item.domainsQuestions.domain
                )
            ),
        ]);
        const questionRatedFive = strength.filter(
            (item) => item.option && isNegativeHelper(item.option)
        );
        const questionRatedFiveWeakness = weakness.find(
            (item) => item.option && isNegativeHelper(item.option)
        );
        const questionRatedOne = strength.filter(
            (item) => item.option && isPositiveHelper(item.option)
        );
        const weaknessRatedOne = weakness.filter(
            (item) => item.option && isPositiveHelper(item.option)
        );
        const weaknessRatedOneWithHighestImportance = weakness.filter(
            (item) =>
                item.option &&
                isPositiveHelper(item.option) &&
                important.some(
                    (imporItem) =>
                        imporItem.domainQuestionsId ===
                            item.domainQuestionsId &&
                        parseInt(imporItem.scale) > 50
                )
        );
        if (questionRatedFive.length) {
            setCategoryRatedLowInRelationToStrength([
                ...new Set(
                    questionRatedFive.map(
                        (item) => item.domainsQuestions.domain
                    )
                ),
            ]);
        }
        if (questionRatedFiveWeakness) {
            setCategoryRatedLowInRelationToWeakness(
                questionRatedFiveWeakness.domainsQuestions.domain
            );
        }
        if (questionRatedOne.length) {
            const imp = important.filter((item) =>
                questionRatedOne.some(
                    (qItem) =>
                        qItem.domainQuestionsId === item.domainQuestionsId
                )
            );
            const highestImportant = imp.sort(
                (a, b) => parseInt(b.scale) - parseInt(a.scale)
            )[0];
            setHighestImportantStrength(highestImportant);

            setCategoryRatedOneInRelationToStrengthWithHighestImportance([
                ...new Set(
                    questionRatedOne.map((item) => item.domainsQuestions.domain)
                ),
            ]);
        }

        if (weaknessRatedOneWithHighestImportance.length) {
            setCategoryRatedOneInRelationToWeaknessthWithHighestImportance([
                ...new Set(
                    weaknessRatedOneWithHighestImportance.map(
                        (item) => item.domainsQuestions.domain
                    )
                ),
            ]);
        }
        if (weaknessRatedOne.length) {
            const imp = important.filter((item) =>
                weaknessRatedOne.some(
                    (qItem) =>
                        qItem.domainQuestionsId === item.domainQuestionsId
                )
            );
            const highestImportant = imp.sort(
                (a, b) => parseInt(b.scale) - parseInt(a.scale)
            )[0];
            setHighestImportantWeakness(highestImportant);
            setCategoryRatedOneInRelationToWeakness([
                ...new Set(
                    weaknessRatedOne.map((item) => item.domainsQuestions.domain)
                ),
            ]);
        }
        if (questionRatedOne.length) {
            setCategoryRatedOneInRelationToStrength([
                ...new Set(
                    questionRatedOne.map((item) => item.domainsQuestions.domain)
                ),
            ]);
        }

        setHighestConfident(
            highestConfident && parseInt(highestConfident.percentage) > 50
                ? highestConfident.domainsQuestions.domain
                : ""
        );
        setLowestConfident(
            lowestConfident ? lowestConfident.domainsQuestions.domain : ""
        );

        setcategoryWithHighConfidence([
            ...new Set(
                domainWithHightConfident.map(
                    (item) => item.domainsQuestions.domain
                )
            ),
        ]);
        setCategoryWithLowConfidence([
            ...new Set(
                domainWithLowtConfident.map(
                    (item) => item.domainsQuestions.domain
                )
            ),
        ]);

        const importantButWithLessConfidentBy11PercentAndGreaterThan60Cop =
            important.filter((item) =>
                confident.some(
                    (confItem) =>
                        confItem.domainQuestionsId === item.domainQuestionsId &&
                        parseInt(confItem.percentage) < parseInt(item.scale) &&
                        LowerBy11PercentAndGreaterThan60(
                            confItem.percentage,
                            item.scale
                        )
                )
            );
        setImportantButWithLessConfidentBy11PercentAndGreaterThan60(
            importantButWithLessConfidentBy11PercentAndGreaterThan60Cop
        );

        const importantButWithLessConfidentBy11PercentAndLessOrEqualTo60Copy =
            important.filter((item) =>
                confident.some(
                    (confItem) =>
                        confItem.domainQuestionsId === item.domainQuestionsId &&
                        parseInt(confItem.percentage) < parseInt(item.scale) &&
                        LowerBy11PercentAndLessOrEqualTo60(
                            confItem.percentage,
                            item.scale
                        )
                )
            );
        setImportantButWithLessConfidentBy11PercentAndLessOrEqualTo60(
            importantButWithLessConfidentBy11PercentAndLessOrEqualTo60Copy
        );

        setSurveyDetails(surveyRes);
    };

    const LowerBy11PercentAndGreaterThan60 = (percent, scale) => {
        return (
            parseInt(scale) > parseInt(percent) &&
            parseInt(scale) > 60 &&
            parseInt(scale) - parseInt(percent) >= 11
        );
    };
    const LowerBy11PercentAndLessOrEqualTo60 = (percent, scale) => {
        return (
            parseInt(scale) > parseInt(percent) &&
            parseInt(scale) <= 60 &&
            parseInt(scale) - parseInt(percent) >= 11
        );
    };

    const onLoad = async () => {
        // admin is blocked from viewing assessment results
        // Uncomment this if you want admin not to see the result page
        // if (userProfile.roleId === 1) {
        //     navigate('/auth/client-list/?admin=cannotSeeAssessmentResults');
        // }
        // Preventing Regular users from seeing other users's survays
        const clientID = clientId ? clientId : userProfile.client.id;
        if (
            userProfile.roleId === 3 &&
            parseInt(createdBy) !== userProfile.id
        ) {
            navigate("/auth/cp");
        }
        // Preventing Client from seeing other users's survays that dose not belong to them
        if (userProfile.roleId === 2) {
            const surveyList = await apiCall(
                "get",
                `/auth/users/${userProfile.id}/assesment-taker-survey-list`
            );
            const subClientId =
                surveyList && surveyList.length > 0
                    ? surveyList[0].userId
                    : null;
            if (subClientId && parseInt(clientID) !== parseInt(subClientId)) {
                navigate("/auth/cp");
            }
        }
        if (userId) {
            setSurveyUserId(userId);
        }
        if (!createdByUser) {
            const surveySubmitRes = await apiCall(
                "get",
                `/users/${surveySubmissionId}/survey-submission`
            );
            setSurveyUserId(userId ?? surveySubmitRes.surveyUserId);
            callBack(surveySubmitRes.createdBy);
        } else {
            callBack(createdByUser);
        }
    };

    useEffect(() => {
        onLoad();
    }, []);

    const getPercentage = (c, domainQuestionsId) => {
        return c.find((item) => item.domainQuestionsId === domainQuestionsId)
            .percentage;
    };
    const getScale = (i, domainQuestionsId) => {
        return i.find((item) => item.domainQuestionsId === domainQuestionsId)
            .scale;
    };
    const getStrengthWeakness = (selfDeclaredStrength) => {
        return (
            selfDeclaredStrength.find((item) => item.question.id === 2)
                ?.value ?? ""
        );
    };

    return (
        <ResultsLegacyStyled className="ResultsLegacy inset">
            <div className="strength-section">
                <h2>Strength Report: LEGACY</h2>

                <Legend />
                <ScalingIFrame
                    src={`/auth/bare/survey/results-diagram-legacy/strength/${
                        clientId ? clientId : userProfile.client.id
                    }/${createdByUserId}/${surveySubmissionId}/${surveyUserId}/${surveyId}`}
                />

                {SurveyDetails.selfDeclaredStrength && (
                    <p>
                        You see yourself as someone whose strength is:{" "}
                        {getStrengthWeakness(
                            SurveyDetails.selfDeclaredStrength
                        )}
                    </p>
                )}
                {!!categoryWithHighConfidence.length && (
                    <p>
                        You believe your strength helps you successfully engage
                        with areas of {commaSep(categoryWithHighConfidence)}
                    </p>
                )}
                {!!categoryWithLowConfidence.length && (
                    <p>
                        Your strength still helps you with{" "}
                        {commaSep(categoryWithLowConfidence)}, but you lack
                        confidence here.
                    </p>
                )}
                {/* { !!categoryNotRatedOne.length && (
                    <p>Your strength does not seem to relate to {commaSep(categoryNotRatedOne)}. </p>
                )} */}
                {!!categoryRatedLowInRelationToStrength.length &&
                    SurveyDetails.selfDeclaredStrength && (
                        <p>
                            Your strength (
                            {`${getStrengthWeakness(
                                SurveyDetails.selfDeclaredStrength
                            )}`}
                            ) means you are not likely to succeed in areas
                            related to{" "}
                            {commaSep(categoryRatedLowInRelationToStrength)}.
                        </p>
                    )}
                {!!categoryRatedOneInRelationToStrength.length &&
                    highestImportantStrength && (
                        <p>
                            Considering areas most related to your strength:{" "}
                            {commaSep(categoryRatedOneInRelationToStrength)}.
                            The most important to you is:{" "}
                            {commaSep([
                                highestImportantStrength.domainsQuestions
                                    .domain,
                            ])}
                        </p>
                    )}
            </div>

            <div className="divider"></div>

            <div className="inset">
                <h2>Detailed Report:</h2>
                <p>
                    Important situations require confidence. If there were
                    situations that were important to you where you have less
                    confidence, they will appear in this section. Being aware of
                    these can help you to improve in the future.
                </p>
                <br />
                <p>
                    If no situations appear here, your confidence at least
                    matches the level of importance for all the situations
                    questioned today.
                </p>
            </div>
            {importantButWithLessConfidentBy11PercentAndGreaterThan60.length ? (
                <>
                    <div className="margin-top inset">
                        You can work to improve your confidence in these
                        situations:
                    </div>
                    <ul className="margin">
                        <div className="list-item">
                            {importantButWithLessConfidentBy11PercentAndGreaterThan60.map(
                                (item, i) => {
                                    return (
                                        <div
                                            className="detailed-report-container"
                                            key={i}
                                        >
                                            <li>
                                                {item.domainsQuestions
                                                    ? item.domainsQuestions
                                                          .question
                                                    : ""}
                                            </li>
                                            <ScalingIFrame
                                                src={`/auth/bare/survey/results-inline/${getPercentage(
                                                    SurveyDetails.confident,
                                                    item.domainQuestionsId
                                                )}/${getScale(
                                                    SurveyDetails.important,
                                                    item.domainQuestionsId
                                                )}`}
                                                aspectRatio="9/4.5"
                                            />
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </ul>
                </>
            ) : (
                ""
            )}
            {importantButWithLessConfidentBy11PercentAndLessOrEqualTo60.length ? (
                <>
                    <div className="margin-top inset">
                        You can also improve your confidence in these areas, but
                        they were not as important to you:
                    </div>
                    <ul className="margin">
                        <div className="list-item">
                            {importantButWithLessConfidentBy11PercentAndLessOrEqualTo60.map(
                                (item, i) => {
                                    return (
                                        <div
                                            className="detailed-report-container"
                                            key={i}
                                        >
                                            <li>
                                                {item.domainsQuestions
                                                    ? item.domainsQuestions
                                                          .question
                                                    : ""}
                                            </li>
                                            <ScalingIFrame
                                                src={`/auth/bare/survey/results-inline/${getPercentage(
                                                    SurveyDetails.confident,
                                                    item.domainQuestionsId
                                                )}/${getScale(
                                                    SurveyDetails.important,
                                                    item.domainQuestionsId
                                                )}`}
                                                aspectRatio="9/4.5"
                                            />
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </ul>
                </>
            ) : (
                ""
            )}
            <div className="divider"></div>

            <div className="weakness-section">
                <h2>Weakness Report:</h2>

                <Legend />
                <ScalingIFrame
                    src={`/auth/bare/survey/results-diagram-legacy/weakness/${
                        clientId ? clientId : userProfile.client.id
                    }/${createdByUserId}/${surveySubmissionId}/${surveyUserId}/${surveyId}`}
                />

                {SurveyDetails.selfDeclaredWeakness && (
                    <p>
                        You see your weakness as:{" "}
                        {`${getStrengthWeakness(
                            SurveyDetails.selfDeclaredWeakness
                        )}`}
                    </p>
                )}
                {!!categoryRatedOneInRelationToWeakness.length && (
                    <p>
                        However, you believe this means you are still more
                        likely to engage with your{" "}
                        {commaSep(categoryRatedOneInRelationToWeakness)}.
                    </p>
                )}
                {!!categoryRatedOneInRelationToWeakness.length && (
                    <p>
                        You are less confident you will be successful when
                        engaging with your{" "}
                        {commaSep(categoryRatedOneInRelationToWeakness)}.
                    </p>
                )}
                {/* { !!categoryNotRatedOne.length && (
                    <p>Your weakness does not seem to relate to: {commaSep(categoryNotRatedOne)}. </p>
                )} */}
                {!!categoryRatedLowInRelationToWeakness &&
                    SurveyDetails.selfDeclaredWeakness && (
                        <p>
                            Your weakness (
                            {`${getStrengthWeakness(
                                SurveyDetails.selfDeclaredWeakness
                            )}`}
                            ) means you are not likely to successfully do things
                            related to your{" "}
                            {categoryRatedLowInRelationToWeakness}. However, you
                            are still fairly confident you could do these
                            things.{" "}
                        </p>
                    )}
                {!!categoryRatedOneInRelationToWeakness.length &&
                categoryRatedOneInRelationToWeaknessWithHighestImportance.length ? (
                    <p>
                        Considering areas most related to your weakness:{" "}
                        {commaSep(categoryRatedOneInRelationToWeakness)}.
                        {highestImportantWeakness.domainsQuestions.domain ? (
                            <>
                                The most important to you is:{" "}
                                {commaSep([
                                    highestImportantWeakness.domainsQuestions
                                        .domain,
                                ])}
                            </>
                        ) : (
                            ""
                        )}
                    </p>
                ) : (
                    ""
                )}
            </div>
        </ResultsLegacyStyled>
    );
};

export default ResultsLegacy;

const ResultsLegacyStyled = styled.div`
    .inset {
        max-width: 700px;
    }

    h2 {
        text-align: center;
        margin-bottom: 40px;
    }

    p {
        margin-bottom: 0px;
        /* text-align: center; */
    }

    div.strength-section {
        margin-top: 20px;
    }

    div.list-item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0;
        margin-top: 25px;
        text-align: left;
        flex-direction: column;
    }
    div.list-item .detailed-report-container {
        width: 40%;
        margin: 0 auto;
    }
    div.list-item .detailed-report-container li {
        margin-bottom: 15px;
        margin-top: 25px;
    }
    .main {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0px;
    }

    .main svg {
        overflow: initial;
    }

    .main .right {
        .c-color,
        .i-color {
            font-weight: 600;
        }
        .c-color {
            color: rgba(31, 124, 102, 1);
        }
        .i-color {
            color: rgba(37, 113, 187, 1);
        }
    }
    .circle-one {
        width: 120px;
        margin-top: 20px;
    }
    .circle-two {
        width: 65px;
        margin-left: -92px;
        margin-bottom: -17px;
    }

    circle[id^="c"],
    circle[id^="i"] {
        stroke-width: 4;
        fill: none;
        stroke-dasharray: 0 100;
        stroke-dashoffset: -200;
        transition: stroke-dasharray 3s linear;
    }
    div.divider {
        width: 100%;
        height: 20px;
        background-color: #42bdef;
        margin: 40px auto 20px auto;
    }
    .weakness-section p,
    .strength-section p {
        text-align: left;
    }

    ul.margin {
        margin: 20px 0px;
    }
    .margin-top {
        margin-top: 20px;
    }
    p.center {
        align-items: center;
    }
`;
