import React from 'react';
import styled from 'styled-components';

import CircleOfDestiny from './CircleOfDestiny/CircleOfDestiny.jsx';

const ConfidenceCircle = ({ percentage, color="#1f7d67" }) => {
    return (
        <ConfidenceCircleStyled className="ConfidenceCircle">
            <CircleOfDestiny
                percentage={percentage}
                fill="#fff"
                color={ color }
            />
        </ConfidenceCircleStyled>
    );
};

export default ConfidenceCircle;

const ConfidenceCircleStyled = styled.div``;
