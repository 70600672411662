import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

/* Components ---------------------------*/
import CommonResultsInline from "Common/Results/ResultsInline";

const ResultsInline = () => {
    const { confPercent, impPercent } = useParams();

    return (
        <ResultsInlineStyled className="ResultsInline">
            <CommonResultsInline
                confPercent={confPercent}
                impPercent={impPercent}
            />
        </ResultsInlineStyled>
    );
};

export default ResultsInline;

const ResultsInlineStyled = styled.div``;
