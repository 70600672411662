import styled from "styled-components";

/* ClientDetailsContext ---------------------------*/
import { useClientDetailsContext } from "Pages/Auth/ControlPanel/ClientDetails/ClientDetailsContext/index.js";

// components
import UniversalForm from "Pages/Auth/ControlPanel/ClientDetails/UniversalForm/index.jsx";
import ControlWithLabel from "Common/BootStrap/FormControls/ControlWithLabel";
import Input from "Common/BootStrap/FormControls/Input";
import Checkbox from "Common/BootStrap/FormControls/Checkbox";

const AccountLimits = () => {
    const {
        useInput,
        useCheckbox,
        config: { roleId, clientProfile, isLoading },
    } = useClientDetailsContext();

    // Controlled Inputs
    const userLimit = useInput({
        id: "user_limit",
        value: clientProfile?.user_limit,
    });
    const status = useCheckbox({
        id: "status",
        isChecked: !!clientProfile?.status,
    });

    if (isLoading) {
        return <div>{isLoading}</div>;
    }

    return (
        <AccountLimitsStyled>
            <UniversalForm>
                <div className="mb-3">
                    <ControlWithLabel label="User Limit" id="user_limit">
                        <Input
                            id="user_limit"
                            placeholder="Enter User Limit"
                            value={userLimit.value}
                            onChange={userLimit.onChange}
                            disabled={roleId !== 1}
                        />
                    </ControlWithLabel>
                </div>
                <div className="mb-3">
                    <ControlWithLabel label="Account Status" id="status">
                        <Checkbox
                            id="status"
                            isChecked={status.isChecked}
                            onChange={status.onChange}
                            textIfChecked="Account is Active"
                            textIfNotChecked="Account Deactivated"
                        />
                    </ControlWithLabel>
                </div>
            </UniversalForm>
        </AccountLimitsStyled>
    );
};

export default AccountLimits;

const AccountLimitsStyled = styled.div``;
