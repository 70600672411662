import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

/* Redux ---------------------------*/
import { useGetClientListQuery } from "Redux/apiAuthSlice.js";

/* Hooks ---------------------------*/
import { useAuth } from "Auth0/useAuth.js";

/* Components ---------------------------*/
import Preloader from "Common/Preloader.jsx";

const ClientList = () => {
    let navigate = useNavigate();
    const { userProfile } = useAuth();

    // Lets make sure we have the user's profile.
    const { data: clientList, isLoading } = useGetClientListQuery();
    const [status, updateStatus] = useState("active");

    if (isLoading) {
        return <Preloader />;
    }

    if (userProfile.roleId !== 1) {
        navigate("/auth/cp");
    }

    return (
        <ClientListStyled className="ClientList inset">
            <div className="header-container">
                <h1>Client List</h1>
                <div className="toggle">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="showAllClientList"
                            id="showAllClientList"
                            value="inActive"
                            onChange={(e) => updateStatus(e.target.value)}
                            checked={status === "inActive"}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="showAllClientList"
                        >
                            Show InActive Client
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="showActiveClientList"
                            id="showActiveClientList"
                            value="active"
                            onChange={(e) => updateStatus(e.target.value)}
                            checked={status === "active"}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="showActiveClientList"
                        >
                            Show Active Client
                        </label>
                    </div>
                </div>
            </div>

            {clientList.length ? (
                <div className="row">
                    {clientList
                        .filter((item) => item.status === status)
                        .map((client) => {
                            const organization =
                                client.organization ||
                                `${client.first_name} ${client.last_name}`;

                            return (
                                <div key={client.id} className="col-sm-4 mb-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">
                                                {organization}
                                            </h4>
                                            <div className="card-text">
                                                <div>
                                                    <b>Client ID:</b>{" "}
                                                    {`${client.id}`}
                                                </div>
                                                <div>
                                                    <b>Name:</b>{" "}
                                                    {`${client.first_name} ${client.last_name}`}
                                                </div>
                                                <div>
                                                    <b>Email:</b> {client.email}
                                                </div>
                                                <div>
                                                    <b>Status:</b>{" "}
                                                    {client.status}
                                                </div>
                                                <div>
                                                    <b>User Limit:</b>{" "}
                                                    {client.user_limit}
                                                </div>
                                            </div>
                                            <a
                                                href={
                                                    "/auth/cp/admin/client/" +
                                                    client.id
                                                }
                                                className="btn btn-secondary manage-link"
                                            >
                                                Manage Client
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            ) : (
                <h4>Client list is empty. Add now</h4>
            )}
        </ClientListStyled>
    );
};

export default ClientList;

const ClientListStyled = styled.div`
    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .header-container input {
        cursor: pointer;
    }

    .row {
        .btn {
            background-color: #0a4643;
            color: white;
            font-weight: bold;

            &:hover {
                color: red;
                background-color: #fff;
            }
        }

        .card {
            min-height: 275px;
        }

        .card-text {
            text-transform: capitalize;
            margin-bottom: 30px;

            > div {
                margin-bottom: 4px;
            }
        }
    }
`;
