import styled from "styled-components";
import PropTypes from "prop-types";

const UserID = ({ submittedBy }) => {
    return (
        <UserIDStyled>
            <b>UID:</b> {submittedBy.id}
        </UserIDStyled>
    );
};

export default UserID;

// prop-types
UserID.propTypes = {
    submittedBy: PropTypes.object.isRequired,
};

const UserIDStyled = styled.div`
    margin-right: 8px;
`;
