import styled from "styled-components";
import PropTypes from "prop-types";

// components
import Question from "./Question";

const QuestionList = ({ sNumber, sKey, questions }) => {
    return (
        <QuestionListStyled>
            {questions.map((q, idx) => {
                const { id } = q;

                const decQuestion = {
                    ...q,
                    sNumber,
                    sKey,
                };

                return (
                    <Question
                        key={id}
                        number={idx + 1}
                        decQuestion={decQuestion}
                    />
                );
            })}
        </QuestionListStyled>
    );
};

export default QuestionList;

// prop-types
QuestionList.propTypes = {
    sNumber: PropTypes.number.isRequired,
    sKey: PropTypes.string.isRequired,
    questions: PropTypes.array.isRequired,
};

const QuestionListStyled = styled.div``;
