import styled from "styled-components";
import PropTypes from "prop-types";

// components
import TakeSurvey from "Pages/Auth/Common/TakeSurvey.jsx";
import ExportCSVButton from "Pages/Auth/Common/ExportCSVButton";

const BodyOpenerActions = ({ survey, roleId }) => {
    if (roleId === 3) return null;
    const { token } = survey;
    return (
        <BodyOpenerActionsStyled>
            <TakeSurvey surveyToken={token} />
            <ExportCSVButton survey={survey} />
        </BodyOpenerActionsStyled>
    );
};

export default BodyOpenerActions;

// prop-types
BodyOpenerActions.propTypes = {
    survey: PropTypes.object.isRequired,
    roleId: PropTypes.number.isRequired,
};

const BodyOpenerActionsStyled = styled.div`
    text-align: right;
`;
