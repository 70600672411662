import styled from "styled-components";
import PropTypes from "prop-types";

/* ClientDetailsContext ---------------------------*/
import { useClientDetailsContext } from "Pages/Auth/ControlPanel/ClientDetails/ClientDetailsContext/index.js";

// components
import SurveyActions from "./SurveyActions";
import AdminActions from "./AdminActions/index";

const Row = ({ count, survey }) => {
    const {
        useInput,
        useCheckbox,
        config: { roleId, clientProfile, isLoading },
    } = useClientDetailsContext();

    const { id: surveyId, token: surveyToken, name } = survey;

    return (
        <RowStyled>
            <td>{count}</td>
            <td>{name}</td>
            <td>
                {surveyId} / {surveyToken}
            </td>
            {roleId === 1 && (
                <td>
                    <AdminActions survey={survey} clientId={clientProfile.id} />
                </td>
            )}
            <td>
                <SurveyActions survey={survey} />
            </td>
        </RowStyled>
    );
};

export default Row;

// prop-types
Row.propTypes = {
    count: PropTypes.number.isRequired,
    survey: PropTypes.object.isRequired,
};

const RowStyled = styled.tr``;
