import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { apiCall } from "Axios/apiCall";

/* Hooks ---------------------------*/
import { useAuth } from "Auth0/useAuth.js";

/* Components ---------------------------*/
import CommonResultsDiagram from "Common/Results/ResultsDiagram";

import {
    isNegativeHelper,
    isPositiveHelper,
} from "Pages/Auth/Legacy/helper/positive-negative.js";

const ResultsDiagram = () => {
    const { userProfile } = useAuth();

    const {
        surveySubmissionId,
        createdBy,
        reportType,
        surveyUserId,
        clientId,
        surveyId,
    } = useParams();

    const navigate = useNavigate();

    const [resultsData, setResultsData] = useState({
        meta: {},
        domains: [],
    });

    const getConfident = (confident = [], domainQuestionsId) => {
        const confQuestion = confident.find((item) => {
            return item.domainQuestionsId === domainQuestionsId;
        });

        if (!confQuestion) {
            return 0;
        }

        const percentage = confQuestion.percentage;
        const percentageInteger = parseInt(percentage);
        console.log({ impPerc: percentageInteger });
        return percentageInteger;
    };
    const getImportant = (important = [], domainQuestionsId) => {
        const impQuestion = important.find((item) => {
            return item.domainQuestionsId === domainQuestionsId;
        });

        if (!impQuestion) {
            return 0;
        }

        const scale = impQuestion.scale;
        const scaleInteger = parseInt(scale);
        console.log({ impPerc: scaleInteger });
        return scaleInteger;
    };
    const getIconPath = (domain) => {
        switch (domain.toLowerCase()) {
            case "academics":
                return "/assets/icons/domains/client1/academics.svg";
            case "belonging":
                return "/assets/icons/domains/client1/belonging.svg";
            case "commuting":
                return "/assets/icons/domains/client1/commuting.svg";
            case "concentration":
                return "/assets/icons/domains/client1/concentration.svg";
            case "mental health":
                return "/assets/icons/domains/client1/mental-health.svg";
            case "family":
                return "/assets/icons/domains/client1/family.svg";
            case "finances":
                return "/assets/icons/domains/client1/finances.svg";
            case "physical health":
                return "/assets/icons/domains/client1/physical-health.svg";
        }
    };

    const getPercOptions = (
        domain,
        // strength and weakness: user input data through the eyes of the dropdown choices (Datatbase: DropDown Questions have many domain answers Finance, Academics)
        strength, // strength user choices
        weakness, // weakness user choices
        originalConfident, // confident user choices
        originalImportant // important user choices
    ) => {
        /**
         * Percentage Options Business Logic
         *
         *  Definitons:
         *
         *  - Percentage Options: For each domain, we can potentially show one or more Confidence and Importance percentages. only up to 2, positive and negative as related to the domain
         *  - Domain: Current report evaluating (e.g. Finances)
         *  - dropDownOptions: These are the drop down options the user could choose from for Confidence and Importance.
         *      They include any Domain Questions attached to each, assuming user chose that drop down option for a given Domain question
         *
         *  Steps:
         *
         *  0. Remove user supplied n/a identifed in importance, and applied to both confident and importance collections
         *  1. Determine which dropDownOptions (strength or weakness)
         *  2. Filter dropDownOptions to only those dropDownOptions chosen for the Domain (e.g. Finances) Questions
         *  3. Split and Filter by Top 2 Positive and Bottom 2 Negative (essentially ignoring Irellavant drop down option)
         *  4. Calculate Positive and Negative Confidence and Importance Mean Averages
         *      - Note: Yaseen used "percentage" for confidence and "scale" for importance. They do the same thing, adding unnecessary confusion
         *      a. Positive Confidence and Importance Mean Averages
         *          1. Confidence Average: Takes all positive questions and averages their values
         *          2. Importance Average: Takes all positive questions and averages their values
         *      b. Negative Confidence and Importance Mean Averages
         *          1. Confidence Average: Takes all negative questions and averages their values
         *          2. Importance Average: Takes all negative questions and averages their values
         *  5. Final Calculated Percentages Array/Collection
         *  6. Reformatting results into Diagram Specific Objects
         */

        /**
         * NOTE: The following is the preferred logic, even though it does not seem to be applied
         *
         * If importance is n/a
         *
         *  THEN that question (both confidence and importance) is not "included in the calculation" for the rings on the strength/weakness results.
         *  Which means if there are 3 items, one is N/A
         *      - the N/A gets thrown out of both confidence and importance averages
         *      - only if there is one or more items left, would something get calculated - which would then show Rings and Stem
         *
         * We need to remove any important questions flagged as N/A for both confidence and importance, even though confidence does not have an n/a. Importance drives the removal of these questions from any calculations
         */

        console.group(`getPercOptions: ${domain}: ${reportType} Report`);

        const questionsToOmitFromConfidence = [];

        const important = originalImportant.filter((i) => {
            // calculate against n/a
            console.log({ scale: i.scale });

            if (i.scale === "n/a") {
                console.log("Has n/a");
                questionsToOmitFromConfidence.push(i.domainQuestionsId);
                return false;
            }
            return i;
        });

        const confident = originalConfident.filter((c) => {
            // calculate against questionsToOmitFromConfidence
            return !questionsToOmitFromConfidence.includes(c.domainQuestionsId);
        });

        console.log({
            step: `0. ${reportType} remove n/a questions from confident and important`,
            originalConfident,
            originalImportant,
            questionsToOmitFromConfidence,
            confident,
            important,
        });

        // 1. Determnine which dropDownOptions (strength or weakness)
        const dropDownOptions = reportType === "strength" ? strength : weakness;
        console.log({
            step: `1. ${reportType} dropDownOptions`,
            dropDownOptions,
        });

        // 2. Filter dropDownOptions to only those dropDownOptions chosen for the Domain (e.g. Finances) Questions
        const dropDownOptionsByDomain = dropDownOptions.filter(
            (item) => item.domainsQuestions.domain === domain
        );
        console.log({
            step: `2. Filtered dropDownOptions by Domain ${domain}`,
            dropDownOptionsByDomain,
        });

        // 3. Split and Filter by Top 2 Positive and Bottom 2 Negative (essentially ignoring Irellavant drop down option)
        const dropDownOptionsByDomainPos = dropDownOptionsByDomain.filter(
            (item) => isPositiveHelper(item.option)
        );
        const dropDownOptionsByDomainNeg = dropDownOptionsByDomain.filter(
            (item) => isNegativeHelper(item.option)
        );
        console.log({
            step: `3. Split and Filter Positive and Negative`,
            positive: dropDownOptionsByDomainPos,
            negative: dropDownOptionsByDomainNeg,
        });

        // 4. Calculate Positive and Negative Confidence and Importance Mean Averages
        console.log({
            step: `4. Calculate Positive and Negative Confidence and Importance Mean Averages`,
        });

        let resArray = [];

        /*---------------------------
        | Positive
        ---------------------------*/
        console.group(`a. Positiive Confidence and Importance Mean Averages`);

        let obj = {};

        console.group(`1. Confidence Average`);

        let posConfAverage = null;

        if (dropDownOptionsByDomainPos.length) {
            let numbers = [];
            const tally = dropDownOptionsByDomainPos.reduce((acc, val) => {
                const c = parseInt(
                    parseInt(getConfident(confident, val.domainQuestionsId))
                );
                numbers.push(c);
                return acc + c;
            }, 0);

            posConfAverage = tally / dropDownOptionsByDomainPos.length;
            obj.confidence = posConfAverage;
            console.log({ numbers, tally, posConfAverage });
        }
        console.groupEnd();

        console.group(`2. Importance Average`);

        let posImpAverage = null;

        // const posImpFiltered = dropDownOptionsByDomainPos.filter((item) =>
        //     important.some(
        //         (IMP) =>
        //             item.domainQuestionsId === IMP.domainQuestionsId &&
        //             IMP.scale !== "n/a"
        //     )
        // );

        // console.log({
        //     step: `2.a Importance Filtered further where important.domainQuestionsId === ddOpt.domainQuestionsId and has been scaled in DB`,
        //     dropDownOptionsByDomainPos,
        //     posImpFiltered,
        // });

        if (dropDownOptionsByDomainPos.length) {
            let numbers = [];

            const tally = dropDownOptionsByDomainPos.reduce((acc, val) => {
                const i = parseInt(
                    getImportant(important, val.domainQuestionsId)
                );
                numbers.push(i);
                return acc + i;
            }, 0);

            posImpAverage = tally / dropDownOptionsByDomainPos.length;
            obj.importance = posImpAverage;
            console.log({ numbers, tally, posImpAverage });
        }

        console.groupEnd();

        if (Object.keys(obj).length) {
            obj.connection = 1;
            resArray.push(obj);
        }

        console.log({
            step: `3. Add Final Positive Object`,
            obj,
        });

        console.groupEnd();

        /*---------------------------
        | Negative
        ---------------------------*/
        console.group(`b. Negative Confidence and Importance Mean Averages`);

        obj = {};
        let negConfAverage = null;

        console.log({ dropDownOptionsByDomainNeg });

        if (dropDownOptionsByDomainNeg.length) {
            let numbers = [];
            const tally = dropDownOptionsByDomainNeg.reduce((acc, val) => {
                const c = parseInt(
                    parseInt(getConfident(confident, val.domainQuestionsId))
                );
                numbers.push(c);
                return acc + c;
            }, 0);

            negConfAverage = tally / dropDownOptionsByDomainNeg.length;
            obj.confidence = negConfAverage;
            console.log({ numbers, tally, negConfAverage });
        }

        console.log({
            step: `1. Confidence Average`,
            negConfAverage,
        });

        // const negImpFiltered = dropDownOptionsByDomainNeg.filter((item) =>
        //     important.some(
        //         (iItem) =>
        //             item.domainQuestionsId === iItem.domainQuestionsId &&
        //             iItem.scale !== "n/a"
        //     )
        // );

        // console.log({
        //     step: `2.a Importance Filtered further where important.domainQuestionsId === ddOpt.domainQuestionsId and has been scaled in DB`,
        //     dropDownOptionsByDomainPos,
        //     negImpFiltered,
        // });

        let negImpAverage = null;

        if (dropDownOptionsByDomainNeg.length) {
            let numbers = [];
            const tally = dropDownOptionsByDomainNeg.reduce((acc, val) => {
                const i = parseInt(
                    getImportant(important, val.domainQuestionsId)
                );
                numbers.push(i);
                return acc + i;
            }, 0);
            negImpAverage = tally / dropDownOptionsByDomainNeg.length;
            obj.importance = negImpAverage;
            console.log({ numbers, tally, negImpAverage });
        }

        console.log({
            step: `2.b Importance Average`,
            negImpAverage,
        });

        if (Object.keys(obj).length) {
            obj.connection = "-1";
            resArray.push(obj);
        }

        console.log({
            step: `3. Add Final Negative Object`,
            obj,
        });

        console.groupEnd();

        console.log({
            step: `5. Final Calculated Percentages Array/Collection`,
            resArray,
        });

        /*---------------------------
        | Positive and Negative Grooming
        ---------------------------*/
        const percOptions = resArray.map((element) => {
            const positive = element.connection === 1;
            return {
                confidence: element.confidence,
                importance: element.importance,
                showStem:
                    getConnection(domain, strength, weakness) === "positive" ||
                    getConnection(domain, strength, weakness) === "negative"
                        ? true
                        : false,
                stemColor: positive ? "#42BDEE" : "#eeeeee",
                titleBgColor: positive ? "#42BDEE" : "#eeeeee",
            };
        });

        console.log({
            step: `6. Reformatting results into Diagram Specific Objects`,
            percOptions,
        });

        console.groupEnd();

        return percOptions;
    };

    const getConnection = (domain, strength, weakness) => {
        const type = reportType === "strength" ? strength : weakness;
        const currentStrengthOrWeakness = type.filter(
            (item) => item.domainsQuestions.domain === domain
        );
        const dropDownOptionsByDomainPos = currentStrengthOrWeakness.filter(
            (item) => isPositiveHelper(item.option)
        );
        const dropDownOptionsByDomainNeg = currentStrengthOrWeakness.filter(
            (item) => isNegativeHelper(item.option)
        );
        // TODO: Handle when no option is returned
        if (dropDownOptionsByDomainPos.length) {
            return "positive";
        } else if (dropDownOptionsByDomainNeg.length) {
            return "negative";
        } else {
            return "noRelation";
        }
    };

    const getMessages = (type, domain, messages) => {
        const message = messages.filter(
            (item) => item.domain === domain && item.type === type
        );
        return message;
    };

    const getMessagesPerDomains = (
        domain,
        index,
        msgs,
        confident,
        important
    ) => {
        const messagesList = [];
        const genMessages = getMessages("GENERAL", domain, msgs);
        const highMessages = getMessages("HIGH", domain, msgs);
        const medMessages = getMessages("MEDIUM", domain, msgs);
        const lowMessages = getMessages("LOW", domain, msgs);
        if (genMessages.length) {
            genMessages.forEach((item) => {
                messagesList.push({
                    type: "GENERAL",
                    label: item.label,
                    link: item.link,
                });
            });
        }

        // find top domains by importance
        if (index <= 4) {
            const allQuestionsPerDomain = confident.filter(
                (item) => item.domainsQuestions.domain === domain
            );
            const average =
                allQuestionsPerDomain.reduce(
                    (a, b) => a + parseInt(b.percentage),
                    0
                ) / allQuestionsPerDomain.length;
            if (highMessages.length && average > 70) {
                highMessages.forEach((item) => {
                    messagesList.push({
                        type: "HIGH",
                        label: item.label,
                        link: item.link,
                    });
                });
            }
            if (medMessages.length && average >= 40 && average < 70) {
                medMessages.forEach((item) => {
                    messagesList.push({
                        type: "MEDIUM",
                        label: item.label,
                        link: item.link,
                    });
                });
            }
            if (lowMessages.length && average > 0 && average < 40) {
                lowMessages.forEach((item) => {
                    messagesList.push({
                        type: "LOW",
                        label: item.label,
                        link: item.link,
                    });
                });
            }
        }
        return messagesList;
    };

    const getSurveyResources = async () => {
        const userSurveyDomains = await apiCall(
            "get",
            `/auth/users/${
                clientId ? clientId : userProfile.client.id
            }/survey-domains/${surveyUserId}/survey`
        );
        const messges = [];

        userSurveyDomains.forEach((element) => {
            element.resources.forEach((ele) => {
                messges.push({
                    type: ele.type,
                    label: ele.label,
                    link: ele.link,
                    domain: element.domain,
                });
            });
        });
        getSurveyDetails(messges);
    };

    const getSurveyDetails = async (msgs) => {
        const userSurvey = await apiCall(
            "get",
            `/auth/users/survey-results/${surveySubmissionId}/${surveyId}`
        );

        const {
            confident,
            important,
            domain: domainData,
            weakness,
            strength,
            selfDeclaredWeakness,
            selfDeclaredStrength,
        } = userSurvey;

        console.log({ userSurvey });

        const filterSelfDeclaredWeakness = selfDeclaredWeakness.find(
            (item) => item.question.id === 2
        );
        const filterSelfDeclaredStrength = selfDeclaredStrength.find(
            (item) => item.question.id === 2
        );

        const domains = domainData.map((item, index) => ({
            id: item.id,
            title: item.domain,
            icon: getIconPath(item.domain),
            positiveOrNegativeConnection: getConnection(
                item.domain,
                strength,
                weakness
            ),
            percents: {
                currPercOptionIdx: 0,
                percOptions: getPercOptions(
                    item.domain,
                    strength,
                    weakness,
                    confident,
                    important
                ),
            },
            popUp: {
                show: false,
                currentMessage: 0,
                messages: getMessagesPerDomains(
                    item.domain,
                    index,
                    msgs,
                    confident,
                    important
                ),
            },
        }));

        const results = {
            meta: {
                title:
                    reportType === "strength"
                        ? "Your Strength"
                        : "Your Weakness",
                userChosen:
                    reportType === "strength"
                        ? filterSelfDeclaredStrength.value
                        : filterSelfDeclaredWeakness.value,
            },
            domains,
        };

        console.log({ results });

        setResultsData(results);
    };

    useEffect(() => {
        // Uncomment this if you want to prevent admin from seeing this page
        // if (userProfile.roleId === 1) {
        //     navigate('/')
        // }
        getSurveyResources();
    }, [userProfile]);

    return (
        <ResultsDiagramStyled className="ResultsDiagram">
            {resultsData.domains.length > 0 && (
                <CommonResultsDiagram results={resultsData} />
            )}
        </ResultsDiagramStyled>
    );
};

export default ResultsDiagram;

const ResultsDiagramStyled = styled.div``;
