import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

/* Redux ---------------------------*/
import { useGetSurveyResultsQuery } from "Redux/apiAuthSurveySlice";

// components
import Preloader from "Common/Preloader.jsx";
import Legend from "./Legend";
import OpeningText from "./OpeningText";
import Divider from "./Common/Divider";
import ResultsDiagramWrapper from "./ResultsDiagramWrapper";
import FooterMessages from "./FooterMessages";
import DetailedReport from "./DetailedReport";

const Results = () => {
    // /survey/results/:surveyId/:surveySubmissionId
    const { surveyId, surveySubmissionId } = useParams();

    // Redux
    const { data: surveyResults, isLoading } = useGetSurveyResultsQuery({
        surveyId,
        surveySubmissionId,
    });

    if (isLoading) {
        return <Preloader />;
    }

    const {
        meta,
        diagramReports: { overall, strength, weakness },
        detailedReport,
    } = surveyResults;

    return (
        <ResultsStyled className="Results inset">
            <h1>Survey Results</h1>

            <OpeningText text={meta.openingText} />

            {/* YOUR RESULTS DIAGRAM */}
            <h2>{overall.meta.title}</h2>
            <Legend legend={overall.legend} />
            <ResultsDiagramWrapper
                surveyId={surveyId}
                surveySubmissionId={surveySubmissionId}
                reportType={"overall"}
            />
            <Divider />

            {/* DETAILED REPORT */}
            <DetailedReport detailedReport={detailedReport} />
            <Divider />

            {/* STRENGTH DIAGRAM */}
            <h2>{strength.meta.title} Report</h2>
            <Legend legend={strength.legend} />
            <ResultsDiagramWrapper
                surveyId={surveyId}
                surveySubmissionId={surveySubmissionId}
                reportType={"strength"}
            />
            <FooterMessages messages={strength.footerMessages} />
            <Divider />

            {/* WEAKNESS DIAGRAM */}
            <h2>{weakness.meta.title} Report</h2>
            <Legend legend={weakness.legend} />
            <ResultsDiagramWrapper
                surveyId={surveyId}
                surveySubmissionId={surveySubmissionId}
                reportType={"weakness"}
            />
            <FooterMessages messages={weakness.footerMessages} />
        </ResultsStyled>
    );
};

export default Results;

const ResultsStyled = styled.div`
    h1 {
        font-size: 60px;
        margin-bottom: 50px;
    }
`;
