import React from "react";
import styled from "styled-components";

/* Components ---------------------------*/
import CommonResultsInline from "Common/Results/ResultsInline";

const ResultsInline = () => {
    return (
        <ResultsInlineStyled className="ResultsInline">
            <h2>Results Inline Example</h2>
            <CommonResultsInline confPercent={100} impPercent={53} />
        </ResultsInlineStyled>
    );
};

export default ResultsInline;

const ResultsInlineStyled = styled.div``;
