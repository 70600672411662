import React, { useContext } from "react";
import styled from "styled-components";

/* Context ---------------------------*/
import RankItemsContext from "../../RankItemsContext/index.js";

/* Components ---------------------------*/
import Header from "./Header.jsx";
import ItemsList from "../Items/ItemsList.jsx";

const UnrankedItems = () => {
    const {
        state: { unranked, unrankedText },
    } = useContext(RankItemsContext);

    return (
        <UnrankedItemsStyled className="UnrankedItems">
            <Header title={unrankedText} count={unranked.length} />
            <ItemsList items={unranked} ranked={false} />
        </UnrankedItemsStyled>
    );
};

export default UnrankedItems;

const UnrankedItemsStyled = styled.div`
    background-color: #dad7d7;
`;
