import styled from "styled-components";
import PropTypes from "prop-types";

const CardTemplate = ({ title, children, noBodyPadding = false }) => {
    return (
        <CardTemplateStyled
            className="CardTemplate card"
            noBodyPadding={noBodyPadding}
        >
            <div className="card-header">{title}</div>
            <div className="card-body">{children}</div>
        </CardTemplateStyled>
    );
};

export default CardTemplate;

// prop-types
CardTemplate.propTypes = {
    children: PropTypes.any,
    title: PropTypes.any.isRequired,
};

const CardTemplateStyled = styled.div`
    margin-bottom: 25px;

    .card-header {
        background-color: #0a4643;
        color: white;
    }

    .card-body {
        ${({ noBodyPadding }) => noBodyPadding && `padding: 0px;`}
    }
`;
