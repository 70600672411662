import styled from "styled-components";
import PropTypes from "prop-types";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

// context
import { useSurvey } from "Pages/Auth/Survey/SurveyForm/SurveyContext";

const NextButton = ({ sID }) => {
    const state = useSurvey();
    const {
        Utils,
        meta: { buttons },
    } = state;
    const { isComplete, eventKey, isLastSection } =
        Utils.getDecoratedSectionById(sID, state);

    // we can perform side effects here when button is clicked to move to next section
    const onClick = (e) => {};
    const decoratedOnClick = useAccordionButton(eventKey, onClick);

    // Last Section does not get a next button
    if (isLastSection) {
        return null;
    }

    return (
        <NextButtonStyled className="NextButton">
            <button
                type="button"
                onClick={decoratedOnClick}
                className="btn btn-primary"
                disabled={!isComplete}
            >
                {buttons.nextSection}
            </button>
        </NextButtonStyled>
    );
};

export default NextButton;

// prop-types
NextButton.propTypes = {
    sID: PropTypes.string,
};

const NextButtonStyled = styled.div`
    text-align: center;
`;
