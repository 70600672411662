import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareHeadersAuth } from "./utils";

export const apiAuthUserSlice = createApi({
    reducerPath: "apiAuthUser",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_EXPRESS_API}/api/auth`,
        prepareHeaders: prepareHeadersAuth,
    }),
    endpoints: (builder) => {
        return {
            getSurveySubmissions: builder.query({
                query: ({ clientId, surveyToken }) =>
                    `/users/${clientId}/survey/${surveyToken}/all`,
            }),
            getSurveyTakerSurveys: builder.query({
                query: ({ userId }) =>
                    `/users/${userId}/assesment-taker-survey-list`,
            }),
        };
    },
});

export const { useGetSurveySubmissionsQuery, useGetSurveyTakerSurveysQuery } =
    apiAuthUserSlice;
