import UniversalLink from "./components/UniversalLink";
import Logout from "./components/Logout";

// All Links
const links = (mainmenu) => {
    return [
        {
            roles: [1],
            Component: () => (
                <UniversalLink
                    display={mainmenu.adminClients}
                    href="/auth/cp/admin"
                />
            ),
        },
        {
            roles: [1],
            Component: () => (
                <UniversalLink
                    display={mainmenu.adminUsers}
                    href="/auth/cp/admin/admin-users"
                />
            ),
        },
        {
            roles: [1],
            Component: () => (
                <UniversalLink
                    display={mainmenu.adminAddUser}
                    href="/auth/cp/admin/add-user"
                />
            ),
        },
        {
            roles: [2],
            Component: () => (
                <UniversalLink
                    display={mainmenu.clientMyDetails}
                    href={`/auth/cp/client`}
                />
            ),
        },
        {
            roles: [2],
            Component: () => (
                <UniversalLink
                    display={mainmenu.clientMySurveys}
                    href={`/auth/cp/client/survey-submissions`}
                />
            ),
        },
        {
            roles: [3],
            Component: () => (
                <UniversalLink
                    display={mainmenu.surveyTakerMySurveys}
                    href={`/auth/cp/survey-user`}
                />
            ),
        },
        {
            roles: [1, 2, 3],
            Component: () => <Logout display={mainmenu.logout} />,
        },
    ];
};

// Filter and Then Add Unique Ids
export const getLinks = (roleId = 3, mainmenu) => {
    return links(mainmenu)
        .filter((l) => l.roles.includes(roleId))
        .map((l, idx) => {
            return {
                ...l,
                id: idx + 1,
            };
        });
};
