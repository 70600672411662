import { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Modal Context
import { useModalContext } from "Common/BootStrap/Modal";

// components
import ControlWithLabel from "Common/BootStrap/FormControls/ControlWithLabel";
import Input from "Common/BootStrap/FormControls/Input";
import Select from "Common/BootStrap/FormControls/Select";

// data
import { linkTypes } from "./data";

const AddResource = ({ survey }) => {
    const { domains } = survey;
    const { reqObjectAdd } = useModalContext();
    const [text, textUpdate] = useState("");
    const [url, urlUpdate] = useState("");
    const [selectedDomain, selectedDomainUpdate] = useState(domains[0]);
    const [selectedLinkType, selectedLinkTypeUpdate] = useState(linkTypes[0]);

    const handleAddResource = () => {
        reqObjectAdd({
            surveyDomainsId: selectedDomain.id,
            label: text,
            link: url,
            type: selectedLinkType.value,
        });
        textUpdate("");
        urlUpdate("");
    };

    const handleDomainSelectChange = (e) => {
        const value = e.target.value;
        const newSelectedDomain = domains.find((d) => d.id === parseInt(value));
        selectedDomainUpdate(newSelectedDomain);
    };
    const handleLinkTypeSelectChange = (e) => {
        const value = e.target.value;
        const newSelectedLinkType = linkTypes.find((d) => d.value === value);
        selectedLinkTypeUpdate(newSelectedLinkType);
    };

    return (
        <AddResourceStyled>
            <ControlWithLabel
                label={`Add New Resource`}
                id={"resource-placeholder"}
            >
                <div className="controls">
                    <div className="text">
                        <Input
                            id={"resource-placeholder"}
                            placeholder="Enter Text"
                            value={text}
                            onChange={(e) => textUpdate(e.target.value)}
                        />
                    </div>
                    <div className="url">
                        <Input
                            id={"resource-placeholder"}
                            placeholder="Enter Url"
                            value={url}
                            onChange={(e) => urlUpdate(e.target.value)}
                        />
                    </div>
                    <div className="dropdown">
                        <Select
                            id={"domain-dropdown-placeholder"}
                            onChange={handleDomainSelectChange}
                            selectedOption={selectedDomain}
                            options={domains}
                            valueKey="id"
                            displayKey="domain"
                        />
                    </div>
                    <div className="dropdown">
                        <Select
                            id={"linkType-dropdown-placeholder"}
                            onChange={handleLinkTypeSelectChange}
                            selectedOption={selectedLinkType}
                            options={linkTypes}
                        />
                    </div>
                </div>
            </ControlWithLabel>
            <footer>
                <button
                    className="btn btn-secondary"
                    onClick={handleAddResource}
                >
                    Add Resource
                </button>
            </footer>
        </AddResourceStyled>
    );
};

export default AddResource;

// prop-types
AddResource.propTypes = {
    survey: PropTypes.object.isRequired,
};

const AddResourceStyled = styled.div`
    margin-bottom: 40px;

    .controls {
        display: flex;

        > .text {
            flex: 1;
            margin-right: 10px;
        }
        > .url {
            flex: 1;
            margin-right: 10px;
        }
        > .dropdown {
            flex: 0 0 200px;
        }
    }

    footer {
        text-align: right;
    }
`;
