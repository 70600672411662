import styled from "styled-components";
import PropTypes from "prop-types";

// components
import FileUpload from "./FileUpload";
import Base64Image from "Common/Base64Image";

const ImageUpload = ({ id, imageInputObj, onChange }) => {
    const { base64Original, browserSrc } = imageInputObj;

    return (
        <ImageUploadStyled>
            <div className="images">
                {base64Original && !browserSrc && (
                    <div className="original">
                        <Base64Image
                            alt="Original Image"
                            imgObj={base64Original}
                        />
                    </div>
                )}
                {browserSrc && (
                    <div className="original">
                        <img src={browserSrc} alt="New Image" />
                    </div>
                )}
            </div>
            <FileUpload id={id} onChange={onChange} />
        </ImageUploadStyled>
    );
};

export default ImageUpload;

// prop-types
ImageUpload.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    imageInputObj: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

const ImageUploadStyled = styled.div`
    .images {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        > div {
            flex: 0 0 50%;
        }

        img {
            max-width: 100%;
        }
    }
`;
