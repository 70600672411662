import axios from "axios";
import { auth0Token } from "Auth0/Auth0Provider.jsx";
const baseURL = process.env.REACT_APP_EXPRESS_API
    ? `${process.env.REACT_APP_EXPRESS_API}/api`
    : `http://localhost:4000/api`;

export const apiCall = async (method, apiEndpoint, requestObject = {}) => {
    try {
        const sec = auth0Token();
        const accessToken = await sec.fetchToken();

        let axInst = axios.create({
            baseURL: baseURL,
            withCredentials: true,
        });

        // Set the AUTH token for any request
        axInst.interceptors.request.use((config) => {
            config.headers.Authorization = accessToken
                ? `Bearer ${accessToken}`
                : "";
            return config;
        });

        const resp = await axInst[method](apiEndpoint, requestObject);
        return resp.data;
    } catch (err) {
        console.log({ "apiCall fetchToken Error": err });
        const {
            response: { data },
        } = err;
        return { apiError: "Authenticated API Call Error", ...data };
    }
};

export const apiPublicCall = async (
    method,
    apiEndpoint,
    requestObject = {}
) => {
    let axInst = axios.create({
        baseURL: baseURL,
    });
    const resp = await axInst[method](apiEndpoint, requestObject);
    return resp.data;
};
