import styled from "styled-components";
import PropTypes from "prop-types";

// Modal Context
import { ReqObjectWrapper, useModalContext } from "Common/BootStrap/Modal";

// components
import ControlWithLabel from "Common/BootStrap/FormControls/ControlWithLabel";
import ControlWithRemoveButton from "Common/BootStrap/FormControls/ControlWithRemoveButton";
import TextArea from "Common/BootStrap/FormControls/TextArea";

const QuestionControl = ({ count, reqObject, survey }) => {
    const { reqObjectUpdate } = useModalContext();
    const { id, question } = reqObject;

    const handleUpdate = (prop) => {
        reqObjectUpdate({
            ...reqObject,
            ...prop,
        });
    };

    const onRemove = () => {
        reqObjectUpdate({
            ...reqObject,
            restType: "destroy",
        });
    };

    return (
        <ReqObjectWrapper reqObject={reqObject}>
            <QuestionControlStyled>
                <ControlWithLabel
                    label={`Question ${count}`}
                    id={`${id}-question`}
                >
                    <ControlWithRemoveButton onRemove={onRemove}>
                        <TextArea
                            id={`${id}-question`}
                            placeholder="Enter Question"
                            value={question}
                            onChange={(e) => {
                                handleUpdate({
                                    question: e.target.value,
                                });
                            }}
                            height="38px"
                        />
                    </ControlWithRemoveButton>
                </ControlWithLabel>
            </QuestionControlStyled>
        </ReqObjectWrapper>
    );
};

export default QuestionControl;

// prop-types
QuestionControl.propTypes = {
    count: PropTypes.number.isRequired,
    reqObject: PropTypes.object.isRequired,
    survey: PropTypes.object.isRequired,
};

const QuestionControlStyled = styled.div``;
