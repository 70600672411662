import styled from "styled-components";

// context
import { useSurvey } from "Pages/Auth/Survey/SurveyForm/SurveyContext";

const FormSubmitButton = () => {
    const {
        canSubmitForm,
        isSubmitting,
        meta: { buttons },
    } = useSurvey();
    const isDisabled = !canSubmitForm;
    return (
        <FormSubmitButtonStyled>
            {isSubmitting ? (
                <p>{buttons.submit.disabled}</p>
            ) : (
                <button
                    className="FormSubmitButton btn btn-primary w-100 create"
                    type="submit"
                    disabled={isDisabled}
                >
                    {buttons.submit.enabled}
                </button>
            )}
        </FormSubmitButtonStyled>
    );
};

export default FormSubmitButton;

const FormSubmitButtonStyled = styled.div`
    padding: 30px 0px;
    text-align: center;
`;
