import styled from "styled-components";
import PropTypes from "prop-types";
import BSAccordion from "react-bootstrap/Accordion";

/*---------------------------
| Components
---------------------------*/
export const AccordionWrapper = ({ children, ...rest }) => {
    return (
        <AccordionWrapperStyled {...rest}>{children}</AccordionWrapperStyled>
    );
};
export const AccordionItem = ({ children, ...rest }) => {
    return <AccordionItemStyled {...rest}>{children}</AccordionItemStyled>;
};
export const AccordionHeader = ({ children, ...rest }) => {
    return <AccordionHeaderStyled {...rest}>{children}</AccordionHeaderStyled>;
};
export const AccordionBody = ({ children, ...rest }) => {
    return <AccordionBodyStyled {...rest}>{children}</AccordionBodyStyled>;
};

/*---------------------------
| PropTypes
---------------------------*/
AccordionWrapper.propTypes = { children: PropTypes.any };
AccordionItem.propTypes = { children: PropTypes.any };
AccordionHeader.propTypes = { children: PropTypes.any };
AccordionBody.propTypes = { children: PropTypes.any };

/*---------------------------
| Styles
---------------------------*/
const AccordionWrapperStyled = styled(BSAccordion)``;
const AccordionItemStyled = styled(BSAccordion.Item)`
    &.disabled {
        pointer-events: none;
    }
`;
const AccordionHeaderStyled = styled(BSAccordion.Header)``;
const AccordionBodyStyled = styled(BSAccordion.Body)`
    padding: 0px;
`;
