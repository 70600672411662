/*---------------------------
| Action Types (TOC)
---------------------------*/
export const actionTypes = {
    RO_RANKED_ITEMS_ORDER_UPDATE: 'RO: rankedItemsOrderUpdate',

    RO_ADD_RANKED_ITEM: 'RO: addRankedItem',
    RO_REMOVE_RANKED_ITEM: 'RO: removeRankedItem',

    RO_MOVE_RANKED_ITEM_UP: 'RO: moveRankedItemUp',
    RO_MOVE_RANKED_ITEM_DOWN: 'RO: moveRankedItemDown',

}