import styled from "styled-components";
import PropTypes from "prop-types";

/* ClientDetailsContext ---------------------------*/
import { useClientDetailsContext } from "Pages/Auth/ControlPanel/ClientDetails/ClientDetailsContext/index.js";

const ErrorMessage = ({ error }) => {
    const { dispatch, actions } = useClientDetailsContext();

    const onClick = () => {
        dispatch(actions.updateFormStatus("listening"));
    };

    return (
        <ErrorMessageStyled>
            <div>
                <div className="opener">
                    There was an error in saving your data to the server. Please
                    try again, if the problem persists contact an adminstrator
                </div>

                <div
                    className="error-message"
                    dangerouslySetInnerHTML={{ __html: error }}
                />

                <button
                    onClick={onClick}
                    className="btn btn-primary"
                    type="button"
                >
                    try again
                </button>
            </div>
        </ErrorMessageStyled>
    );
};

export default ErrorMessage;

// prop-types
ErrorMessage.propTypes = {
    error: PropTypes.any,
};

const ErrorMessageStyled = styled.div`
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    > div {
        max-width: 300px;

        .error-message {
            color: red;
            margin: 30px 0px;
            text-align: center;
        }
    }
`;
