import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareHeadersAuth } from "./utils";

export const apiAuthSlice = createApi({
    reducerPath: "apiAuth",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_EXPRESS_API}/api/auth`,
        prepareHeaders: prepareHeadersAuth,
    }),
    tagTypes: ["Shark"],
    endpoints: (builder) => {
        return {
            getSharks: builder.query({
                query: () => `/sharks`,
                providesTags: ["Shark"],
            }),
            createSharks: builder.mutation({
                query: (sharks) => {
                    return {
                        method: "post",
                        url: "/sharks",
                        body: sharks,
                    };
                },
                invalidatesTags: ["Shark"],
            }),
            getPreloadedStateAuth: builder.query({
                query: ({ surveyId = 0, surveyToken = 0 }) =>
                    `/preload/${surveyId}/${surveyToken}`,
            }),
            getAdminList: builder.query({
                query: () => `/users/admin/`,
            }),
            getClientList: builder.query({
                query: () => `/clients/`,
            }),
            createNewClient: builder.mutation({
                query: (body) => {
                    return {
                        method: "post",
                        url: "/users",
                        body: body,
                    };
                },
            }),
        };
    },
});

export const {
    useGetSharksQuery,
    useCreateSharksMutation,
    useGetPreloadedStateAuthQuery,

    useGetAdminListQuery,
    useGetClientListQuery,
    useCreateNewClientMutation,
} = apiAuthSlice;
