const isPositiveHelper = (option) => {
    return option.includes('Most relevant') || option.includes('Relevant to my')
}
const isNegativeHelper = (option) => {
    return option.includes('Most negatively') || option.includes('Negatively relevant to my')
}

module.exports = {
    isPositiveHelper,
    isNegativeHelper
}