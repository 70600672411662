import BSButton from "react-bootstrap/Button";
import PropTypes from "prop-types";
import styled from "styled-components";

// Context
import { useModalContext } from "Common/BootStrap/Modal/ModalContext/index";

export const ModalButton = ({ children }) => {
    const { dispatch, actions } = useModalContext();

    const onClick = () => {
        dispatch(actions.showUpdate(true));
    };

    return (
        <BSButtonStyled variant="btn btn-success ModalButton" onClick={onClick}>
            {children}
        </BSButtonStyled>
    );
};

// prop-types
ModalButton.propTypes = {
    children: PropTypes.any,
};

export default ModalButton;

const BSButtonStyled = styled(BSButton)`
    margin: 0px 3px;
`;
