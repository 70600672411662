import styled from "styled-components";

/* Redux ---------------------------*/
import { useAuth } from "Auth0/useAuth";
import { useGetSurveyTakerSurveysQuery } from "Redux/apiAuthUserSlice";

/* Components ---------------------------*/
import Preloader from "Common/Preloader";
import SurveySubmissions from "./SurveySubmissions";

const SurveyUserLanding = () => {
    const { userProfile } = useAuth();
    const { data: surveys, isLoading } = useGetSurveyTakerSurveysQuery({
        userId: userProfile.id,
    });

    if (isLoading || !surveys) {
        return <Preloader />;
    }

    return (
        <SurveyUserLandingStyled>
            <h1>Survey User Landing</h1>
            <SurveySubmissions surveys={surveys} roleId={3} />
        </SurveyUserLandingStyled>
    );
};

export default SurveyUserLanding;

const SurveyUserLandingStyled = styled.div``;
