import React from "react";
import styled from "styled-components";

/* RankItems ---------------------------*/
import { useRankItems } from "Common/useRankItems";

/* Components ---------------------------*/
import HostDisplay from "./HostDisplay.jsx";

const RankItemsHost = () => {
    const { RankItems } = useRankItems();
    return (
        <RankItemsHostStyled className="RankItemsHost">
            <RankItems />
            <HostDisplay />
        </RankItemsHostStyled>
    );
};

export default RankItemsHost;

const RankItemsHostStyled = styled.div``;
