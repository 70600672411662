import React from "react";
import styled from "styled-components";

/* Hooks ---------------------------*/
import { useAuth } from "Auth0/useAuth.js";

/* Redux ---------------------------*/
import { useGetAdminListQuery } from "Redux/apiAuthSlice.js";

/* Components ---------------------------*/
import Preloader from "Common/Preloader.jsx";
import { useNavigate } from "react-router-dom";

const AdminList = () => {
    let navigate = useNavigate();
    const { userProfile } = useAuth();

    // Lets make sure we have the user's profile.
    const { isLoading, data: adminList } = useGetAdminListQuery();

    if (isLoading) {
        return <Preloader />;
    }

    if (userProfile.roleId !== 1) {
        navigate("/auth/cp");
    }

    return (
        <AdminListStyled className="AdminList inset">
            <h1>Admin List</h1>
            <div className="row">
                {adminList.map((admin) => {
                    return (
                        <div key={admin.id} className="col-sm-4 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        {admin.firstName} {admin.lastName}
                                    </h5>
                                    <p className="card-text">
                                        <span>Status: </span> {admin.status}
                                    </p>
                                    <p className="card-text">
                                        <span>email: </span> {admin.email}
                                    </p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </AdminListStyled>
    );
};

export default AdminList;

const AdminListStyled = styled.div`
    h1 {
        margin: auto auto 20px auto;
    }

    .row {
        .btn {
            background-color: #0a4643;
            color: white;

            &:hover {
                color: red;
                font-weight: bold;
                background-color: #fff;
            }
        }
        .card-text {
            text-transform: capitalize;
            margin-bottom: 5px;
            span {
                font-weight: bold;
            }
        }
    }
`;
